import { AdminRouteList } from "./routes/adminRouteList";
import { AdressebokRouteList } from "./routes/adressebokRouteList";
import { DashboardRouteList } from "./routes/dashboardRouteList";
import { KursetRouteList } from "./routes/kursetRouteList";
import { KursRouteList } from "./routes/kursRouteList";
import { StudieplanerRouteList } from "./routes/studieplanerRouteList";

export const ApplicationRoutes = [
  //  Login
  {
    path: "/login",
    name: "login",
    component: () => import(/* webpackChunkName: "LoginPage" */ "../pages/login/LoginPage.vue"),
    meta: {
      guest: true,
    },
  },
  {
    path: "/login-callback",
    name: "login-callback",
    component: () => import(/* webpackChunkName: "LoginCallbackPage" */ "../pages/login/LoginCallbackPage.vue"),
  },
  //  Reroute
  {
    path: "/",
    name: "reroute",
    meta: { requiresAuth: true, redirect: true },
  },
  //  Application
  {
    path: "/",
    name: "dashboard",
    component: () => import(/* webpackChunkName: "AppRouterPage" */ "@/pages/AppRouterPage.vue"),
    meta: { requiresAuth: true, isEnabledForMemberOrganizations: true },
    children: [
      ...DashboardRouteList,
      ...StudieplanerRouteList,
      ...KursRouteList,
      ...KursetRouteList,
      ...AdressebokRouteList,
      ...AdminRouteList,
      // kursserier not in use? TODO
      // {
      //   path: "kursserier",
      //   name: "courseSeries",
      //   component: () => import("@/components/Course/CourseSeries/AllCourseSeries.vue"),
      // },
      // {
      //   path: "kursserier/ny",
      //   name: "newCourseSeries",
      //   component: () => import("@/components/Course/CourseSeries/New/index.vue"),
      // },
    ],
  },
  {
    path: "/unauthorized",
    name: "Unauthorized",
    component: () => import(/* webpackChunkName: "UnauthorizedPage" */ "../pages/UnauthorizedPage.vue"),
  },
  {
    path: "/:catchAll(.*)",
    name: "NotFound",
    component: () => import(/* webpackChunkName: "PageNotFoundPage" */ "../pages/PageNotFoundPage.vue"),
  },
];
