import { add, startOfDay, sub } from "date-fns";
import { CourseDto } from "./interfaces/Course.interface";
import { Curriculum } from "@/store/modules/plans/interfaces/Curriculum.interface";

export const getCourseInitialState = (usePreFilled = false, curriculum?: Curriculum): CourseDto => {
  if (usePreFilled) {
    return {
      // Prefilled form state to make manual testing of course flow less painful
      curriculumId: 0,
      courseName: `Forhåndsutfylt testkurs [${new Date().toISOString()}]`,
      useLearningPlatform: true,
      isOrderable: false,
      courseLocation: {
        areaId: 109,
      },
      isTimeWebVisible: false,
      studyLocationId: undefined,
      cancellationDeadline: "",
      startDate: sub(startOfDay(new Date()), { weeks: 1 }).toISOString(),
      endDate: add(startOfDay(new Date()), { months: 1 }).toISOString(),
      hoursWithInstructor: 10,
      courseSupervisorId: 94403,
      organizerOrganizationId: 0,
      lecturerId: 94403,
      contactId: 94403,
      kasId: undefined,
      enrollmentDeadline: "",
      unenrollmentDeadline: "",
      minimumParticipants: 1,
      maxParticipants: 10,
      participantCapacity: 10,
      financedByOrganizationIds: [],
      plan: {
        registerHours: true,
        schoolRouteId: 0,
        schedules: [],
      },
      additionalTeachingMaterialIds: [],
      tests: [],
      comments: "",
      economy: {
        accountingDepartment: 30,
        cancellationFee: 0,
        price: 0,
        allowExpenses: false,
        articleNos: [],
        grantId: 10,
      },
      webDescription: {
        templateId: "0071663a-45ed-4db8-b885-911a3695052d",
        title: `Forhåndsutfylt testkurs [${new Date().toISOString()}]`,
        webCategory: "helse-miljo-sikkerhet",
        intro: "<p>a</p>",
        targetGroup: "<p>b</p>",
        foreknowledgeRequirements: "<p>c</p>",
        documentationRequired: false,
        learningObjectives: "<p>d</p>",
        courseContent: "<p>e</p>",
        method: "<p>f</p>",
        financing: "<p>g</p>",
        exam: "<p>h</p>",
        publishDate: new Date().toISOString(),
        enrollmentStatus: "åpent for påmelding",
        defaultEnrollmentStudentStatus: "søkt",
      },
    };
  }

  // Empty form
  return {
    curriculumId: 0,
    courseName: "",
    useLearningPlatform: true,
    isOrderable: false,
    courseLocation: {
      areaId: undefined,
      locationId: undefined,
      roomId: undefined,
    },
    isTimeWebVisible: false,
    studyLocationId: undefined,
    cancellationDeadline: "",
    startDate: "",
    endDate: "",
    hoursWithInstructor: 0,
    hoursWithoutInstructor: undefined,
    hoursOnline: undefined,
    hoursSelfStudy: undefined,
    courseSupervisorId: 0,
    organizerOrganizationId: 0,
    lecturerId: 0,
    contactId: 0,
    kasId: undefined,
    enrollmentDeadline: "",
    unenrollmentDeadline: "",
    minimumParticipants: 0,
    maxParticipants: 0,
    participantCapacity: 0,
    financedByOrganizationIds: [],
    plan: {
      registerHours: true,
      schoolRouteId: 0,
      schedules: [],
    },
    additionalTeachingMaterialIds: [],
    tests: [],
    comments: "",
    economy: {
      accountingDepartment: 0,
      cancellationFee: 0,
      price: 0,
      allowExpenses: false,
      articleNos: [],
      grantId: 0,
    },
    webDescription: {
      templateId: "",
      title: "",
      webCategory: "",
      intro: "",
      targetGroup: curriculum?.targetGroup ?? "",
      foreknowledgeRequirements: curriculum?.foreknowledgeRequirements ?? "",
      documentationRequired: curriculum?.courseCertificateRequired ?? false,
      learningObjectives: curriculum?.learningObjectives ?? "",
      courseContent: curriculum?.course.content ?? "",
      method: curriculum?.course.method ?? "",
      financing: "",
      exam: "",
      publishDate: "",
      enrollmentStatus: "",
      defaultEnrollmentStudentStatus: "",
    },
  };
};
