














import TheAppBarItems from "@/components/shell/TheAppBarItems.vue";
import { defineComponent } from "@vue/composition-api";
export default defineComponent({
  name: "TheAppBar",
  components: { TheAppBarItems },
});
