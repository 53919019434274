/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface ApiAccountingDepartment {
  /** @format int32 */
  organizationId: number;

  /** @format int32 */
  erpClientId: number;

  /** @format int32 */
  erpDepartmentId: number;
  name?: string | null;
  organization?: ApiOrganization;
}

export interface ApiAccountRatesDto {
  /** @format double */
  participantPrice?: number | null;

  /** @format double */
  hourlyPrice?: number | null;

  /** @format double */
  percentageOfTotalCostsPerCourse?: number | null;

  /** @format double */
  maximumAmount?: number | null;
}

export interface ApiAddActualCostsToFacilitationGrantApplicationDto {
  commentToGrant?: string | null;
  actualCosts?: ApiFacilitationActualCostDto[] | null;
}

export interface ApiAddActualCostsToFacilitationGrantApplicationDtoValidationEntry {
  validatedItem?: ApiAddActualCostsToFacilitationGrantApplicationDto;

  /** The validation errors for the SYSCO.Universum.Api.Dtos.Validation.ValidationEntry`1.ValidatedItem */
  validationErrors?: string[] | null;
}

export interface ApiAddActualCostsToFacilitationGrantApplicationDtoValidationResponseDto {
  /**
   * The general validation errors for the entire list of validated entries.
   * E.g. if the sum of entries makes it invalid, but the items themselves are valid,
   * the errors are more general.
   */
  generalValidationErrors?: string[] | null;
  validationList?: ApiAddActualCostsToFacilitationGrantApplicationDtoValidationEntry[] | null;
}

export interface ApiAppFormResponse {
  /** @format int64 */
  id: number;

  /** @format uuid */
  templateId: string;

  /** @format int32 */
  courseId: number;

  /** @format int32 */
  userId: number;
  isNorwegianCitizen?: boolean | null;
  citizenship?: string | null;
  personalnumber?: string | null;
  isNewsletter?: boolean | null;
  employerName?: string | null;
  employerAddress?: string | null;
  employerPostNumber?: string | null;
  employerLocation?: string | null;
  employerEmail?: string | null;
  organizationPurchaserBrRegOrganizationNumber?: string | null;
  organizationPurchaserOrganizationName?: string | null;
  organizationPurchaserAddress?: string | null;
  organizationPurchaserPostNumber?: string | null;
  organizationPurchaserPostLocation?: string | null;
  organizationPurchaserPostCountry?: string | null;
  organizationPurchaserMobileNumber?: string | null;
  organizationPurchaserEmail?: string | null;
  organizationPurchaserHomePage?: string | null;
  isPrivateCostBearer?: boolean | null;

  /** @format int32 */
  privateCostBearerInstallmentType?: number | null;

  /** @format int32 */
  privateCostBearerInstallmentInterval?: number | null;
  organizationCostBearerEmail?: string | null;
  organizationCostBearerBrRegOrganizationNumber?: string | null;
  organizationCostBearerOrganizationName?: string | null;
  organizationCostBearerAddress?: string | null;
  organizationCostBearerPostNumber?: string | null;
  organizationCostBearerPostLocation?: string | null;
  organizationCostBearerPostCountry?: string | null;
  organizationCostBearerMobileNumber?: string | null;
  organizationCostBearerOrderNumber?: string | null;
  organizationCostBearerReferenceNumber?: string | null;
  organizationCostBearerHomePage?: string | null;
  isNorwegianMothertongue?: boolean | null;
  norwegianSkillsLevel?: string | null;
  norwegianSkillsCerfLevel?: string | null;
  isFormalCompetence?: boolean | null;
  formalCompetenciesIsNorwegianHealthAndSocialSciences?: boolean | null;
  formalCompetenciesIsAuthorizationDiploma?: boolean | null;
  formalCompetenciesIsForeignHealthAndSocialSciences?: boolean | null;
  isLoMember?: boolean | null;

  /** @format int32 */
  memberOrganizationId?: number | null;
  courseRegulationsAccepted?: boolean | null;

  /** @format int64 */
  regulationFileId?: number | null;

  /** @format date-time */
  inserted: string;

  /** @format date-time */
  updated: string;

  /** @format int32 */
  insertedBy: number;

  /** @format int32 */
  updatedBy: number;
  appFormResponseFile?: ApiAppFormResponseFile[] | null;
  memberOrganization?: ApiMemberOrganization;
  appFormResponseAdditionalQuestion?: ApiAppFormResponseAdditionalQuestion[] | null;
  courseParticipant?: ApiCourseParticipant;
  regulationFile?: ApiRegulationFile;
}

export interface ApiAppFormResponseAdditionalQuestion {
  /** @format int64 */
  id: number;

  /** @format int64 */
  appFormResponseId: number;

  /** @format int64 */
  fileId?: number | null;
  response?: string | null;

  /** @format uuid */
  appFormTemplateAdditionalQuestionId: string;
  appFormResponse?: ApiAppFormResponse;
  file?: ApiFile;
  appFormTemplateAdditionalQuestion?: ApiAppFormTemplateAdditionalQuestion;
}

export interface ApiAppFormResponseFile {
  /** @format int64 */
  appFormResponseId: number;

  /** @format int64 */
  fileId: number;
  appFormResponse?: ApiAppFormResponse;
  file?: ApiFile;
}

export interface ApiAppFormTemplate {
  /** @format uuid */
  id: string;
  name?: string | null;
  isActive: boolean;
  isVocationalSchool: boolean;
  isCitizenship: boolean;
  isPersonalNumber: boolean;
  isNorwegianMothertongue: boolean;
  isNorwegianCertificationLevel: boolean;
  isNorwegianSkills: boolean;
  isCompetence: boolean;
  isEmployer: boolean;
  isEmployerEmail?: boolean | null;
  isCostBearer: boolean;
  isInstallments: boolean;
  isMonthly?: boolean | null;
  isSemiAnnually?: boolean | null;
  isQuarterly?: boolean | null;
  isInvoiceReference?: boolean | null;
  isNewsletter: boolean;
  isUnionized: boolean;
  isMemberOrganization: boolean;
  isRegionalOffices?: boolean | null;

  /** @format int64 */
  regulationFileId?: number | null;

  /** @format date-time */
  inserted: string;

  /** @format date-time */
  updated: string;

  /** @format int32 */
  insertedBy: number;

  /** @format int32 */
  updatedBy: number;
  regulationFile?: ApiRegulationFile;
  appFormTemplateAdditionalQuestion?: ApiAppFormTemplateAdditionalQuestion[] | null;
}

export interface ApiAppFormTemplateAdditionalQuestion {
  /** @format uuid */
  id: string;

  /** @format uuid */
  appFormTemplateId: string;
  type?: string | null;
  description?: string | null;

  /** @format uuid */
  parentQuestionId: string;

  /** @format int32 */
  questionNumber: number;
  required: boolean;

  /** @format date-time */
  inserted: string;

  /** @format date-time */
  updated: string;

  /** @format int32 */
  insertedBy: number;

  /** @format int32 */
  updatedBy: number;
  appFormTemplate?: ApiAppFormTemplate;
  appFormResponseAdditionalQuestion?: ApiAppFormResponseAdditionalQuestion[] | null;
  appFormTemplateAdditionalQuestionAlternative?: ApiAppFormTemplateAdditionalQuestionAlternative[] | null;
}

export interface ApiAppFormTemplateAdditionalQuestionAlternative {
  /** @format uuid */
  appFormTemplateAdditionalQuestionId: string;
  alternative?: string | null;

  /** @format int32 */
  alternativeNumber: number;
  appFormTemplateAdditionalQuestion?: ApiAppFormTemplateAdditionalQuestion;
}

export interface ApiApplicationFormTemplateAdditionalQuestionAlternativeDto {
  /** @format int32 */
  alternativeNumber: number;
  description?: string | null;
}

export interface ApiApplicationFormTemplateAdditionalQuestionDto {
  /** @format uuid */
  id: string;
  type?: string | null;
  description?: string | null;
  questionAlternatives?: ApiApplicationFormTemplateAdditionalQuestionAlternativeDto[] | null;
  required: boolean;

  /** @format int32 */
  questionNumber: number;
  displayIfQuestionIdIsTrue?: string | null;
}

export interface ApiApproveFacilitationGrantApplicationCostsDto {
  commentToActualCosts?: string | null;
}

export interface ApiApproveFacilitationGrantApplicationCostsDtoValidationEntry {
  validatedItem?: ApiApproveFacilitationGrantApplicationCostsDto;

  /** The validation errors for the SYSCO.Universum.Api.Dtos.Validation.ValidationEntry`1.ValidatedItem */
  validationErrors?: string[] | null;
}

export interface ApiApproveFacilitationGrantApplicationCostsDtoValidationResponseDto {
  /**
   * The general validation errors for the entire list of validated entries.
   * E.g. if the sum of entries makes it invalid, but the items themselves are valid,
   * the errors are more general.
   */
  generalValidationErrors?: string[] | null;
  validationList?: ApiApproveFacilitationGrantApplicationCostsDtoValidationEntry[] | null;
}

export interface ApiApproveFacilitationGrantApplicationDto {
  commentToGrant?: string | null;
  costsGranted?: ApiFacilitationCostGrantedDto[] | null;
}

export interface ApiApproveFacilitationGrantApplicationDtoValidationEntry {
  validatedItem?: ApiApproveFacilitationGrantApplicationDto;

  /** The validation errors for the SYSCO.Universum.Api.Dtos.Validation.ValidationEntry`1.ValidatedItem */
  validationErrors?: string[] | null;
}

export interface ApiApproveFacilitationGrantApplicationDtoValidationResponseDto {
  /**
   * The general validation errors for the entire list of validated entries.
   * E.g. if the sum of entries makes it invalid, but the items themselves are valid,
   * the errors are more general.
   */
  generalValidationErrors?: string[] | null;
  validationList?: ApiApproveFacilitationGrantApplicationDtoValidationEntry[] | null;
}

export interface ApiArea {
  /** @format int32 */
  id: number;
  country?: string | null;
  countryCode?: string | null;
  county?: string | null;
  municipality?: string | null;
  place?: string | null;
  studylocationCode?: string | null;

  /** @format date-time */
  inserted: string;

  /** @format int32 */
  insertedBy: number;

  /** @format date-time */
  updated: string;

  /** @format int32 */
  updatedBy: number;
  course?: ApiCourse[] | null;
  location?: ApiLocation[] | null;
}

export interface ApiArticleDto {
  articleNo?: string | null;

  /**
   * Optional amount override
   * @format double
   */
  amountOverride?: number | null;
}

export interface ApiArticleDtoType {
  id?: string | null;
  name?: string | null;

  /** @format double */
  vatPercent?: number | null;
  teachingMethod?: string | null;
}

export interface ApiAttachmentDto {
  /** @format int64 */
  fileId: number;
  fileName?: string | null;
}

export interface ApiBaseGetFileDto {
  /** @format int64 */
  id: number;
  originalFileName?: string | null;
  description?: string | null;
  mimeType?: string | null;
  category?: string | null;
  isAdminRead?: boolean | null;

  /** @format date-time */
  inserted: string;

  /** @format int32 */
  insertedBy: number;
  insertedByUserFullName?: string | null;
}

export interface ApiBatchMergeCourseParticipantAttendancesDto {
  attendances?: ApiCourseParticipantAttendanceBaseDto[] | null;
}

export interface ApiBuildinfo {
  enabled: boolean;
  environment?: string | null;
  buildNumber?: string | null;
  sourceBranchName?: string | null;
  sourceVersion?: string | null;
}

export interface ApiCallbackRequest {
  callbackUrl?: string | null;
  callbackTag?: string | null;
  serviceBusMessageId?: string | null;
  isSuccessful: boolean;
  errorMessage?: string | null;
}

export interface ApiCloseCourseValidationCategoryDto {
  name?: string | null;
  errors?: ApiCloseCourseValidationMessageDto[] | null;
  warnings?: ApiCloseCourseValidationMessageDto[] | null;

  /** @format int32 */
  numberOfErrors: number;

  /** @format int32 */
  numberOfWarnings: number;
}

export interface ApiCloseCourseValidationDto {
  /**
   * Categories av feil ved avslutte kurs. Disse er:
   * "course"
   * "teachinggrant"
   * "participantAttendance"
   * "participantExpense"
   * "workingHour"
   * "economy"
   */
  categories?: ApiCloseCourseValidationCategoryDto[] | null;

  /**
   * antall feil i valideringen
   * @format int32
   */
  numberOfErrors: number;

  /**
   * antall varsler i valideringen
   * @format int32
   */
  numberOfWarnings: number;

  /**
   * type feilmeldinger som kan bypasses. Disse er:
   * "skipWorkingHours": kan hoppe over arbeidstimer sjekk
   * "skipTeachingHours": kan hoppe over "timer med lærer mindre enn 4 timer"
   * "skipAmountOfParticipantsOlderThan14": kan hoppe over "mindre enn 4 deltakere over 14 år"
   */
  availableBypasses?: string[] | null;
}

export interface ApiCloseCourseValidationMessageDto {
  message?: string | null;
  users?: ApiUsersIdNameDto[] | null;

  /** @format date-time */
  date?: string | null;
}

export interface ApiCommunicationTemplate {
  /** @format int32 */
  id: number;

  /** @format int32 */
  communicationTemplateGroupId: number;
  tag?: string | null;
  name?: string | null;
  subject?: string | null;
  content?: string | null;
  isActive: boolean;

  /** @format date-time */
  inserted: string;

  /** @format int32 */
  insertedBy: number;

  /** @format date-time */
  updated: string;

  /** @format int32 */
  updatedBy: number;
  communicationTemplateGroup?: ApiCommunicationTemplateGroup;
}

export interface ApiCommunicationTemplateGroup {
  /** @format int32 */
  id: number;

  /** @format int32 */
  organizationId?: number | null;
  name?: string | null;
  type?: string | null;

  /** @format date-time */
  inserted: string;

  /** @format int32 */
  insertedBy: number;

  /** @format date-time */
  updated: string;

  /** @format int32 */
  updatedBy: number;
  organization?: ApiOrganization;
  communicationTemplate?: ApiCommunicationTemplate[] | null;
}

export interface ApiCost {
  /** @format int32 */
  id: number;

  /** @format int32 */
  organizationId: number;

  /** @format int32 */
  costTypeId: number;
  name?: string | null;
  description?: string | null;

  /** @format double */
  price: number;

  /** @format int32 */
  payType?: number | null;
  articleNo?: string | null;

  /** @format int32 */
  deductionCode?: number | null;
  teachingMethod?: string | null;
  isActive: boolean;

  /** @format date-time */
  inserted: string;

  /** @format int32 */
  insertedBy: number;

  /** @format date-time */
  updated: string;

  /** @format int32 */
  updatedBy: number;
  costType?: ApiCostType;
  organization?: ApiOrganization;
  courseContractLine?: ApiCourseContractLine[] | null;
  courseParticipantExpense?: ApiCourseParticipantExpense[] | null;
}

export interface ApiCostBearerOrganizationDto {
  organizationNumber?: string | null;
  name?: string | null;
  homePage?: string | null;
  address?: string | null;
  city?: string | null;
  zip?: string | null;
  country?: string | null;
  mobileNumber?: string | null;
  email?: string | null;
  isBrregSubCompany?: boolean | null;
  isPurchaser: boolean;
  orderNumber?: string | null;
  referenceNumber?: string | null;
}

export interface ApiCostBearerPrivateDto {
  /**
   * Ønsket avdrag; mndlig(1), kvartalsvis(3), halvårlig(6)
   * @format int32
   */
  installmentInterval?: number | null;

  /** @format int32 */
  installmentType?: number | null;
}

export interface ApiCostType {
  /** @format int32 */
  id: number;
  costGroup?: string | null;
  name?: string | null;

  /** @format date-time */
  inserted: string;
  cost?: ApiCost[] | null;
}

export interface ApiCourse {
  /** @format int32 */
  id: number;

  /** @format int32 */
  curriculumId: number;
  courseName?: string | null;
  externalTitle?: string | null;
  status?: string | null;
  reasonForStatusChange?: string | null;
  useLearningPlatform: boolean;

  /** @format int32 */
  areaId?: number | null;

  /** @format int32 */
  locationId?: number | null;

  /** @format int32 */
  roomId?: number | null;

  /** @format int32 */
  studyLocationId?: number | null;

  /** @format date-time */
  cancellationDeadline?: string | null;

  /** @format date-time */
  startDate: string;

  /** @format date-time */
  endDate: string;
  isTimeWebVisible: boolean;

  /** @format int32 */
  hoursWithInstructor: number;

  /** @format int32 */
  hoursWithoutInstructor?: number | null;

  /** @format int32 */
  hoursOnline?: number | null;

  /** @format int32 */
  hoursSelfStudy?: number | null;

  /** @format int32 */
  courseSupervisorId: number;

  /** @format int32 */
  organizerOrganizationId: number;

  /** @format int32 */
  ownerOrganizationId: number;

  /** @format int32 */
  lecturerId: number;

  /** @format int32 */
  contactId: number;

  /** @format int32 */
  kasId?: number | null;

  /** @format date-time */
  enrollmentDeadline?: string | null;

  /** @format date-time */
  unenrollmentDeadline?: string | null;

  /** @format int32 */
  minimumParticipants?: number | null;

  /** @format int32 */
  maxParticipants?: number | null;

  /** @format int32 */
  participantCapacity?: number | null;
  isOrderable?: boolean | null;
  registerHours: boolean;
  comments?: string | null;

  /** @format int32 */
  holidayId?: number | null;

  /** @format date-time */
  inserted: string;

  /** @format int32 */
  insertedBy: number;

  /** @format date-time */
  updated: string;

  /** @format int32 */
  updatedBy: number;

  /** @format int32 */
  msgUserId?: number | null;

  /** @format int32 */
  erpProjectNo?: number | null;

  /** @format date-time */
  erpLastUpdated?: string | null;

  /** @format int32 */
  mainCourseId?: number | null;
  area?: ApiArea;
  curriculum?: ApiCurriculum;
  holiday?: ApiHoliday;
  location?: ApiLocation;
  room?: ApiRoom;
  studyLocation?: ApiStudyLocation;
  courseDescription?: ApiCourseDescription;
  courseEconomy?: ApiCourseEconomy;
  ownerOrganization?: ApiDepartment;
  courseArticle?: ApiCourseArticle[] | null;
  courseContract?: ApiCourseContract[] | null;
  courseFile?: ApiCourseFile[] | null;
  courseNote?: ApiCourseNote[] | null;
  courseParticipant?: ApiCourseParticipant[] | null;
  courseTeachingMaterial?: ApiCourseTeachingMaterial[] | null;
  courseTest?: ApiCourseTest[] | null;
  schedule?: ApiSchedule[] | null;
  courseFinancedOrganization?: ApiCourseFinancedOrganization[] | null;
  orders?: ApiOrder[] | null;
  courseInvitation?: ApiCourseInvitation[] | null;
  courseSupervisorEmployee?: ApiEmployee;
  courseContactEmployee?: ApiEmployee;
  courseLecturerEmployee?: ApiEmployee;
  courseParticipantExpense?: ApiCourseParticipantExpense[] | null;
  facilitationGrantApplication?: ApiFacilitationGrantApplication;
  mainCourse?: ApiCourse;
  grantAmount?: ApiTeachingGrantAmount;
  facilitationCourse?: ApiCourse[] | null;
  courseSms?: ApiCourseSms[] | null;
}

export interface ApiCourseArea {
  /** @format int32 */
  id: number;
  name?: string | null;
  code?: string | null;
  ssbCode?: string | null;

  /** @format int32 */
  parentId?: number | null;
  isActive: boolean;

  /** @format date-time */
  inserted: string;

  /** @format int32 */
  insertedBy: number;

  /** @format date-time */
  updated: string;

  /** @format int32 */
  updatedBy: number;

  /** @format int32 */
  erpProductNo?: number | null;

  /** @format date-time */
  erpLastUpdated?: string | null;
  parent?: ApiCourseArea;
  curriculum?: ApiCurriculum[] | null;
  inverseParent?: ApiCourseArea[] | null;
  orderLines?: ApiOrderLine[] | null;
}

export interface ApiCourseArticle {
  /** @format int32 */
  courseId: number;
  articleNo?: string | null;
  course?: ApiCourse;
}

export interface ApiCourseContract {
  /** @format int32 */
  id: number;

  /** @format int32 */
  courseId: number;

  /** @format int32 */
  userId: number;
  status?: string | null;
  accountNo?: string | null;
  isSelfEmployed: boolean;

  /** @format int32 */
  customerOrganizationId?: number | null;
  note?: string | null;
  isPrimaryEmployer: boolean;
  taxMunicipalityNo?: string | null;
  taxMunicipalityName?: string | null;
  isActive: boolean;
  roleName?: string | null;
  serviceBusMessageId?: string | null;

  /** @format date-time */
  inserted: string;

  /** @format int32 */
  insertedBy: number;

  /** @format date-time */
  updated: string;

  /** @format int32 */
  updatedBy: number;
  course?: ApiCourse;
  courseParticipant?: ApiCourseParticipant;
  customerOrganization?: ApiCustomerOrganization;
  courseContractLine?: ApiCourseContractLine[] | null;
  role?: ApiRole;
  courseContractFile?: ApiCourseContractFile;
}

export interface ApiCourseContractFile {
  /** @format int32 */
  courseContractId: number;

  /** @format int64 */
  signedFileId?: number | null;

  /** @format int64 */
  unsignedFileId: number;

  /** @format int32 */
  signicatDocumentId?: number | null;
  signStatus?: string | null;

  /** @format date-time */
  inserted: string;

  /** @format int32 */
  insertedBy: number;

  /** @format date-time */
  updated: string;

  /** @format int32 */
  updatedBy: number;
  courseContract?: ApiCourseContract;
  signedFile?: ApiFile;
  unsignedFile?: ApiFile;
}

export interface ApiCourseContractLine {
  /** @format int32 */
  id: number;

  /** @format int32 */
  courseContractId: number;

  /** @format int32 */
  costId: number;
  unit?: string | null;

  /** @format double */
  amount: number;

  /** @format double */
  price?: number | null;
  description?: string | null;

  /** @format date-time */
  inserted: string;

  /** @format int32 */
  insertedBy: number;

  /** @format date-time */
  updated: string;

  /** @format int32 */
  updatedBy: number;
  cost?: ApiCost;
  courseContract?: ApiCourseContract;
  workingHour?: ApiWorkingHour[] | null;
}

export interface ApiCourseDescription {
  /** @format int32 */
  courseId: number;

  /** @format uuid */
  appFormTemplateId: string;
  webCategory?: string | null;
  intro?: string | null;
  targetGroup?: string | null;
  foreknowledgeRequirements?: string | null;
  documentationRequired: boolean;
  learningObjectives?: string | null;
  courseContent?: string | null;
  method?: string | null;
  financing?: string | null;
  exam?: string | null;

  /** @format date-time */
  publishDate: string;

  /** @format date-time */
  unpublishDate?: string | null;
  enrollmentStatus?: string | null;
  defaultEnrollmentStudentStatus?: string | null;

  /** @format date-time */
  inserted: string;

  /** @format int32 */
  insertedBy: number;

  /** @format date-time */
  updated: string;

  /** @format int32 */
  updatedBy: number;
  course?: ApiCourse;
}

export interface ApiCourseEconomy {
  /** @format int32 */
  courseId: number;

  /** @format int32 */
  accountingDepartment: number;

  /** @format double */
  price: number;

  /** @format double */
  cancellationFee: number;

  /** @format int32 */
  grantRateId?: number | null;

  /** @format int32 */
  grantId?: number | null;
  allowExpenses: boolean;

  /** @format date-time */
  inserted: string;

  /** @format int32 */
  insertedBy: number;

  /** @format date-time */
  updated: string;

  /** @format int32 */
  updatedBy: number;
  course?: ApiCourse;
  grant?: ApiGrant;
  grantRate?: ApiGrantRate;
}

export interface ApiCourseEconomyBaseDataDto {
  economyDepartments?: ApiIdNameDtoType[] | null;
  articles?: ApiArticleDtoType[] | null;
  grants?: ApiGetGrantDto[] | null;
  grantRates?: ApiGetGrantRateDto[] | null;
}

export interface ApiCourseFile {
  /** @format int32 */
  courseId: number;

  /** @format int64 */
  fileId: number;

  /** @format date-time */
  inserted: string;

  /** @format int32 */
  insertedBy: number;
  course?: ApiCourse;
  file?: ApiFile;
}

export interface ApiCourseFinancedOrganization {
  /** @format int32 */
  courseId: number;

  /** @format int32 */
  customerOrganizationId: number;

  /** @format date-time */
  inserted: string;

  /** @format int32 */
  insertedBy: number;
  course?: ApiCourse;
  customerOrganization?: ApiCustomerOrganization;
}

export interface ApiCourseInfoDto {
  courseName?: string | null;

  /** @format date-time */
  enrollmentDeadline?: string | null;

  /** @format date-time */
  startDate: string;

  /** @format date-time */
  endDate: string;

  /** @format double */
  totalScheduledHours: number;
}

export interface ApiCourseInvitation {
  /** @format int32 */
  id: number;

  /** @format int32 */
  courseId: number;
  firstName?: string | null;
  lastName?: string | null;
  emailAddress?: string | null;
  phoneNumber?: string | null;
  status?: string | null;

  /** @format date-time */
  inserted: string;

  /** @format int32 */
  insertedBy: number;
  course?: ApiCourse;
}

export interface ApiCourseLocationDtoType {
  /** @format int32 */
  areaId?: number | null;

  /** @format int32 */
  locationId?: number | null;

  /** @format int32 */
  roomId?: number | null;
}

export interface ApiCourseNote {
  /** @format int32 */
  id: number;

  /** @format int32 */
  courseId: number;
  title?: string | null;
  text?: string | null;

  /** @format date-time */
  inserted: string;

  /** @format int32 */
  insertedBy: number;
  course?: ApiCourse;
}

export interface ApiCourseParticipant {
  /** @format int32 */
  courseId: number;

  /** @format int32 */
  userId: number;
  status?: string | null;
  statusComment?: string | null;
  requiresElectronicInvoice: boolean;

  /** @format int32 */
  memberOrganizationId?: number | null;
  appliedViaForm: boolean;

  /** @format date-time */
  inserted: string;

  /** @format int32 */
  insertedBy: number;

  /** @format date-time */
  updated: string;

  /** @format int32 */
  updatedBy: number;

  /** @format int32 */
  purchaserId?: number | null;

  /** @format int32 */
  invoiceRecipientId?: number | null;

  /** @format date-time */
  applicationDate?: string | null;

  /** @format int32 */
  rank?: number | null;
  isRead: boolean;
  course?: ApiCourse;
  memberOrganization?: ApiMemberOrganization;
  user?: ApiUser;
  courseContract?: ApiCourseContract[] | null;
  courseParticipantAttendance?: ApiCourseParticipantAttendance[] | null;
  courseParticipantFile?: ApiCourseParticipantFile[] | null;
  courseParticipantExpense?: ApiCourseParticipantExpense[] | null;
  courseParticipantRole?: ApiCourseParticipantRole[] | null;
  appFormResponse?: ApiAppFormResponse;
  purchaser?: ApiCustomerOrganization;
  invoiceRecipient?: ApiCustomerOrganization;
  employeeContractLine?: ApiEmployeeContractLine[] | null;
}

export interface ApiCourseParticipantAttendance {
  /** @format int32 */
  courseId: number;

  /** @format int32 */
  userId: number;

  /** @format date-time */
  scheduleStart: string;

  /** @format double */
  noOfHours: number;
  comment?: string | null;

  /** @format date-time */
  inserted: string;

  /** @format int32 */
  insertedBy: number;

  /** @format date-time */
  updated: string;

  /** @format int32 */
  updatedBy: number;
  courseParticipant?: ApiCourseParticipant;
  schedule?: ApiSchedule;
}

export interface ApiCourseParticipantAttendanceBaseDto {
  /**
   * Startdatoen og tidspunktet til timeplanen som fremmøtet gjelder for
   * @format date-time
   */
  scheduleStart: string;

  /**
   * Id'en til brukeren som fremmøtet gjelder for
   * @format int32
   */
  userId: number;

  /**
   * Antall timer brukeren var tilstede
   * @format double
   */
  noOfHours: number;

  /** Kommentaren til fremmøtet */
  comment?: string | null;
}

export interface ApiCourseParticipantAttendanceBaseDtoValidationEntry {
  validatedItem?: ApiCourseParticipantAttendanceBaseDto;

  /** The validation errors for the SYSCO.Universum.Api.Dtos.Validation.ValidationEntry`1.ValidatedItem */
  validationErrors?: string[] | null;
}

export interface ApiCourseParticipantAttendanceBaseDtoValidationResponseDto {
  /**
   * The general validation errors for the entire list of validated entries.
   * E.g. if the sum of entries makes it invalid, but the items themselves are valid,
   * the errors are more general.
   */
  generalValidationErrors?: string[] | null;
  validationList?: ApiCourseParticipantAttendanceBaseDtoValidationEntry[] | null;
}

export interface ApiCourseParticipantDto {
  /** @format int32 */
  userId: number;

  /** @format int32 */
  courseId: number;
}

export interface ApiCourseParticipantExpense {
  /** @format int32 */
  id: number;

  /** @format int32 */
  courseId: number;

  /** @format int32 */
  userId: number;

  /** @format int32 */
  costId: number;

  /** @format int64 */
  fileId: number;

  /** @format int32 */
  orderId?: number | null;
  status?: string | null;
  accountNumber?: string | null;
  description?: string | null;

  /** @format double */
  quantity: number;

  /** @format double */
  amount: number;

  /** @format double */
  sum: number;
  messageToParticipant?: string | null;

  /** @format date-time */
  inserted: string;

  /** @format int32 */
  insertedBy: number;

  /** @format date-time */
  updated: string;

  /** @format int32 */
  updatedBy: number;
  course?: ApiCourse;
  courseParticipant?: ApiCourseParticipant;
  file?: ApiFile;
  cost?: ApiCost;
  order?: ApiOrder;
}

export interface ApiCourseParticipantFile {
  /** @format int32 */
  courseId: number;

  /** @format int32 */
  userId: number;

  /** @format int64 */
  fileId: number;
  isAdminRead: boolean;

  /** @format date-time */
  inserted: string;

  /** @format int32 */
  insertedBy: number;
  courseParticipant?: ApiCourseParticipant;
  file?: ApiFile;
}

export interface ApiCourseParticipantRole {
  /** @format int32 */
  courseId: number;

  /** @format int32 */
  userId: number;
  roleName?: string | null;

  /** @format date-time */
  inserted: string;

  /** @format int32 */
  insertedBy: number;
  courseParticipant?: ApiCourseParticipant;
  roleNameNavigation?: ApiRole;
}

/**
 * @format int32
 */
export type ApiCourseResponsibleFilter = 0 | 1 | 2 | 3;

export interface ApiCourseSms {
  /** @format int32 */
  id: number;

  /** @format int32 */
  courseId: number;
  messageText?: string | null;

  /** @format date-time */
  inserted: string;

  /** @format int32 */
  insertedBy: number;
  recipients?: ApiCourseSmsRecipient[] | null;
  course?: ApiCourse;
}

export interface ApiCourseSmsRecipient {
  /** @format int32 */
  courseSmsId: number;

  /** @format int32 */
  userId: number;
  messageId?: string | null;
  mobileNumber?: string | null;
  courseSms?: ApiCourseSms;
}

export interface ApiCourseTeachingMaterial {
  /** @format int32 */
  teachingMaterialId: number;

  /** @format int32 */
  courseId: number;

  /** @format date-time */
  inserted: string;

  /** @format int32 */
  insertedBy: number;
  course?: ApiCourse;
  teachingMaterial?: ApiTeachingMaterial;
}

export interface ApiCourseTest {
  /** @format int32 */
  id: number;

  /** @format int32 */
  testTypeId: number;
  name?: string | null;
  description?: string | null;

  /** @format int32 */
  courseId: number;

  /** @format date-time */
  inserted: string;

  /** @format int32 */
  insertedBy: number;

  /** @format date-time */
  updated: string;

  /** @format int32 */
  updatedBy: number;
  course?: ApiCourse;
  testType?: ApiTestType;
}

export interface ApiCreateApplicationFormTemplateDto {
  name?: string | null;
  isVocationalSchool: boolean;
  isCitizenship: boolean;
  isPersonalNumber: boolean;
  isNorwegianMotherTongue: boolean;
  isNorwegianCertificationLevel: boolean;
  isNorwegianSkills: boolean;
  isCompetence: boolean;
  isEmployer: boolean;
  isEmployerEmail?: boolean | null;
  isCostBearer: boolean;
  isInstallments: boolean;
  isMonthly?: boolean | null;
  isSemiAnnually?: boolean | null;
  isQuarterly?: boolean | null;
  isInvoiceReference?: boolean | null;
  isNewsletter: boolean;
  isUnionized: boolean;
  isMemberOrganization: boolean;
  isRegionalOffices?: boolean | null;

  /** @format int32 */
  regulationFileId?: number | null;
  additionalQuestions?: ApiApplicationFormTemplateAdditionalQuestionDto[] | null;
}

export interface ApiCreateCommunicationTemplateDto {
  /**
   * Id'en malgruppen denne kommunikasjonsmalen gjelder for
   * @format int32
   */
  communicationTemplateGroupId: number;

  /** Navnet til kommunikasjonsmalen */
  name?: string | null;

  /** Emnet til kommunikasjonsmalen */
  subject?: string | null;

  /** Innholdet til kommunikasjonsmalen */
  content?: string | null;

  /** Om kommunikasjonsmalen er aktiv eller ikke */
  isActive: boolean;
}

export interface ApiCreateContractDto {
  accountNo?: string | null;
  isSelfEmployed?: boolean | null;

  /** @format int32 */
  customerOrganizationId?: number | null;
  note?: string | null;
  isPrimaryEmployer?: boolean | null;
  taxMunicipalityNo?: string | null;
  taxMunicipalityName?: string | null;

  /**
   * The role per contract can be different than the course participant role.
   * E.g. a resource can have a contract for a role that is different than his usual role.
   */
  roleName?: string | null;
  isActive?: boolean | null;
  contractLines?: ApiCreateContractLineDto[] | null;
}

export interface ApiCreateContractLineDto {
  /** @format int32 */
  costId: number;
  unit?: string | null;

  /** @format double */
  amount: number;

  /**
   * If the SYSCO.Universum.Api.Dtos.CreateContractLineDto.CostId is a cost where the price is not null,
   * the cost price will be saved for the contract line instead of the value of this input field.
   * @format double
   */
  price?: number | null;
  description?: string | null;
}

export interface ApiCreateCostDto {
  /** Kostnadstype / Navn */
  name?: string | null;

  /** Beskrivelse (kun brukt for utgifter) */
  description?: string | null;

  /**
   * Sats
   * @format double
   */
  price?: number | null;

  /**
   * Lønnsart (kun brukt for lønnskostnader)
   * @format int32
   */
  payType?: number | null;

  /** Arikkelnummer (kun brukt for utgifter) */
  articleNo?: string | null;

  /**
   * Trekkode (kun brukt for lønnskostnader)
   * @format int32
   */
  deductionCode?: number | null;

  /** Undervisningsform */
  teachingMethod?: string | null;

  /** Status */
  isActive?: boolean | null;

  /** @format int32 */
  costTypeId: number;
}

export interface ApiCreateCourseAreaDto {
  name?: string | null;
  ssbCode?: string | null;
  code?: string | null;
  isActive?: boolean | null;

  /** @format int32 */
  parentId?: number | null;
}

export interface ApiCreateCourseDto {
  courseName?: string | null;
  externalTitle?: string | null;
  useLearningPlatform: boolean;
  courseLocation?: ApiCourseLocationDtoType;

  /** @format int32 */
  studyLocationId?: number | null;

  /** @format date-time */
  cancellationDeadline?: string | null;

  /** @format date-time */
  startDate?: string | null;

  /** @format date-time */
  endDate?: string | null;
  isTimeWebVisible: boolean;

  /** @format int32 */
  hoursWithInstructor: number;

  /** @format int32 */
  hoursWithoutInstructor?: number | null;

  /** @format int32 */
  hoursOnline?: number | null;

  /** @format int32 */
  hoursSelfStudy?: number | null;

  /**
   * Kursansvarlig - kursadministratorbruker, ansatt.
   * @format int32
   */
  courseSupervisorId: number;

  /** @format int32 */
  organizerOrganizationId: number;

  /**
   * Fagansvarlig - kursadministratorbruker, ansatt.
   * @format int32
   */
  lecturerId: number;

  /**
   * Kontaktperson - kursadministratorbruker, ansatt.
   * @format int32
   */
  contactId: number;

  /**
   * KasId er id fra gamle kursadmin system (KAS) som skal avvikles
   * @format int32
   */
  kasId?: number | null;

  /** @format date-time */
  enrollmentDeadline?: string | null;

  /** @format date-time */
  unenrollmentDeadline?: string | null;

  /** @format int32 */
  minimumParticipants: number;

  /** @format int32 */
  maxParticipants: number;

  /** @format int32 */
  participantCapacity: number;

  /** Bedriftsinternt kurs (het fra gammelt av "Bestillingskurs"/"Kurset er bestilt") */
  isOrderable: boolean;
  plan?: ApiUpsertPlanDtoType;
  additionalTeachingMaterialIds?: number[] | null;

  /** Id'ene til organisasjoner som finansierer kurset */
  financedByOrganizationIds?: number[] | null;
  tests?: ApiUpsertTestDtoType[] | null;
  comments?: string | null;
  webDescription?: ApiUpsertCourseDescriptionDto;
  economy?: ApiUpsertCourseEconomyDtoType;

  /**
   * Id til studieplan
   * @format int32
   */
  curriculumId: number;
}

export interface ApiCreateCourseInvitationDto {
  name?: string | null;

  /** Fornavnet */
  firstName?: string | null;

  /** Etternavnet */
  lastName?: string | null;

  /** Eposten */
  emailAddress?: string | null;

  /** Telefonnummeret */
  phoneNumber?: string | null;

  /** Adressen (gateadresse og nummer) */
  postAddress?: string | null;

  /** Postnummeret */
  postZip?: string | null;

  /** Poststedet */
  postCity?: string | null;

  /** Kjønnet - K (Kvinne), M (Mann), A (Anonym) */
  sex?: string | null;

  /**
   * Fødselsdatoen. Bør sendes inn i UTC-tid hvor tiden er 00:00:00 (e.g. 2007-03-23T00:00:00)
   * @format date-time
   */
  birthDate?: string | null;

  /** Om det skal opprettes en begrenset bruker for personen hvis eposten ikke allerede finnes i systemet */
  createRestrictedUser: boolean;

  /** Om det skal sendes epost til brukeren om at de har mottatt en kursinvitasjon (vil ikke ble sendt for begrenset bruker) */
  sendEmail: boolean;

  /** Om det skal sendes sms til brukeren om at de har mottatt en kursinvitasjon (vil ikke ble sendt for begrenset bruker) */
  sendSms: boolean;
}

export interface ApiCreateDraftDto {
  /** Internfakturering */
  isInternal: boolean;

  /** Kundens bestillingsnummer – (manuelt felt, PH nummer, kan være tom) */
  customerOrderNo?: string | null;

  /** Reise/utleggsreferanse – (Refusjon kurs ____)(max 30 tegn) */
  expenseRef?: string | null;

  /** Deres referanse – (manuelt) */
  theirRef?: string | null;

  /**
   * Motbilag – (ved kreditering sende PK fra originale ordrenummer)
   * @format int32
   */
  contraEntryOrderId?: number | null;

  /**
   * Avdragstype – (type 0, 1 eller 2)
   * Type 0: Ingen avdrag
   * Type 1: Fast intervall
   *     Antall avdrag
   *     Intervall(eks.antall mnd mellom hvert avdrag)
   * Type 2: Liste med avdrag(må gå opp i 100%)
   *     Dato
   *     Prosent
   * @format int32
   * @min 0
   * @max 2
   */
  paymentType: number;

  /**
   * Antall avdrag – (bare fylt ut dersom type 1)
   * @format int32
   */
  noOfPayments?: number | null;

  /**
   * Avdragsintervall – (bare fylt ut dersom type 1)
   * @format int32
   */
  paymentInverval?: number | null;

  /** @format int32 */
  cardTransactionId?: number | null;
  cardOrderRef?: string | null;
  contraEntryOrdersId?: number[] | null;

  /** @format int32 */
  courseId: number;
  participant?: ApiOrderParticipantTemplateDto;
  invoiceRecipient?: ApiOrderInvoiceRecipientTemplateDto;

  /** Avdragslinjer (en liste, bare ved type 2) */
  orderPaymentLines?: ApiOrderPaymentLineDto[] | null;
  orderLines?: ApiCreateOrderLineDto[] | null;
}

export interface ApiCreateFacilitationGrantApplicationDto {
  applicationDescription?: string | null;
  descriptionOfAdditionalCost?: string | null;
  targetGroup?: string | null;
  location?: string | null;

  /** @format double */
  numberOfHours: number;

  /** @format int32 */
  numberOfParticipants: number;

  /** @format date-time */
  startDate: string;

  /** @format date-time */
  endDate: string;
  budgetLines?: ApiFacilitationCostsAppliedForDto[] | null;
}

export interface ApiCreateFacilitationGrantApplicationDtoValidationEntry {
  validatedItem?: ApiCreateFacilitationGrantApplicationDto;

  /** The validation errors for the SYSCO.Universum.Api.Dtos.Validation.ValidationEntry`1.ValidatedItem */
  validationErrors?: string[] | null;
}

export interface ApiCreateFacilitationGrantApplicationDtoValidationResponseDto {
  /**
   * The general validation errors for the entire list of validated entries.
   * E.g. if the sum of entries makes it invalid, but the items themselves are valid,
   * the errors are more general.
   */
  generalValidationErrors?: string[] | null;
  validationList?: ApiCreateFacilitationGrantApplicationDtoValidationEntry[] | null;
}

export interface ApiCreateHolidayDetailDto {
  periodName?: string | null;

  /** @format date-time */
  fromDate: string;

  /** @format date-time */
  toDate: string;
}

export interface ApiCreateHolidayDto {
  name?: string | null;
  isActive: boolean;
  details?: ApiCreateHolidayDetailDto[] | null;
}

export interface ApiCreateMemberOrganizationDto {
  name?: string | null;
  orgNo?: string | null;
  phone?: string | null;
  email?: string | null;
  url?: string | null;
  isWebVisible?: boolean | null;

  /** @format int32 */
  clientAccountNumber?: number | null;
  inheritOrgNoFromParent?: boolean | null;

  /** @format int32 */
  parentId?: number | null;
  isActive?: boolean | null;
}

export interface ApiCreateNewMessageMsDto {
  subject?: string | null;
  text?: string | null;
  attachments?: File[] | null;

  /** @format int32 */
  courseId: number;
}

export interface ApiCreateNewSystemCommunicationTemplateMessageMsDto {
  /** @format int32 */
  courseId: number;
  communicationTemplateTag?: string | null;
  dynamicVariables?: ApiStringStringKeyValuePair[] | null;
}

export interface ApiCreateOrderLineDto {
  /** Beskrivelse. Hvis oppgitt, overstyrer artikkelnavn */
  description?: string | null;

  /** Kan foreslås hentes fra CourseArticle */
  articleNo?: string | null;

  /**
   * @format double
   * @min 0
   * @max 10000000000000000
   */
  amount: number;

  /**
   * Rabatt
   * @format double
   * @min 0
   * @max 100
   */
  discount: number;

  /**
   * Antall – (positivt ved debitering, negativt ved kreditering)
   * @format double
   */
  quantity: number;
  user?: ApiOrderLineUserDto;
}

export interface ApiCreateRoleDto {
  description?: string | null;
  isActive?: boolean | null;
  isResource?: boolean | null;
  isForRegularCourse?: boolean | null;
  isForVocationalSchool?: boolean | null;
  cannotBeCombined?: boolean | null;
  requiresCertification?: boolean | null;
  name?: string | null;
}

export interface ApiCreateTeachingMaterialCategoryDto {
  name?: string | null;
  description?: string | null;
  attribute1?: string | null;
  attribute2?: string | null;
  attribute3?: string | null;
  attribute4?: string | null;
  attribute5?: string | null;
}

export interface ApiCreateWorkingHourDto {
  /**
   * The id'en til kurskontraktlinjen som det skal skrives timer for
   * @format int32
   */
  courseContractLineId: number;

  /**
   * Antall timer som skal registreres. Kan bli satt ned til nærmeste halvtime (e.g. 2.5)
   * @format double
   */
  amount: number;

  /**
   * <br>
   *             Hvis kostnadstypen for kontraktlinjen er 'Undervisningstimer' så må denne datoen/tidspunktet tilsvare en dato/tidspunkt i timeplanen.
   *
   *             Hvis kostnadstypen for kontraktlinjen ikke er 'Undervisningstimer' så må denne datoen tilsvare en dag hvor
   *             UTC tidspunktet er 00:00:00 på den angitte dagen og være for i dag eller en dag i fortiden.
   *
   * @format date-time
   */
  date: string;

  /** Kommentaren til de registrerte timene. Kan være opptil 500 karakterer lang. */
  comment?: string | null;
}

export interface ApiCurriculum {
  /** @format int32 */
  id: number;
  name?: string | null;

  /** @format int32 */
  organizationId: number;

  /** @format int32 */
  memberOrganizationId?: number | null;

  /** @format int32 */
  courseAreaId: number;
  teachingMethod?: string | null;
  cefrLevel?: string | null;

  /** @format double */
  hoursMin: number;

  /** @format double */
  hoursMax: number;

  /** @format double */
  hoursOnline?: number | null;

  /** @format int32 */
  levelDescriptionId: number;

  /** @format int32 */
  points?: number | null;

  /** @format int32 */
  evaluationDescriptionId: number;

  /** @format int32 */
  statusDescriptionId: number;
  ssbCode?: string | null;

  /** @format double */
  hoursStudyLoad?: number | null;

  /** @format int32 */
  subjectCount?: number | null;
  practice?: boolean | null;
  nusCode?: string | null;

  /** @format date-time */
  inserted: string;

  /** @format int32 */
  insertedBy: number;

  /** @format date-time */
  updated: string;

  /** @format int32 */
  updatedBy: number;
  courseArea?: ApiCourseArea;
  curriculumDetail?: ApiCurriculumDetail;
  virNusCode?: ApiNusCode;
  course?: ApiCourse[] | null;
  curriculumCustomerOrganization?: ApiCurriculumCustomerOrganization[] | null;
  curriculumFile?: ApiCurriculumFile[] | null;
  curriculumTeachingMaterial?: ApiCurriculumTeachingMaterial[] | null;
}

export interface ApiCurriculumCustomerOrganization {
  /** @format int32 */
  curriculumId: number;

  /** @format int32 */
  customerOrganizationId: number;
  curriculum?: ApiCurriculum;
  customerOrganization?: ApiCustomerOrganization;
}

export interface ApiCurriculumDetail {
  /** @format int32 */
  curriculumId: number;
  teacherRequired: boolean;
  teacherRequirements?: string | null;
  foreknowledgeRequired: boolean;
  foreknowledgeRequirements?: string | null;
  externalCurriculumUrl?: string | null;
  learningObjectives?: string | null;
  courseContent?: string | null;
  method?: string | null;
  courseCertificateRequired: boolean;
  webContent?: string | null;
  targetGroup?: string | null;
  curriculum?: ApiCurriculum;
}

export interface ApiCurriculumFile {
  /** @format int32 */
  curriculumId: number;

  /** @format int64 */
  fileId: number;

  /** @format date-time */
  inserted: string;

  /** @format int32 */
  insertedBy: number;
  curriculum?: ApiCurriculum;
  file?: ApiFile;
}

export interface ApiCurriculumTeachingMaterial {
  /** @format int32 */
  teachingMaterialId: number;

  /** @format int32 */
  curriculumId: number;

  /** @format date-time */
  inserted: string;

  /** @format int32 */
  insertedBy: number;
  curriculum?: ApiCurriculum;
  teachingMaterial?: ApiTeachingMaterial;
}

export interface ApiCustomerOrganization {
  /** @format int32 */
  userId: number;
  orgNumber?: string | null;
  homePage?: string | null;
  postAddress1?: string | null;
  postAddress2?: string | null;
  postAddress3?: string | null;
  postCity?: string | null;
  postZip?: string | null;
  postCountry?: string | null;
  invoiceAddress1?: string | null;
  invoiceAddress2?: string | null;
  invoiceAddress3?: string | null;
  invoiceCity?: string | null;
  invoiceZip?: string | null;
  invoiceCountry?: string | null;
  phoneNumber?: string | null;
  mobileNumber?: string | null;
  email?: string | null;
  user?: ApiUser;
  courseContract?: ApiCourseContract[] | null;
  curriculumCustomerOrganization?: ApiCurriculumCustomerOrganization[] | null;
  courseFinancedOrganization?: ApiCourseFinancedOrganization[] | null;
  courseParticipantPurchaser?: ApiCourseParticipant[] | null;
  courseParticipantInvoiceRecipient?: ApiCourseParticipant[] | null;
}

export interface ApiCustomerOrgDto {
  /** @format int32 */
  customerId: number;
  name?: string | null;
  orgNumber?: string | null;
  homePage?: string | null;
  isActive: boolean;
}

export interface ApiCustomerPerson {
  /** @format int32 */
  userId: number;

  /** @format uuid */
  adUserId?: string | null;
  ssno?: string | null;
  email?: string | null;
  phoneNumber?: string | null;
  mobileNumber?: string | null;
  postAddress?: string | null;
  postZip?: string | null;
  postCity?: string | null;
  postCountry?: string | null;
  sex?: string | null;
  termsOfUsage?: string | null;

  /** @format date-time */
  birthDate?: string | null;
  type?: string | null;
  bankAccountNo?: string | null;

  /** @format date-time */
  lastVerifiedContactInfoDate: string;
  lastApprovedTermsOfUsage?: string | null;
  receiveNewsletter: boolean;

  /** @format int32 */
  birthYear?: number | null;
  user?: ApiUser;
}

export interface ApiDepartment {
  /** @format int32 */
  organizationId: number;

  /** @format int32 */
  parentId?: number | null;
  name?: string | null;
  email?: string | null;
  tag?: string | null;
  isWebVisible: boolean;

  /** @format int32 */
  erpClientId?: number | null;
  organization?: ApiOrganization;
  ownedCourse?: ApiCourse[] | null;
  employee?: ApiEmployee[] | null;
  regulationFile?: ApiRegulationFile[] | null;
  studyLocation?: ApiStudyLocation[] | null;
  nusCode?: ApiNusCode[] | null;
}

export interface ApiEmployee {
  /** @format int32 */
  userId: number;

  /** @format int32 */
  organizationId: number;
  email?: string | null;
  phoneNumber?: string | null;

  /** @format uuid */
  adUserId?: string | null;

  /** @format int32 */
  erpEmployeeId?: number | null;
  department?: ApiDepartment;
  user?: ApiUser;
  coursesContact?: ApiCourse[] | null;
  coursesSupervisor?: ApiCourse[] | null;
  coursesLecturer?: ApiCourse[] | null;
  orderLines?: ApiOrderLine[] | null;
  workingHoursApproved?: ApiWorkingHour[] | null;
  workingHoursDeclined?: ApiWorkingHour[] | null;
  facilitationGrantApplicationsAppliedFor?: ApiFacilitationGrantApplication[] | null;
}

export interface ApiEmployeeContractLine {
  /** @format int32 */
  id: number;

  /** @format int32 */
  courseId: number;

  /** @format int32 */
  userId: number;

  /** @format double */
  amountOfHours: number;
  description?: string | null;

  /** @format date-time */
  inserted: string;

  /** @format int32 */
  insertedBy: number;

  /** @format date-time */
  updated: string;

  /** @format int32 */
  updatedBy: number;
  courseParticipant?: ApiCourseParticipant;
}

export interface ApiEmployerDto {
  name?: string | null;
  address?: string | null;
  email?: string | null;
  zip?: string | null;
  city?: string | null;
}

export interface ApiErpEmployeeDtoType {
  /** @format int32 */
  employeeNo: number;
  firstName?: string | null;
  lastName?: string | null;
  email?: string | null;
  ssn?: string | null;
}

export interface ApiExecutionPlanCertificateDtoType {
  courseCertificateRequired: boolean;
  gradeCertificateRequired: boolean;
  diplomaRequired: boolean;
}

export interface ApiExecutionPlanDto {
  info?: ApiExecutionPlanInfoDtoType;
  plan: boolean;
  economyRequired: boolean;
  certificatesRequired: boolean;
  certificate?: ApiExecutionPlanCertificateDtoType;
  teachingMaterialsRequired: boolean;
  testsRequired: boolean;
  testDescriptionRequired: boolean;
}

export interface ApiExecutionPlanInfoDtoType {
  reasonForStatusChangeRequired: boolean;
  cancellationDeadlineRequired: boolean;
  hoursWithoutInstructorRequired: boolean;
  hoursSelfStudyRequired: boolean;
  enrollmentDeadlineRequired: boolean;
  unenrollmentDeadlineRequired: boolean;
  minimumParticipantsRequired: boolean;
  maximumParticipantsRequired: boolean;
  availableParticipantsRequired: boolean;
  financiersRequired: boolean;
  priceRequired: boolean;
  additionalFilesRequired: boolean;
  groupIdRequired: boolean;
}

export interface ApiFacilitationActualCostDto {
  budgetLineType: ApiFacilitationGrantBudgetLineType;

  /** @format double */
  actualCost: number;
}

export interface ApiFacilitationCostGrantedDto {
  budgetLineType: ApiFacilitationGrantBudgetLineType;

  /** @format double */
  amountGranted: number;
}

export interface ApiFacilitationCostsAppliedForDto {
  budgetLineType: ApiFacilitationGrantBudgetLineType;

  /** @format double */
  amountAppliedFor: number;
}

export interface ApiFacilitationCostsDto {
  budgetLineType: ApiFacilitationGrantBudgetLineType;

  /** @format double */
  amountAppliedFor: number;

  /** @format double */
  amountGranted: number;

  /** @format double */
  amountActualCost: number;
}

export interface ApiFacilitationGrantApplication {
  /** @format int32 */
  courseId: number;
  applicationStatus?: string | null;

  /** @format int32 */
  applicantUserId: number;
  applicationDescription?: string | null;
  descriptionOfAdditionalCost?: string | null;
  targetGroup?: string | null;
  location?: string | null;

  /** @format double */
  numberOfHours: number;

  /** @format int32 */
  numberOfParticipants: number;

  /** @format date-time */
  startDate: string;

  /** @format date-time */
  endDate: string;
  commentToGrant?: string | null;
  commentToActualCost?: string | null;

  /** @format date-time */
  inserted: string;

  /** @format date-time */
  updated: string;

  /** @format int32 */
  insertedBy: number;

  /** @format int32 */
  updatedBy: number;
  course?: ApiCourse;
  budgetLines?: ApiFacilitationGrantApplicationBudgetLine[] | null;
  attachments?: ApiFacilitationGrantApplicationFile[] | null;
  applicantEmployee?: ApiEmployee;
}

export interface ApiFacilitationGrantApplicationBudgetLine {
  /** @format int32 */
  courseId: number;

  /** @format int32 */
  grantApplicationBudgetLineTypeId: number;

  /** @format double */
  amountAppliedFor: number;

  /** @format double */
  amountGranted: number;

  /** @format double */
  amountActualCost: number;
  facilitationGrantApplication?: ApiFacilitationGrantApplication;
}

export interface ApiFacilitationGrantApplicationFile {
  /** @format int32 */
  courseId: number;

  /** @format int64 */
  fileId: number;

  /** @format date-time */
  inserted: string;

  /** @format int32 */
  insertedBy: number;
  facilitationGrantApplication?: ApiFacilitationGrantApplication;
  file?: ApiFile;
}

/**
 * @format int32
 */
export type ApiFacilitationGrantBudgetLineType = 0 | 1 | 2 | 3 | 4 | 5;

export interface ApiFile {
  /** @format int64 */
  id: number;
  originalFileName?: string | null;
  mimeType?: string | null;
  description?: string | null;
  externalFileName?: string | null;
  externalContainer?: string | null;
  externalUrl?: string | null;
  category?: string | null;

  /** @format date-time */
  inserted: string;

  /** @format int32 */
  insertedBy: number;
  courseFile?: ApiCourseFile[] | null;
  courseParticipantFile?: ApiCourseParticipantFile[] | null;
  curriculumFile?: ApiCurriculumFile[] | null;
  courseContractFileSigned?: ApiCourseContractFile;
  courseContractFileUnsigned?: ApiCourseContractFile;
  regulationFile?: ApiRegulationFile;
  workingHourFile?: ApiWorkingHourFile;
  courseParticipantExpense?: ApiCourseParticipantExpense;
  appFormResponseFile?: ApiAppFormResponseFile;
  appFormResponseAdditionalQuestion?: ApiAppFormResponseAdditionalQuestion;
  facilitationGrantApplicationFile?: ApiFacilitationGrantApplicationFile[] | null;
}

export interface ApiFileOwnerDto {
  ownerName?: string | null;

  /** @format int32 */
  ownerId: number;
  ownerType?: string | null;
}

export interface ApiFormalCompetenciesDto {
  isNorwegianHealthAndSocialSciences?: boolean | null;
  isAuthorizationDiploma?: boolean | null;
  isForeignHealthAndSocialSciences?: boolean | null;
}

export interface ApiGenerateEmailVerificationCodeDto {
  /** The new email address to send verification code to */
  emailAddress?: string | null;
}

export interface ApiGenerateOrganizationOrderTemplateDto {
  /** @format int32 */
  courseId: number;

  /**
   * Bestiller - enten deltager eller firma
   * @format int32
   */
  customerUserId: number;

  /**
   * Fakturamottaker - enten deltager eller firma
   * @format int32
   */
  invoiceRecipientUserId: number;
  articles?: ApiArticleDto[] | null;
}

export interface ApiGenerateParticipantOrderTemplateDto {
  /** @format int32 */
  courseId: number;

  /**
   * Bestiller - enten deltager eller firma
   * @format int32
   */
  customerUserId: number;

  /**
   * Fakturamottaker - enten deltager eller firma
   * @format int32
   */
  invoiceRecipientUserId: number;
  articles?: ApiArticleDto[] | null;

  /** Deltagere - enten registrerte med ID eller navn. */
  userIds?: number[] | null;
}

export interface ApiGenerateTemplateDto {
  /** @format int32 */
  courseId: number;

  /**
   * Bestiller - enten deltager eller firma
   * @format int32
   */
  customerUserId: number;

  /**
   * Fakturamottaker - enten deltager eller firma
   * @format int32
   */
  invoiceRecipientUserId: number;
  articles?: ApiArticleDto[] | null;

  /** Deltagere - enten registrerte med ID eller navn. */
  userIds?: number[] | null;
  articleNos?: string[] | null;
}

export interface ApiGetAppFormResponseAdditionalQuestionDto {
  file?: ApiBaseGetFileDto;
  response?: string | null;

  /** @format uuid */
  questionId: string;
}

export interface ApiGetApplicationFormResponseDto {
  /** @format uuid */
  templateId: string;
  employer?: ApiEmployerDto;
  isCostBearerPrivate?: boolean | null;
  costBearerOrganization?: ApiCostBearerOrganizationDto;
  purchaser?: ApiPurchaserOrganizationDto;
  isFormalCompetence?: boolean | null;
  isNorwegianCitizen?: boolean | null;
  citizenship?: string | null;
  isNorwegianMotherTongue?: boolean | null;
  norwegianSkillsLevel?: string | null;
  norwegianSkillsCerfLevel?: string | null;
  personalNumber?: string | null;
  isNewsletter?: boolean | null;
  isUnionized?: boolean | null;

  /** @format int32 */
  memberOrganizationId?: number | null;
  courseRegulationsAccepted?: boolean | null;

  /** @format int32 */
  regulationsFileId?: number | null;
  files?: ApiBaseGetFileDto[] | null;
  additionalQuestions?: ApiGetAppFormResponseAdditionalQuestionDto[] | null;
  costBearerPrivate?: ApiCostBearerPrivateDto;
  formalCompetencies?: ApiFormalCompetenciesDto;

  /** @format date-time */
  inserted?: string | null;

  /** @format date-time */
  updated?: string | null;
}

export interface ApiGetApplicationFormTemplateDto {
  name?: string | null;
  isVocationalSchool: boolean;
  isCitizenship: boolean;
  isPersonalNumber: boolean;
  isNorwegianMotherTongue: boolean;
  isNorwegianCertificationLevel: boolean;
  isNorwegianSkills: boolean;
  isCompetence: boolean;
  isEmployer: boolean;
  isEmployerEmail?: boolean | null;
  isCostBearer: boolean;
  isInstallments: boolean;
  isMonthly?: boolean | null;
  isSemiAnnually?: boolean | null;
  isQuarterly?: boolean | null;
  isInvoiceReference?: boolean | null;
  isNewsletter: boolean;
  isUnionized: boolean;
  isMemberOrganization: boolean;
  isRegionalOffices?: boolean | null;

  /** @format int32 */
  regulationFileId?: number | null;
  additionalQuestions?: ApiApplicationFormTemplateAdditionalQuestionDto[] | null;

  /** @format uuid */
  id: string;
  isActive: boolean;
  appliedInCourses?: number[] | null;

  /** @format date-time */
  inserted: string;

  /** @format int32 */
  insertedBy: number;

  /** @format date-time */
  updated: string;

  /** @format int32 */
  updatedBy: number;
}

export interface ApiGetAreaDto {
  /** @format int32 */
  id: number;
  country?: string | null;
  countryCode?: string | null;
  county?: string | null;
  municipality?: string | null;
  place?: string | null;
  studyLocationCode?: string | null;

  /** @format date-time */
  inserted: string;

  /** @format int32 */
  insertedBy: number;

  /** @format date-time */
  updated: string;

  /** @format int32 */
  updatedBy: number;
}

export interface ApiGetClientAccountRateDto {
  centralCourseRates?: ApiAccountRatesDto;
  localCourseRates?: ApiAccountRatesDto;
  productContributions?: ApiProductContributionDto;

  /** @format double */
  governmentContribution?: number | null;

  /** @format double */
  grantedAmount?: number | null;

  /** @format double */
  balance?: number | null;
  isActive: boolean;

  /** @format int32 */
  year: number;

  /** @format int32 */
  memberOrganizationId: number;
  memberOrganizationName?: string | null;

  /** @format int32 */
  clientAccountNumber?: number | null;

  /** @format date-time */
  inserted: string;

  /** @format int32 */
  insertedBy: number;
  insertedByName?: string | null;

  /** @format date-time */
  updated: string;

  /** @format int32 */
  updatedBy: number;
  updatedByName?: string | null;
}

export interface ApiGetClientAccountRateOverviewDto {
  /** @format int32 */
  year: number;

  /** @format date-time */
  lastUpdated: string;
  isActive: boolean;
}

export interface ApiGetCommunicationTemplateDto {
  /**
   * Id'en malgruppen denne kommunikasjonsmalen gjelder for
   * @format int32
   */
  communicationTemplateGroupId: number;

  /** Navnet til kommunikasjonsmalen */
  name?: string | null;

  /** Emnet til kommunikasjonsmalen */
  subject?: string | null;

  /** Innholdet til kommunikasjonsmalen */
  content?: string | null;

  /** Om kommunikasjonsmalen er aktiv eller ikke */
  isActive: boolean;

  /**
   * Id'en til kommunikasjonsmalen
   * @format int32
   */
  id: number;

  /** Taggen til kommunikasjonsmalen. Kun brukt for system-maler som har ekstra logikk knyttet til seg. */
  tag?: string | null;

  /**
   * Id'en til organisasjonen for malgruppen denne kommunikasjonsmalen er under
   * @format int32
   */
  communicationTemplateGroupOrganizationId?: number | null;

  /** Gruppenavnet til malgruppen denne kommunikasjonsmalen er under */
  communicationTemplateGroupName?: string | null;

  /** Typen til malgruppen denne kommunikasjonsmalen er under */
  communicationTemplateGroupType?: string | null;

  /** @format date-time */
  inserted?: string | null;

  /** @format int32 */
  insertedBy?: number | null;
  insertedByUserFullName?: string | null;

  /** @format date-time */
  updated?: string | null;

  /** @format int32 */
  updatedBy?: number | null;
  updatedByUserFullName?: string | null;
}

export interface ApiGetCommunicationTemplateGroupDto {
  /** Navnet til malgruppen */
  name?: string | null;

  /** Type malgruppe (e.g. system, bruker) */
  type?: string | null;

  /**
   * Id'en til organisasjonen for malgruppen
   * @format int32
   */
  organizationId?: number | null;

  /**
   * Id'en til malgruppen
   * @format int32
   */
  id: number;

  /** @format date-time */
  inserted?: string | null;

  /** @format int32 */
  insertedBy?: number | null;

  /** @format date-time */
  updated?: string | null;

  /** @format int32 */
  updatedBy?: number | null;
}

export interface ApiGetContractFileDto {
  /** @format int32 */
  courseContractId: number;

  /** @format int64 */
  signedFileId?: number | null;

  /** @format int64 */
  unsignedFileId: number;

  /** @format date-time */
  inserted: string;

  /** @format int32 */
  insertedBy: number;

  /** @format date-time */
  updated: string;

  /** @format int32 */
  updatedBy: number;
}

export interface ApiGetContractLineDtoType {
  /**
   * Id'en til kontraktlinjen
   * @format int32
   */
  id: number;

  /**
   * Id'en til kontrakten
   * @format int32
   */
  courseContractId: number;

  /**
   * Id'en til kostnaden for kontraktlinjen
   * @format int32
   */
  costId: number;

  /** Navnet på kostnadstypen (type timer) for kostnaden til kontraktlinjen */
  costTypeName?: string | null;

  /** Hvilken type antallet er (vanligvis bare timer) */
  unit?: string | null;

  /**
   * Maks antall (vanligvis bare timer) for denne kontraktlinjen
   * @format double
   */
  amount: number;

  /**
   * Dette er antall timer minus alle registrerte arbeidstimer for denne kontraktlinjen uansett status på timene
   * @format double
   */
  remainingAmount: number;

  /**
   * Dette er antall timer minus alle registrerte arbeidstimer for denne kontraktlinjen som har status godkjent eller til utbetaling
   * @format double
   */
  remainingApprovedAmount: number;

  /**
   * Dette er antall arbeidstimer for denne kontraktlinjen som har status godkjent eller til utbetaling
   * @format double
   */
  approvedWorkingHours: number;

  /**
   * Dette er antall arbeidstimer for denne kontraktlinjen uansett status på timene
   * @format double
   */
  workingHours: number;

  /**
   * Prisen
   * @format double
   */
  price?: number | null;

  /** Beskrivelsen på kontraktlinjen */
  description?: string | null;

  /** @format date-time */
  inserted: string;
  insertedBy?: string | null;

  /** @format date-time */
  updated: string;
  updatedBy?: string | null;
}

export interface ApiGetContractSimpleDto {
  /** @format int32 */
  id: number;

  /** @format int32 */
  courseId: number;

  /** @format int32 */
  userId: number;

  /**
   * <br>
   *             not_sent : This status is used when the process of generating a pdf and
   *             sending it for signing through signicat has NOT started (e.g. new contract)
   *
   *             sent_but_not_signed : This status is used when the process of generating a pdf and
   *             sending it for signing through signicat has started or finished
   *             signed : This status is used when the person who received the contract has signed it
   *
   */
  status?: string | null;
  statusText?: string | null;
  accountNo?: string | null;
  isSelfEmployed: boolean;

  /** @format int32 */
  customerOrganizationId?: number | null;
  note?: string | null;
  isPrimaryEmployer: boolean;
  taxMunicipalityNo?: string | null;
  taxMunicipalityName?: string | null;

  /**
   * The role per contract can be different than the course participant role.
   * E.g. a resource can have a contract for a role that is different than his usual role.
   */
  roleName?: string | null;
  isActive: boolean;

  /** @format date-time */
  inserted: string;

  /** @format int32 */
  insertedBy: number;

  /** @format date-time */
  updated: string;

  /** @format int32 */
  updatedBy: number;
}

export interface ApiGetContractWithFileDto {
  /** @format int32 */
  id: number;

  /** @format int32 */
  courseId: number;

  /** @format int32 */
  userId: number;

  /**
   * <br>
   *             not_sent : This status is used when the process of generating a pdf and
   *             sending it for signing through signicat has NOT started (e.g. new contract)
   *
   *             sent_but_not_signed : This status is used when the process of generating a pdf and
   *             sending it for signing through signicat has started or finished
   *             signed : This status is used when the person who received the contract has signed it
   *
   */
  status?: string | null;
  statusText?: string | null;
  accountNo?: string | null;
  isSelfEmployed: boolean;

  /** @format int32 */
  customerOrganizationId?: number | null;
  note?: string | null;
  isPrimaryEmployer: boolean;
  taxMunicipalityNo?: string | null;
  taxMunicipalityName?: string | null;

  /**
   * The role per contract can be different than the course participant role.
   * E.g. a resource can have a contract for a role that is different than his usual role.
   */
  roleName?: string | null;
  isActive: boolean;

  /** @format date-time */
  inserted: string;

  /** @format int32 */
  insertedBy: number;

  /** @format date-time */
  updated: string;

  /** @format int32 */
  updatedBy: number;
  contractLines?: ApiGetContractLineDtoType[] | null;
  contractFile?: ApiGetContractFileDto;
}

export interface ApiGetCostDto {
  /**
   * Id'en til kostnaden
   * @format int32
   */
  id: number;

  /**
   * Id'en til organisasjonen/avdelingen denne kostnaden er knyttet til
   * @format int32
   */
  organizationId: number;

  /**
   * Id'en til kostnadstypen
   * @format int32
   */
  costTypeId: number;

  /** Gruppen/typen til kostnadstypen */
  costTypeCostGroup?: string | null;

  /** Navnet til kostnadstypen (kategori) */
  costTypeName?: string | null;

  /** Kostnadstype / Navn */
  name?: string | null;

  /** Beskrivelse (kun brukt for utgifter) */
  description?: string | null;

  /**
   * Sats
   * @format double
   */
  price: number;

  /**
   * Lønnsart
   * @format int32
   */
  payType?: number | null;

  /** Arikkelnummer */
  articleNo?: string | null;

  /**
   * Trekkode
   * @format int32
   */
  deductionCode?: number | null;

  /** Undervisningsform */
  teachingMethod?: string | null;

  /** Status */
  isActive: boolean;

  /** @format date-time */
  inserted: string;

  /** @format int32 */
  insertedBy: number;

  /** @format date-time */
  updated: string;

  /** @format int32 */
  updatedBy: number;
}

export interface ApiGetCostTypeDto {
  /** @format int32 */
  id: number;
  costGroup?: string | null;
  name?: string | null;

  /** @format date-time */
  inserted: string;
}

export interface ApiGetCourseAreaDto {
  /** @format int32 */
  id: number;

  /** @format int32 */
  parentId?: number | null;
  name?: string | null;
  ssbCode?: string | null;
  code?: string | null;
  isActive: boolean;

  /** @format date-time */
  inserted: string;

  /** @format int32 */
  insertedBy: number;

  /** @format date-time */
  updated: string;

  /** @format int32 */
  updatedBy: number;
}

export interface ApiGetCourseDefaultOptionDto {
  courseStatuses?: string[] | null;
  courseEnrollmentStatuses?: string[] | null;
  participantStatuses?: string[] | null;
  vocationalSchoolParticipantStatuses?: string[] | null;
  memberOrganizationParticipantStatuses?: string[] | null;
}

export interface ApiGetCourseDescriptionBaseDto {
  /** @format uuid */
  templateId: string;
  webCategory?: string | null;
  intro?: string | null;
  targetGroup?: string | null;
  foreknowledgeRequirements?: string | null;
  documentationRequired: boolean;
  learningObjectives?: string | null;
  courseContent?: string | null;
  method?: string | null;
  financing?: string | null;
  exam?: string | null;

  /** @format date-time */
  publishDate: string;

  /** @format date-time */
  unpublishDate?: string | null;
  enrollmentStatus?: string | null;
  defaultEnrollmentStudentStatus?: string | null;

  /** @format date-time */
  inserted: string;

  /** @format int32 */
  insertedBy: number;

  /** @format date-time */
  updated: string;

  /** @format int32 */
  updatedBy: number;
}

export interface ApiGetCourseDescriptionDto {
  /** @format uuid */
  templateId: string;
  webCategory?: string | null;
  intro?: string | null;
  targetGroup?: string | null;
  foreknowledgeRequirements?: string | null;
  documentationRequired: boolean;
  learningObjectives?: string | null;
  courseContent?: string | null;
  method?: string | null;
  financing?: string | null;
  exam?: string | null;

  /** @format date-time */
  publishDate: string;

  /** @format date-time */
  unpublishDate?: string | null;
  enrollmentStatus?: string | null;
  defaultEnrollmentStudentStatus?: string | null;

  /** @format date-time */
  inserted: string;

  /** @format int32 */
  insertedBy: number;

  /** @format date-time */
  updated: string;

  /** @format int32 */
  updatedBy: number;

  /** @format int32 */
  courseId: number;
}

export interface ApiGetCourseDraftDtoType {
  content?: string | null;
  method?: string | null;
  teachingMaterials?: ApiGetTeachingMaterialDto[] | null;
}

export interface ApiGetCourseDto {
  /** @format int32 */
  id: number;

  /** @format int32 */
  curriculumId: number;
  status?: string | null;
  courseName?: string | null;
  externalTitle?: string | null;
  reasonForStatusChange?: string | null;
  useLearningPlatform: boolean;
  courseLocation?: ApiCourseLocationDtoType;

  /** @format int32 */
  studyLocationId?: number | null;

  /** @format date-time */
  cancellationDeadline?: string | null;

  /** @format date-time */
  startDate: string;

  /** @format date-time */
  endDate: string;
  isTimeWebVisible: boolean;

  /** @format int32 */
  hoursWithInstructor: number;

  /** @format int32 */
  hoursWithoutInstructor?: number | null;

  /** @format int32 */
  hoursOnline?: number | null;

  /** @format int32 */
  hoursSelfStudy?: number | null;

  /**
   * Antall (timeplan)timer gjennomført (timeplan-timer som er i fortiden)
   * @format double
   */
  completedScheduledHours: number;

  /**
   * Antall (timeplan)timer gjenværende
   * @format double
   */
  remainingScheduledHours: number;

  /**
   * Totalt antall (timeplan)timer
   * @format double
   */
  totalScheduledHours: number;

  /** @format int32 */
  courseSupervisorId: number;

  /** @format int32 */
  organizerOrganizationId: number;

  /** @format int32 */
  ownerOrganizationId: number;

  /** @format int32 */
  lecturerId: number;

  /** @format int32 */
  contactId: number;

  /**
   * KasId er id fra gamle kursadmin system (KAS) som skal avvikles
   * @format int32
   */
  kasId?: number | null;

  /** @format date-time */
  enrollmentDeadline?: string | null;

  /** @format date-time */
  unenrollmentDeadline?: string | null;

  /** @format int32 */
  minimumParticipants: number;

  /** @format int32 */
  maxParticipants: number;

  /**
   * Vises kun hvis bruker-context er en ressurs
   * @format int32
   */
  participantSubscribedCount?: number | null;

  /** @format int32 */
  participantCapacity: number;

  /** Id'ene til organisasjoner som finansierer kurset */
  financedByOrganizationIds?: number[] | null;

  /** Bedriftsinternt kurs (het fra gammelt av "Bestillingskurs"/"Kurset er bestilt") */
  isOrderable: boolean;
  plan?: ApiGetPlanDtoType;
  additionalTeachingMaterialIds?: number[] | null;

  /** Id'ene til læremidlene for studieplanen for dette kurset */
  curriculumTeachingMaterialIds?: number[] | null;
  tests?: ApiGetTestDtoType[] | null;
  comments?: string | null;
  economy?: ApiGetCourseEconomyDtoType;
  webDescription?: ApiGetCourseDescriptionBaseDto;
  teachingMethod?: string | null;

  /** @format int32 */
  mainCourseId?: number | null;

  /** @format int32 */
  facilitationCourseId?: number | null;

  /** @format date-time */
  inserted: string;

  /** @format int32 */
  insertedBy: number;

  /** @format date-time */
  updated: string;

  /** @format int32 */
  updatedBy: number;
}

export interface ApiGetCourseEconomyDtoType {
  /** @format int32 */
  accountingDepartment?: number | null;

  /** @format double */
  cancellationFee?: number | null;

  /** @format double */
  price?: number | null;

  /**
   * Id til opplæringstilskudd
   * @format int32
   */
  grantId?: number | null;

  /** @format int32 */
  grantRateYear?: number | null;

  /** @format int32 */
  grantRateVersion?: number | null;
  allowExpenses?: boolean | null;
  articleNos?: string[] | null;
}

export interface ApiGetCourseInvitationDto {
  /**
   * Id'en til invitasjonen
   * @format int32
   */
  id: number;

  /**
   * Id'en til kurset invitasjonen gjelder for
   * @format int32
   */
  courseId: number;

  /** Fornavnet */
  firstName?: string | null;

  /** Etternavnet */
  lastName?: string | null;

  /** Eposten */
  emailAddress?: string | null;

  /** Telefonnummeret */
  phoneNumber?: string | null;

  /** Statusen på invitasjonen - new, approved, declined */
  status?: string | null;

  /** @format date-time */
  inserted: string;
  course?: ApiCourseInfoDto;
}

export interface ApiGetCourseNoteDto {
  /** @format int32 */
  id: number;
  title?: string | null;
  text?: string | null;

  /** @format date-time */
  inserted: string;

  /** @format int32 */
  insertedBy: number;
  insertedByUserFullName?: string | null;
}

export interface ApiGetCourseParticipantAttendanceDto {
  /**
   * Startdatoen og tidspunktet til timeplanen som fremmøtet gjelder for
   * @format date-time
   */
  scheduleStart: string;

  /**
   * Id'en til brukeren som fremmøtet gjelder for
   * @format int32
   */
  userId: number;

  /**
   * Antall timer brukeren var tilstede
   * @format double
   */
  noOfHours: number;

  /** Kommentaren til fremmøtet */
  comment?: string | null;

  /**
   * Id'en til kurset som fremmøtet gjelder for
   * @format int32
   */
  courseId: number;

  /**
   * Sluttdatoen og tidspunktet til timeplanen som fremmøtet gjelder for
   * @format date-time
   */
  scheduleEnd: string;

  /**
   * Antall timer mellom start- og sluttdatoen til timeplanen som fremmøtet gjelder for
   * @format double
   */
  scheduleHours: number;

  /** Brukerens fulle navn */
  userFullName?: string | null;

  /** Om fremmøtet er registrert av en bruker */
  isRegistered: boolean;

  /** Om fremmøtet er registrert av en KursAdmin-bruker */
  isSetByKursAdmin: boolean;

  /** @format date-time */
  inserted?: string | null;

  /** @format int32 */
  insertedBy?: number | null;

  /** @format date-time */
  updated?: string | null;

  /** @format int32 */
  updatedBy?: number | null;
  updatedByUserFullName?: string | null;
}

export interface ApiGetCourseParticipantDto {
  /** @format int32 */
  courseId: number;

  /** @format int32 */
  userId: number;
  roleName?: string | null;
  roleNames?: string[] | null;
  status?: string | null;
  requiresElectronicInvoice: boolean;

  /** @format int32 */
  memberOrganizationId?: number | null;
  appliedViaForm: boolean;

  /** @format date-time */
  inserted: string;

  /** @format int32 */
  insertedBy: number;

  /** @format date-time */
  updated: string;

  /** @format int32 */
  updatedBy: number;

  /** @format date-time */
  applicationDate?: string | null;
  course?: ApiGetCourseDto;
  userFullName?: string | null;
  isActive: boolean;

  /**
   * Vises kun hvis bruker-context er en ressurs.
   * Hvis kontraktstatusen ikke er signert så vil den kjøre en statussjekk mot Signicat før denne verdien fylles.
   */
  isContractSigned?: boolean | null;
}

export interface ApiGetCourseParticipantDtoType {
  /** @format int32 */
  courseId: number;

  /** @format int32 */
  userId: number;
  roleName?: string | null;
  roleNames?: string[] | null;
  status?: string | null;
  requiresElectronicInvoice: boolean;

  /** @format int32 */
  memberOrganizationId?: number | null;
  appliedViaForm: boolean;

  /** @format date-time */
  inserted: string;

  /** @format int32 */
  insertedBy: number;

  /** @format date-time */
  updated: string;

  /** @format int32 */
  updatedBy: number;

  /** @format date-time */
  applicationDate?: string | null;
}

export interface ApiGetCourseParticipantKursAdminDto {
  /** @format int32 */
  courseId: number;

  /** @format int32 */
  userId: number;
  roleName?: string | null;
  roleNames?: string[] | null;
  status?: string | null;
  requiresElectronicInvoice: boolean;

  /** @format int32 */
  memberOrganizationId?: number | null;
  appliedViaForm: boolean;

  /** @format date-time */
  inserted: string;

  /** @format int32 */
  insertedBy: number;

  /** @format date-time */
  updated: string;

  /** @format int32 */
  updatedBy: number;

  /** @format date-time */
  applicationDate?: string | null;
  course?: ApiGetCourseDto;
  userFullName?: string | null;
  isActive: boolean;

  /**
   * Vises kun hvis bruker-context er en ressurs.
   * Hvis kontraktstatusen ikke er signert så vil den kjøre en statussjekk mot Signicat før denne verdien fylles.
   */
  isContractSigned?: boolean | null;
  statusComment?: string | null;
  purchaser?: ApiCustomerOrgDto;
  invoiceRecipient?: ApiCustomerOrgDto;

  /** @format int32 */
  rank?: number | null;

  /**
   * Attendance percentage for the scheduled hours including today
   * @format double
   */
  currentAttendancePercentage?: number | null;
}

export interface ApiGetCourseParticipantStatusDto {
  /** @format int32 */
  courseId: number;
  courseName?: string | null;

  /** @format date-time */
  startDate: string;

  /** @format date-time */
  endDate: string;
  courseStatus?: string | null;
  participantStatus?: string | null;
  roleName?: string | null;
}

export interface ApiGetCourseRegulationFileDto {
  /** @format int64 */
  id: number;
  originalFileName?: string | null;
  description?: string | null;
  mimeType?: string | null;
  category?: string | null;
  isAdminRead?: boolean | null;

  /** @format date-time */
  inserted: string;

  /** @format int32 */
  insertedBy: number;
  insertedByUserFullName?: string | null;

  /** @format int32 */
  organizationId: number;
}

export interface ApiGetCourseRelatedFileDto {
  /** @format int64 */
  id: number;
  originalFileName?: string | null;
  description?: string | null;
  mimeType?: string | null;
  category?: string | null;
  isAdminRead?: boolean | null;

  /** @format date-time */
  inserted: string;

  /** @format int32 */
  insertedBy: number;
  insertedByUserFullName?: string | null;
  fileOwners?: ApiFileOwnerDto[] | null;
}

export interface ApiGetCourseResourceDto {
  /** @format int32 */
  userId: number;
  name?: string | null;
  mobile?: string | null;
  email?: string | null;
  roleName?: string | null;
  roleNames?: string[] | null;

  /** @format double */
  noTeachingHours: number;

  /** @format double */
  noRemainingTeachingHours: number;
  contractStatus?: Record<string, string>;

  /** C = customer, E = employee */
  userType?: string | null;
}

export interface ApiGetCoursesAndNewDocumentsDto {
  /** @format int32 */
  courseId: number;
  courseName?: string | null;
  courseSupervisorName?: string | null;

  /** @format int32 */
  courseSupervisorId: number;
  courseLecturerName?: string | null;

  /** @format int32 */
  courseLecturerId: number;

  /** @format date-time */
  courseStartDate?: string | null;
  courseLocation?: string | null;
  newDocumentParticipants?: ApiParticipantNewDocumentDto[] | null;
}

export interface ApiGetCourseScheduleDtoType {
  /** The title/name of the schedule */
  title?: string | null;

  /**
   * The start date/time of the schedule
   * @format date-time
   */
  start: string;

  /**
   * The end date/time of the schedule
   * @format date-time
   */
  end: string;

  /** Whether all non-resource course participants have their attendances registered for this schedule entry */
  isAllAttendancesRegistered: boolean;

  /**
   * Number of registered participants excluding resources
   * @format int32
   */
  expectedAttendantCount: number;

  /**
   * Hours of registered participant attendances
   * @format double
   */
  registeredAttendanceHours: number;

  /** Whether any working hours are referencing this schedule entry */
  hasAnyWorkingHourReferences?: boolean | null;

  /** Whether any registered attendances are referencing this schedule entry */
  hasAnyAttendanceReferences: boolean;

  /** @format date-time */
  inserted: string;

  /** @format int32 */
  insertedBy: number;

  /** @format date-time */
  updated: string;

  /** @format int32 */
  updatedBy: number;
}

export interface ApiGetCurrentEmployeeDto {
  /** @format int32 */
  id: number;

  /** @format int32 */
  organizationId: number;
  organizationName?: string | null;

  /** @format int32 */
  currentOrganizationId: number;
  currentOrganizationName?: string | null;
  firstName?: string | null;
  middleName?: string | null;
  lastName?: string | null;
}

export interface ApiGetCurriculumDefaultOptionDto {
  departments?: ApiMetadataDtoInfoType[] | null;
  memberOrganisations?: ApiMetadataDtoInfoType[] | null;
  courseAreas?: ApiHierarchicalAreaDtoType[] | null;
  courseLevels?: ApiMetadataDtoInfoType[] | null;
  courseEvaluations?: ApiMetadataDtoInfoType[] | null;
  courseStatuses?: ApiMetadataDtoInfoType[] | null;
  ssbCourses?: ApiMetadataDtoInfoType[] | null;
  teachingMethods?: string[] | null;
  cefrLevels?: string[] | null;
}

export interface ApiGetCurriculumDto {
  /** @format int32 */
  id: number;

  /** Point 1 */
  name?: string | null;
  ownerDepartmentId?: number[] | null;

  /** @format int32 */
  orgId: number;

  /** @format int32 */
  memberOrgId?: number | null;

  /** @format int32 */
  mainCourseId: number;

  /** @format int32 */
  subCourseId: number;
  ssbCode?: string | null;
  cefrLevel?: string | null;

  /** @format double */
  hoursMin: number;

  /** @format double */
  hoursMax: number;

  /** @format double */
  hoursOnline?: number | null;

  /** @format double */
  hoursStudyLoad?: number | null;

  /** @format int32 */
  levelId: number;

  /** @format int32 */
  points?: number | null;

  /** @format int32 */
  evaluationId: number;

  /** @format int32 */
  statusId: number;
  nusCode?: string | null;
  nusCodeName?: string | null;

  /** Point 2 */
  teacherRequired: boolean;
  teacherRequirements?: string | null;

  /** Point 3 */
  foreknowledgeRequired: boolean;
  foreknowledgeRequirements?: string | null;
  targetGroup?: string | null;

  /** Point 4 */
  externalCurriculumUrl?: string | null;
  learningObjectives?: string | null;
  course?: ApiGetCourseDraftDtoType;

  /** Point 6 */
  courseCertificateRequired: boolean;

  /** Point 7 */
  webContent?: string | null;
  teachingMethod?: string | null;
  vocationalSchool?: ApiVocationalSchoolDtoType;
  partners?: ApiCustomerOrgDto[] | null;

  /** @format date-time */
  inserted: string;

  /** @format int32 */
  insertedBy: number;

  /** @format date-time */
  updated: string;

  /** @format int32 */
  updatedBy: number;
}

export interface ApiGetCurriculumFileDto {
  /** @format int64 */
  id: number;
  originalFileName?: string | null;
  description?: string | null;
  mimeType?: string | null;
  category?: string | null;
  isAdminRead?: boolean | null;

  /** @format date-time */
  inserted: string;

  /** @format int32 */
  insertedBy: number;
  insertedByUserFullName?: string | null;
  fileOwners?: ApiFileOwnerDto[] | null;
}

export interface ApiGetCustomerDto {
  /** @format int32 */
  id: number;

  /** @format uuid */
  adUserId?: string | null;
  postAddress1?: string | null;
  postAddress2?: string | null;
  postAddress3?: string | null;
  postCity?: string | null;
  postZip?: string | null;
  postCountry?: string | null;
  invoiceAddress1?: string | null;
  invoiceAddress2?: string | null;
  invoiceAddress3?: string | null;
  invoiceCity?: string | null;
  invoiceZip?: string | null;
  invoiceCountry?: string | null;
  phoneNumber?: string | null;
  mobileNumber?: string | null;
  bankAccountNo?: string | null;
  email?: string | null;
  isActive: boolean;

  /** @format date-time */
  insertDate: string;

  /** @format int32 */
  insertedBy: number;
  insertedByUser?: string | null;

  /** @format date-time */
  updateDate: string;

  /** @format int32 */
  updatedBy: number;
  updatedByUser?: string | null;

  /**
   * The date the user last verified their contact info (Email, MobileNumber, PhoneNumber)
   * @format date-time
   */
  lastVerifiedContactInfoDate: string;
  customer?: any;
}

export interface ApiGetCustomerPersonDto {
  firstName?: string | null;
  lastName?: string | null;

  /** @format date-time */
  birthDate?: string | null;
  phoneNumber?: string | null;
  mobileNumber?: string | null;
  postAddress1?: string | null;
  postZip?: string | null;
  postCity?: string | null;
  postCountry?: string | null;
  gender?: string | null;
  bankAccountNo?: string | null;

  /** The social security number (personnummer) for the user */
  ssno?: string | null;
  lastApprovedTermsOfUsage?: string | null;
  receiveNewsletter: boolean;

  /** @format int32 */
  birthYear?: number | null;

  /** @format int32 */
  id: number;

  /** @format date-time */
  inserted: string;

  /** @format int32 */
  insertedBy: number;

  /** @format date-time */
  updated: string;

  /** @format int32 */
  updatedBy: number;

  /**
   * The date the user last verified their contact info (Email, MobileNumber, PhoneNumber)
   * @format date-time
   */
  lastVerifiedContactInfoDate: string;
  type?: string | null;

  /** @format uuid */
  adUserId?: string | null;
  isActive: boolean;
  email?: string | null;
}

export interface ApiGetDashboardCourseAttendanceDto {
  /** @format int32 */
  courseId: number;
  courseName?: string | null;

  /** BrukerId til deltakere for dette kurset med oppmøte under den angitte grensen (standard 80%). */
  courseParticipantsWithLowAttendance?: number[] | null;
}

export interface ApiGetDashboardDto {
  /**
   * Antallet søkere for kurs som brukeren er kursadministrator for.
   * Definisjonen av en søker er at deltakeren har status SYSCO.Universum.Common.Constants.CourseParticipantStatus.APPLIED, SYSCO.Universum.Common.Constants.CourseParticipantStatus.SUBSCRIBED eller SYSCO.Universum.Common.Constants.CourseParticipantStatus.WAITINGLIST
   * @format int32
   */
  courseParticipantAppliedCount: number;

  /**
   * Antall studieplaner som ikke er godkjent enda.
   * Dette er studieplaner med status SYSCO.Universum.Common.Constants.CurriculumStatusDescriptionId.FOR_APPROVAL
   * @format int32
   */
  curriculumForApprovalCount: number;

  /**
   * Antall reise/stipend - som ikke er godkjent enda.
   * Dette er reise/stipend med status SYSCO.Universum.Api.Dtos.CourseParticipantExpenseStatusDtoType.DRAFT
   * @format int32
   */
  courseParticipantExpenseForApprovalCount: number;

  /**
   * Antall arbeidstimeoppføringer (en oppføring kan ha flere timer) som ikke er godkjent enda.
   * Dette er arbeidstimeoppføringer med status SYSCO.Universum.Common.Constants.WorkingHourStatus.NOT_APPROVED_VALUE
   * @format int32
   */
  workingHourEntriesForApprovalCount: number;

  /**
   * Antallet kurs som brukeren er kursadministrator for med en eller flere deltakere med oppmøte under den angitte grensen (standard 80%).
   * @format int32
   */
  courseWithLowAttendanceCount: number;
  coursesWithParticipantsWithLowAttendance?: ApiGetDashboardCourseAttendanceDto[] | null;

  /**
   * Antallet påmeldinger som ikke er merket som lest
   * @format int32
   */
  unreadCourseApplicationsCount: number;

  /**
   * Antallet nye dokumenter lastet opp av deltakere som ikke er merket som "sett"
   * @format int32
   */
  unreadNewDocumentsCount: number;
}

export interface ApiGetDepartmentDto {
  /** @format int32 */
  id: number;

  /** @format int32 */
  parentId?: number | null;
  isActive: boolean;
  isEconomyActive: boolean;
  name?: string | null;
  email?: string | null;
  isWebVisible: boolean;

  /** @format date-time */
  inserted: string;

  /** @format int32 */
  insertedBy: number;

  /** @format date-time */
  updated: string;

  /** @format int32 */
  updatedBy: number;
}

export interface ApiGetEfactorStudentDto {
  courseId?: string | null;
  courseName?: string | null;
  courseStudentId?: string | null;
  courseStudentFirstName?: string | null;
  courseStudentLastName?: string | null;
  courseStudentPhoneNumber?: string | null;
  courseStudentEmail?: string | null;
  courseStudentStatus?: string | null;
  courseStatus?: string | null;
  courseStudentAdress1?: string | null;
  courseStudentAdress2?: string | null;
  courseStudentAdress3?: string | null;
  courseStudentZip?: string | null;
  courseStudentZipPlace?: string | null;
}

export interface ApiGetEmployeeContractLineDto {
  /** @format double */
  amountOfHours: number;
  description?: string | null;

  /** @format int32 */
  id: number;
}

export interface ApiGetEmployeeDto {
  /** @format int32 */
  id: number;

  /** @format int32 */
  organizationId: number;
  email?: string | null;
  phoneNumber?: string | null;
  isActive: boolean;

  /** @format date-time */
  inserted: string;

  /** @format int32 */
  insertedBy: number;
  insertedByUser?: string | null;

  /** @format date-time */
  updated: string;

  /** @format int32 */
  updatedBy: number;
  updatedByUser?: string | null;
  firstName?: string | null;
  middleName?: string | null;
  lastName?: string | null;
}

export interface ApiGetExecutionPlanDto {
  info?: ApiExecutionPlanInfoDtoType;
  plan: boolean;
  economyRequired: boolean;
  certificatesRequired: boolean;
  certificate?: ApiExecutionPlanCertificateDtoType;
  teachingMaterialsRequired: boolean;
  testsRequired: boolean;
  testDescriptionRequired: boolean;

  /** @format date-time */
  inserted: string;

  /** @format int32 */
  insertedBy: number;

  /** @format date-time */
  updated: string;

  /** @format int32 */
  updatedBy: number;
}

export interface ApiGetExpenseDto {
  /**
   * Id
   * @format int32
   */
  id: number;

  /**
   * KursId
   * @format int32
   */
  courseId: number;

  /**
   * Ordre-Id
   * @format int32
   */
  orderId?: number | null;
  file?: ApiBaseGetFileDto;

  /** Status - utkast, validert, osv. */
  status?: string | null;
  cost?: ApiGetCostDto;
  courseParticipant?: ApiGetPureCourseParticipantDto;

  /** Bankkontonummer */
  accountNumber?: string | null;

  /** Melding til kursdeltaker fra kursadministrator */
  messageToParticipant?: string | null;

  /**
   * Opprettet dato
   * @format date-time
   */
  inserted: string;

  /** Beskrivelse */
  description?: string | null;

  /**
   * Beløp
   * @format double
   */
  amount: number;

  /**
   * Antall
   * @format double
   */
  quantity: number;

  /**
   * Utregnet fra beløp * antall
   * @format double
   */
  sum: number;
}

export interface ApiGetFacilitationGrantApplicationDto {
  /** @format int32 */
  courseId: number;
  courseName?: string | null;
  courseSupervisorName?: string | null;
  applicantName?: string | null;
  applicationStatus?: string | null;
  applicationDescription?: string | null;
  descriptionOfAdditionalCost?: string | null;
  targetGroup?: string | null;
  location?: string | null;

  /** @format double */
  numberOfHours: number;

  /** @format int32 */
  numberOfParticipants: number;

  /** @format date-time */
  startDate: string;

  /** @format date-time */
  endDate: string;
  commentToGrant?: string | null;
  commentToActualCost?: string | null;

  /** @format int32 */
  departmentId: number;
  departmentName?: string | null;
  budgetLines?: ApiFacilitationCostsDto[] | null;
}

export interface ApiGetFileDefaultOptionDto {
  categories?: string[] | null;
}

export interface ApiGetGrantDto {
  /** @format int32 */
  id: number;
  name?: string | null;
  governmentFunded: boolean;

  /** @format date-time */
  inserted: string;

  /** @format int32 */
  insertedBy: number;

  /** @format date-time */
  updated: string;

  /** @format int32 */
  updatedBy: number;
}

export interface ApiGetGrantRateDto {
  /** @format int32 */
  year: number;

  /** @format int32 */
  version: number;

  /** @format double */
  teachingGrantAmount: number;

  /** @format double */
  extraProfessionalCourseAmount: number;

  /** @format date-time */
  inserted?: string | null;

  /** @format int32 */
  insertedBy: number;

  /** @format date-time */
  updated?: string | null;

  /** @format int32 */
  updatedBy: number;
  updatedByUserFullName?: string | null;
  insertedByUserFullName?: string | null;
}

export interface ApiGetGuestEmployeeDto {
  /** @format int32 */
  id: number;

  /** @format int32 */
  organizationId: number;
  email?: string | null;
  phoneNumber?: string | null;
  isActive: boolean;

  /** @format date-time */
  inserted: string;

  /** @format int32 */
  insertedBy: number;
  insertedByUser?: string | null;

  /** @format date-time */
  updated: string;

  /** @format int32 */
  updatedBy: number;
  updatedByUser?: string | null;
  firstName?: string | null;
  middleName?: string | null;
  lastName?: string | null;
}

export interface ApiGetGuestEmployeewithMemberOrganizationDto {
  /** @format int32 */
  id: number;
  firstName?: string | null;
  middleName?: string | null;
  lastName?: string | null;
  email?: string | null;
  phoneNumber?: string | null;
  isActive: boolean;

  /** @format date-time */
  inserted: string;

  /** @format int32 */
  insertedBy: number;
  insertedByUser?: string | null;

  /** @format date-time */
  updated: string;

  /** @format int32 */
  updatedBy: number;
  updatedByUser?: string | null;
  memberOrganization?: ApiGetMemberOrganizationDto;
}

export interface ApiGetGuestUserDashboardDto {
  /** @format int32 */
  participantApplicationCount: number;
}

export interface ApiGetHolidayDetailDto {
  /** @format int32 */
  id: number;
  periodName?: string | null;

  /** @format date-time */
  fromDate: string;

  /** @format date-time */
  toDate: string;
}

export interface ApiGetHolidayDto {
  /** @format int32 */
  id: number;
  name?: string | null;
  isActive: boolean;
  details?: ApiGetHolidayDetailDto[] | null;
}

export interface ApiGetKursAdminCourseDto {
  /** @format int32 */
  id: number;

  /** @format int32 */
  curriculumId: number;
  status?: string | null;
  courseName?: string | null;
  externalTitle?: string | null;
  reasonForStatusChange?: string | null;
  useLearningPlatform: boolean;
  courseLocation?: ApiCourseLocationDtoType;

  /** @format int32 */
  studyLocationId?: number | null;

  /** @format date-time */
  cancellationDeadline?: string | null;

  /** @format date-time */
  startDate: string;

  /** @format date-time */
  endDate: string;
  isTimeWebVisible: boolean;

  /** @format int32 */
  hoursWithInstructor: number;

  /** @format int32 */
  hoursWithoutInstructor?: number | null;

  /** @format int32 */
  hoursOnline?: number | null;

  /** @format int32 */
  hoursSelfStudy?: number | null;

  /**
   * Antall (timeplan)timer gjennomført (timeplan-timer som er i fortiden)
   * @format double
   */
  completedScheduledHours: number;

  /**
   * Antall (timeplan)timer gjenværende
   * @format double
   */
  remainingScheduledHours: number;

  /**
   * Totalt antall (timeplan)timer
   * @format double
   */
  totalScheduledHours: number;

  /** @format int32 */
  courseSupervisorId: number;

  /** @format int32 */
  organizerOrganizationId: number;

  /** @format int32 */
  ownerOrganizationId: number;

  /** @format int32 */
  lecturerId: number;

  /** @format int32 */
  contactId: number;

  /**
   * KasId er id fra gamle kursadmin system (KAS) som skal avvikles
   * @format int32
   */
  kasId?: number | null;

  /** @format date-time */
  enrollmentDeadline?: string | null;

  /** @format date-time */
  unenrollmentDeadline?: string | null;

  /** @format int32 */
  minimumParticipants: number;

  /** @format int32 */
  maxParticipants: number;

  /**
   * Vises kun hvis bruker-context er en ressurs
   * @format int32
   */
  participantSubscribedCount?: number | null;

  /** @format int32 */
  participantCapacity: number;

  /** Id'ene til organisasjoner som finansierer kurset */
  financedByOrganizationIds?: number[] | null;

  /** Bedriftsinternt kurs (het fra gammelt av "Bestillingskurs"/"Kurset er bestilt") */
  isOrderable: boolean;
  plan?: ApiGetPlanDtoType;
  additionalTeachingMaterialIds?: number[] | null;

  /** Id'ene til læremidlene for studieplanen for dette kurset */
  curriculumTeachingMaterialIds?: number[] | null;
  tests?: ApiGetTestDtoType[] | null;
  comments?: string | null;
  economy?: ApiGetCourseEconomyDtoType;
  webDescription?: ApiGetCourseDescriptionBaseDto;
  teachingMethod?: string | null;

  /** @format int32 */
  mainCourseId?: number | null;

  /** @format int32 */
  facilitationCourseId?: number | null;

  /** @format date-time */
  inserted: string;

  /** @format int32 */
  insertedBy: number;

  /** @format date-time */
  updated: string;

  /** @format int32 */
  updatedBy: number;

  /**
   * Antall arbeidstimer som ikke er godkjent enda
   * @format double
   */
  unapprovedWorkingHours: number;
  facilitationGrantApplication?: ApiGetFacilitationGrantApplicationDto;
}

export interface ApiGetLocationDto {
  /** @format int32 */
  id: number;

  /** @format int32 */
  areaId: number;
  street?: string | null;
  postalCode?: string | null;
  postalArea?: string | null;
  isActive: boolean;
  details?: string | null;
  rooms?: ApiGetRoomDto[] | null;

  /** @format date-time */
  inserted: string;

  /** @format int32 */
  insertedBy: number;

  /** @format date-time */
  updated: string;

  /** @format int32 */
  updatedBy: number;
}

export interface ApiGetMemberOrganizationDto {
  /** @format int32 */
  id: number;

  /** @format int32 */
  parentId?: number | null;
  name?: string | null;
  orgNo?: string | null;
  ssbId?: string | null;
  phone?: string | null;
  email?: string | null;
  url?: string | null;
  isWebVisible: boolean;
  isActive: boolean;

  /** @format int32 */
  clientAccountNumber?: number | null;

  /** @format date-time */
  inserted: string;

  /** @format int32 */
  insertedBy: number;

  /** @format date-time */
  updated: string;

  /** @format int32 */
  updatedBy: number;
  inheritOrgNoFromParent?: boolean | null;
}

export interface ApiGetMemberOrganizationUserDto {
  /** @format int32 */
  id: number;
  email?: string | null;
}

export interface ApiGetMessageResultDto {
  /** @format int64 */
  id: number;

  /** @format int64 */
  threadId: number;

  /** @format int64 */
  replyToId?: number | null;
  subject?: string | null;
  text?: string | null;

  /** @format int32 */
  courseId: number;

  /** @format int32 */
  senderMsgUserId: number;
  sender?: string | null;
  recipientMsgUserIds?: number[] | null;
  recipients?: string[] | null;

  /** @format date-time */
  sent: string;
  isRead: boolean;
  sentFromKursAdmin: boolean;
  attachments?: ApiAttachmentDto[] | null;
}

export interface ApiGetMessageResultDtoPagedResult {
  items?: ApiGetMessageResultDto[] | null;

  /** @format int32 */
  currentPage: number;

  /** @format int32 */
  totalCount: number;

  /** @format int32 */
  pageSize: number;

  /** @format int32 */
  totalPages: number;
  hasPrevious: boolean;
  hasNext: boolean;
}

export interface ApiGetMessagingDefaultOptionDto {
  variables?: string[] | null;
}

export interface ApiGetMinSideCourseDto {
  /** @format int32 */
  id: number;

  /** @format int32 */
  curriculumId: number;
  status?: string | null;
  courseName?: string | null;
  externalTitle?: string | null;
  reasonForStatusChange?: string | null;
  useLearningPlatform: boolean;
  courseLocation?: ApiCourseLocationDtoType;

  /** @format int32 */
  studyLocationId?: number | null;

  /** @format date-time */
  cancellationDeadline?: string | null;

  /** @format date-time */
  startDate: string;

  /** @format date-time */
  endDate: string;
  isTimeWebVisible: boolean;

  /** @format int32 */
  hoursWithInstructor: number;

  /** @format int32 */
  hoursWithoutInstructor?: number | null;

  /** @format int32 */
  hoursOnline?: number | null;

  /** @format int32 */
  hoursSelfStudy?: number | null;

  /**
   * Antall (timeplan)timer gjennomført (timeplan-timer som er i fortiden)
   * @format double
   */
  completedScheduledHours: number;

  /**
   * Antall (timeplan)timer gjenværende
   * @format double
   */
  remainingScheduledHours: number;

  /**
   * Totalt antall (timeplan)timer
   * @format double
   */
  totalScheduledHours: number;

  /** @format int32 */
  courseSupervisorId: number;

  /** @format int32 */
  organizerOrganizationId: number;

  /** @format int32 */
  ownerOrganizationId: number;

  /** @format int32 */
  lecturerId: number;

  /** @format int32 */
  contactId: number;

  /**
   * KasId er id fra gamle kursadmin system (KAS) som skal avvikles
   * @format int32
   */
  kasId?: number | null;

  /** @format date-time */
  enrollmentDeadline?: string | null;

  /** @format date-time */
  unenrollmentDeadline?: string | null;

  /** @format int32 */
  minimumParticipants: number;

  /** @format int32 */
  maxParticipants: number;

  /**
   * Vises kun hvis bruker-context er en ressurs
   * @format int32
   */
  participantSubscribedCount?: number | null;

  /** @format int32 */
  participantCapacity: number;

  /** Id'ene til organisasjoner som finansierer kurset */
  financedByOrganizationIds?: number[] | null;

  /** Bedriftsinternt kurs (het fra gammelt av "Bestillingskurs"/"Kurset er bestilt") */
  isOrderable: boolean;
  plan?: ApiGetPlanDtoType;
  additionalTeachingMaterialIds?: number[] | null;

  /** Id'ene til læremidlene for studieplanen for dette kurset */
  curriculumTeachingMaterialIds?: number[] | null;
  tests?: ApiGetTestDtoType[] | null;
  comments?: string | null;
  economy?: ApiGetCourseEconomyDtoType;
  webDescription?: ApiGetCourseDescriptionBaseDto;
  teachingMethod?: string | null;

  /** @format int32 */
  mainCourseId?: number | null;

  /** @format int32 */
  facilitationCourseId?: number | null;

  /** @format date-time */
  inserted: string;

  /** @format int32 */
  insertedBy: number;

  /** @format date-time */
  updated: string;

  /** @format int32 */
  updatedBy: number;
  mainCourseArea?: string | null;
}

export interface ApiGetNusCodeDto {
  name?: string | null;
  shortName?: string | null;
  educationalOfferCode?: string | null;
  isActive: boolean;
  code?: string | null;

  /** @format int32 */
  ownerOrganizationId: number;
}

export interface ApiGetOrderDto {
  /** Internfakturering */
  isInternal: boolean;

  /** Kundens bestillingsnummer – (manuelt felt, PH nummer, kan være tom) */
  customerOrderNo?: string | null;

  /** Reise/utleggsreferanse – (Refusjon kurs ____)(max 30 tegn) */
  expenseRef?: string | null;

  /** Deres referanse – (manuelt) */
  theirRef?: string | null;

  /**
   * Motbilag – (ved kreditering sende PK fra originale ordrenummer)
   * @format int32
   */
  contraEntryOrderId?: number | null;

  /**
   * Avdragstype – (type 0, 1 eller 2)
   * Type 0: Ingen avdrag
   * Type 1: Fast intervall
   *     Antall avdrag
   *     Intervall(eks.antall mnd mellom hvert avdrag)
   * Type 2: Liste med avdrag(må gå opp i 100%)
   *     Dato
   *     Prosent
   * @format int32
   * @min 0
   * @max 2
   */
  paymentType: number;

  /**
   * Antall avdrag – (bare fylt ut dersom type 1)
   * @format int32
   */
  noOfPayments?: number | null;

  /**
   * Avdragsintervall – (bare fylt ut dersom type 1)
   * @format int32
   */
  paymentInverval?: number | null;

  /** @format int32 */
  cardTransactionId?: number | null;
  cardOrderRef?: string | null;
  contraEntryOrdersId?: number[] | null;

  /** @format int32 */
  id: number;

  /** @format int32 */
  courseId: number;

  /** @format int32 */
  courseParticipantExpenseId?: number | null;

  /**
   * Kursdato start
   * @format date-time
   */
  courseStartDate?: string | null;

  /**
   * Kursdato slutt
   * @format date-time
   */
  courseEndDate?: string | null;
  status?: ApiIdNameDtoType;
  participant?: ApiOrderParticipantDto;
  invoiceRecipient?: ApiOrderInvoiceRecipientDto;
  orderLines?: ApiOrderLineDto[] | null;

  /** Avdragslinjer (en liste, bare ved type 2) */
  orderPaymentLines?: ApiOrderPaymentLineDto[] | null;

  /** @format date-time */
  queued?: string | null;

  /** @format int32 */
  retryNumber: number;

  /** @format int32 */
  erpClientId: number;

  /** @format int32 */
  erpOrderNo?: number | null;
  erpVgOrderNo?: string | null;

  /** @format date-time */
  erpSendt?: string | null;

  /** @format date-time */
  erpLastUpdated?: string | null;
  erpReceipt?: string | null;

  /** @format int32 */
  erpResponseCode?: number | null;

  /** @format date-time */
  inserted?: string | null;
  erpStatus?: string | null;

  /** @format int32 */
  erpInvoiceNo?: number | null;

  /** @format int32 */
  numberOfReminders?: number | null;

  /** @format date-time */
  lastReminderDate?: string | null;
}

export interface ApiGetPlanDtoType {
  /** @format int32 */
  schoolRouteId?: number | null;
  registerHours: boolean;
  schedules?: ApiGetCourseScheduleDtoType[] | null;
}

export interface ApiGetPureCourseParticipantDto {
  /** @format int32 */
  courseId: number;

  /** @format int32 */
  userId: number;
  roleName?: string | null;
  roleNames?: string[] | null;
  status?: string | null;
  requiresElectronicInvoice: boolean;

  /** @format int32 */
  memberOrganizationId?: number | null;
  appliedViaForm: boolean;

  /** @format date-time */
  inserted: string;

  /** @format int32 */
  insertedBy: number;

  /** @format date-time */
  updated: string;

  /** @format int32 */
  updatedBy: number;

  /** @format date-time */
  applicationDate?: string | null;
  userFullName?: string | null;
  isActive: boolean;
}

export interface ApiGetRoleDto {
  name?: string | null;
  isActive: boolean;
  isResource: boolean;
  isForRegularCourse: boolean;
  isForVocationalSchool: boolean;
  cannotBeCombined: boolean;
  requiresCertification: boolean;
  description?: string | null;

  /** @format date-time */
  inserted: string;

  /** @format int32 */
  insertedBy: number;

  /** @format date-time */
  updated: string;

  /** @format int32 */
  updatedBy: number;
}

export interface ApiGetRoomDto {
  /** @format int32 */
  id: number;
  name?: string | null;

  /** @format int32 */
  capacity: number;

  /** @format int32 */
  recommendedCapacity: number;

  /** @format int32 */
  floorNumber?: number | null;
  isActive: boolean;
  description?: string | null;

  /** @format date-time */
  inserted: string;

  /** @format int32 */
  insertedBy: number;

  /** @format date-time */
  updated: string;

  /** @format int32 */
  updatedBy: number;
}

export interface ApiGetShortCourseDto {
  /** @format int32 */
  id: number;

  /** @format int32 */
  curriculumId: number;
  status?: string | null;
  courseName?: string | null;
  courseLocation?: ApiCourseLocationDtoType;

  /** @format int32 */
  studyLocationId?: number | null;
  areaName?: string | null;

  /** @format date-time */
  startDate: string;

  /** @format date-time */
  endDate: string;

  /** @format date-time */
  cancellationDeadline?: string | null;

  /** @format date-time */
  enrollmentDeadline?: string | null;

  /** @format int32 */
  courseSupervisorId: number;
  courseSupervisorName?: string | null;

  /** @format int32 */
  organizerOrganizationId: number;
  organizerOrganizationName?: string | null;

  /** @format int32 */
  ownerOrganizationId: number;
  ownerOrganizationName?: string | null;
  teachingMethod?: string | null;

  /** @format int32 */
  lecturerId: number;
  lecturerName?: string | null;

  /** @format int32 */
  kasId?: number | null;

  /** @format double */
  price: number;

  /** @format int32 */
  erpProductNo?: number | null;

  /** @format date-time */
  inserted: string;

  /** @format date-time */
  updated: string;
  participants?: ApiGetCourseParticipantDtoType[] | null;
}

export interface ApiGetShortCourseWithParticipantsDto {
  /** @format int32 */
  id: number;

  /** @format int32 */
  curriculumId: number;
  status?: string | null;
  courseName?: string | null;
  courseLocation?: ApiCourseLocationDtoType;

  /** @format int32 */
  studyLocationId?: number | null;

  /** @format date-time */
  startDate: string;

  /** @format date-time */
  endDate: string;

  /** @format date-time */
  cancellationDeadline?: string | null;

  /** @format int32 */
  courseSupervisorId: number;
  courseSupervisorName?: string | null;

  /** @format int32 */
  organizerOrganizationId: number;
  organizerOrganizationName?: string | null;

  /** @format int32 */
  ownerOrganizationId: number;
  ownerOrganizationName?: string | null;

  /** @format double */
  price: number;

  /** @format date-time */
  inserted: string;

  /** @format date-time */
  updated: string;
  participants?: ApiGetPureCourseParticipantDto[] | null;
}

export interface ApiGetShortCurriculumDto {
  /** @format int32 */
  id: number;
  name?: string | null;

  /** @format int32 */
  orgId: number;
  orgName?: string | null;

  /** @format int32 */
  mainCourseId?: number | null;
  mainCourseName?: string | null;

  /** @format int32 */
  subCourseId?: number | null;
  ssbCode?: string | null;
  subCourseName?: string | null;

  /** @format int32 */
  curriculumStatusId: number;
  curriculumStatusName?: string | null;
  teachingMethod?: string | null;

  /** @format double */
  hoursMin: number;

  /** @format double */
  hoursMax: number;

  /** @format int32 */
  insertedById: number;
  insertedByUsername?: string | null;

  /** @format date-time */
  inserted: string;

  /** @format date-time */
  updated: string;
}

export interface ApiGetSmsResultDto {
  messageText?: string | null;

  /** @format date-time */
  inserted: string;
  recipients?: ApiRecipientDto[] | null;
}

export interface ApiGetStudyLocationDto {
  /** @format int32 */
  id: number;
  code?: string | null;
  name?: string | null;
  isActive: boolean;
}

export interface ApiGetTeachingGrantAmountDto {
  /** @format int32 */
  courseId: number;

  /** @format double */
  amount: number;
}

export interface ApiGetTeachingGrantAmountDtoIdDto {
  id?: ApiGetTeachingGrantAmountDto;
}

export interface ApiGetTeachingMaterialCategoryDto {
  name?: string | null;
  description?: string | null;
  attribute1?: string | null;
  attribute2?: string | null;
  attribute3?: string | null;
  attribute4?: string | null;
  attribute5?: string | null;

  /** @format date-time */
  inserted: string;

  /** @format int32 */
  insertedBy: number;

  /** @format date-time */
  updated: string;

  /** @format int32 */
  updatedBy: number;
}

export interface ApiGetTeachingMaterialDto {
  /** @format int32 */
  id: number;
  name?: string | null;
  description?: string | null;
  isActive: boolean;
  attribute1?: string | null;
  attribute2?: string | null;
  attribute3?: string | null;
  attribute4?: string | null;
  attribute5?: string | null;
  category?: string | null;

  /** @format date-time */
  inserted: string;

  /** @format int32 */
  insertedBy: number;

  /** @format date-time */
  updated: string;

  /** @format int32 */
  updatedBy: number;
}

export interface ApiGetTestDtoType {
  /** @format int32 */
  id: number;

  /** @format int32 */
  testTypeId: number;
  name?: string | null;
  description?: string | null;

  /** @format date-time */
  inserted: string;

  /** @format int32 */
  insertedBy: number;

  /** @format date-time */
  updated: string;

  /** @format int32 */
  updatedBy: number;
}

export interface ApiGetTestTypeDto {
  name?: string | null;
  description?: string | null;

  /** @format int32 */
  id: number;

  /** @format date-time */
  inserted: string;

  /** @format int32 */
  insertedBy: number;

  /** @format date-time */
  updated: string;

  /** @format int32 */
  updatedBy: number;
}

export interface ApiGetUnreadCourseApplicationsDto {
  /** @format int32 */
  courseId: number;
  courseName?: string | null;
  areaName?: string | null;

  /** @format int32 */
  courseSupervisorId: number;
  courseSupervisorName?: string | null;

  /** @format int32 */
  lecturerId: number;
  lecturerName?: string | null;

  /** @format date-time */
  startDate: string;

  /** @format date-time */
  endDate: string;

  /** @format date-time */
  enrollmentDeadline: string;

  /** @format int32 */
  applicantUserId: number;
  applicantName?: string | null;

  /** @format date-time */
  applicationSentDate: string;
  applicationStatus?: string | null;
  appliedViaForm: boolean;
  isRead: boolean;
}

export interface ApiGetUserDto {
  /** @format int32 */
  id: number;
  type?: string | null;
  firstName?: string | null;
  middleName?: string | null;
  lastName?: string | null;
  isActive: boolean;

  /** @format date-time */
  inserted: string;

  /** @format int32 */
  insertedBy: number;

  /** @format date-time */
  updated: string;

  /** @format int32 */
  updatedBy: number;
}

export interface ApiGetWorkingHourDto {
  /**
   * The id'en til kurskontraktlinjen som det skal skrives timer for
   * @format int32
   */
  courseContractLineId: number;

  /**
   * Antall timer som skal registreres. Kan bli satt ned til nærmeste halvtime (e.g. 2.5)
   * @format double
   */
  amount: number;

  /**
   * <br>
   *             Hvis kostnadstypen for kontraktlinjen er 'Undervisningstimer' så må denne datoen/tidspunktet tilsvare en dato/tidspunkt i timeplanen.
   *
   *             Hvis kostnadstypen for kontraktlinjen ikke er 'Undervisningstimer' så må denne datoen tilsvare en dag hvor
   *             UTC tidspunktet er 00:00:00 på den angitte dagen og være for i dag eller en dag i fortiden.
   *
   * @format date-time
   */
  date: string;

  /** Kommentaren til de registrerte timene. Kan være opptil 500 karakterer lang. */
  comment?: string | null;

  /**
   * Id'en til arbeidstimen
   * @format int64
   */
  id: number;

  /** Statusen til arbeidstimen */
  status?: string | null;

  /** Brukervennlig status til arbeidstimen */
  statusText?: string | null;

  /**
   * Id'en til kurset som kontraktlinjen for arbeidstimen er skrevet for
   * @format int32
   */
  courseId: number;

  /** Navnet til kurset som kontraktlinjen for arbeidstimen er skrevet for */
  courseName?: string | null;

  /**
   * Id'en til Visma Global for kurset sin eier-avdeling
   * @format int32
   */
  courseOwnerOrganizationErpClientId?: number | null;

  /**
   * Id'en til kurset sin regnskapsavdeling
   * @format int32
   */
  courseEconomyAccountingDepartment?: number | null;

  /**
   * Produktnummeret til kursområdet til kurset sin studieplan
   * @format int32
   */
  courseAreaErpProductNo?: number | null;

  /**
   * Id'en til kontrakten som timen gjelder for
   * @format int32
   */
  courseContractId: number;

  /**
   * Id'en til brukeren som timen (og kontrakten) gjelder for
   * @format int32
   */
  userId: number;

  /** Brukerens fulle navn */
  userFullName?: string | null;

  /**
   * Ansattnummeret til brukeren for denne avdelingen i Visma Lønn
   * @format int32
   */
  erpPayrollEmployeeId?: number | null;

  /** Beskrivelsen på kontraktlinjen */
  courseContractLineDescription?: string | null;

  /**
   * Prisen til kontraktlinjen
   * @format double
   */
  courseContractLinePrice: number;

  /** Navnet på kostnadstypen (type timer) for kostnaden til kontraktlinjen */
  costTypeName?: string | null;

  /**
   * Lønnsarten til kostnaden til kontraktlinjen
   * @format int32
   */
  costPayType?: number | null;

  /**
   * Id'en til brukeren som godkjente arbeidstimene
   * @format int32
   */
  approvedByUserId?: number | null;

  /** Brukeren som godkjente arbeidstimene sitt fulle navn */
  approvedByUserFullName?: string | null;

  /**
   * Datoen arbeidstimene ble godkjent
   * @format date-time
   */
  approvedByDate?: string | null;

  /**
   * Id'en til brukeren som avviste arbeidstimene
   * @format int32
   */
  declinedByUserId?: number | null;

  /** Brukeren som avviste arbeidstimene sitt fulle navn */
  declinedByUserFullName?: string | null;

  /**
   * Datoen arbeidstimene ble avvist
   * @format date-time
   */
  declinedByDate?: string | null;

  /**
   * Id'en til filen knyttet til denne arbeidstimen (f.eks. en visma eksport eller fakturagrunnlag)
   * @format int64
   */
  fileId?: number | null;

  /** Filnavnet til filen knyttet til denne arbeidstimen */
  fileName?: string | null;

  /** Statusen til filen knyttet til denne arbeidstimen */
  fileStatus?: string | null;

  /** Brukervennlige statusen til filen knyttet til denne arbeidstimen */
  fileStatusText?: string | null;

  /**
   * Id'en til brukeren som genererte filen for arbeidstimene
   * @format int32
   */
  fileGeneratedByUserId?: number | null;

  /** Brukeren som genererte filen for arbeidstimene sitt fulle navn */
  fileGeneratedByUserFullName?: string | null;

  /**
   * Datoen filen ble generert
   * @format date-time
   */
  fileGeneratedByDate?: string | null;

  /** @format date-time */
  inserted: string;

  /** @format int32 */
  insertedBy: number;

  /** @format date-time */
  updated: string;

  /** @format int32 */
  updatedBy: number;
}

export interface ApiGrant {
  /** @format int32 */
  id: number;
  name?: string | null;
  governmentFunded: boolean;

  /** @format date-time */
  inserted: string;

  /** @format int32 */
  insertedBy: number;

  /** @format date-time */
  updated: string;

  /** @format int32 */
  updatedBy: number;
  courseEconomy?: ApiCourseEconomy[] | null;
}

export interface ApiGrantRate {
  /** @format int32 */
  id: number;

  /** @format int32 */
  year: number;

  /** @format double */
  teachingGrantAmount: number;

  /** @format double */
  extraProfessionalCourseAmount: number;

  /** @format date-time */
  inserted: string;

  /** @format int32 */
  insertedBy: number;

  /** @format date-time */
  updated: string;

  /** @format int32 */
  updatedBy: number;
  courseEconomy?: ApiCourseEconomy[] | null;
}

export interface ApiGuestEmployee {
  /** @format int32 */
  userId: number;

  /** @format int32 */
  organizationId: number;
  email?: string | null;

  /** @format uuid */
  adUserId?: string | null;
  memberOrganization?: ApiMemberOrganization;
  user?: ApiUser;
}

export interface ApiGuestEmployeeCourseParticipantStatusDto {
  participantStatus?: string | null;

  /** @format int32 */
  memberOrganizationId: number;
  statusComment?: string | null;
}

export interface ApiGuidIdDto {
  /** @format uuid */
  id: string;
}

export interface ApiHierarchicalAreaDtoType {
  /** @format int32 */
  id: number;
  description?: string | null;

  /** @format int32 */
  parentId?: number | null;
  children?: ApiHierarchicalAreaDtoType[] | null;
  ssbCode?: string | null;
  code?: string | null;
  isActive: boolean;
}

export interface ApiHoliday {
  /** @format int32 */
  id: number;
  name?: string | null;
  isActive: boolean;

  /** @format date-time */
  inserted: string;

  /** @format int32 */
  insertedBy: number;

  /** @format date-time */
  updated: string;

  /** @format int32 */
  updatedBy: number;
  course?: ApiCourse[] | null;
  holidayDetail?: ApiHolidayDetail[] | null;
}

export interface ApiHolidayDetail {
  /** @format int32 */
  id: number;

  /** @format int32 */
  holidayId: number;
  periodName?: string | null;

  /** @format date-time */
  fromDate: string;

  /** @format date-time */
  toDate: string;

  /** @format date-time */
  inserted: string;

  /** @format int32 */
  insertedBy: number;

  /** @format date-time */
  updated: string;

  /** @format int32 */
  updatedBy: number;
  holiday?: ApiHoliday;
}

export interface ApiIdNameDtoType {
  /** @format int32 */
  id: number;
  name?: string | null;
}

export interface ApiIdRankDto {
  /** @format int32 */
  userId: number;

  /** @format int32 */
  rank: number;
}

export interface ApiInsertNusCodeDto {
  name?: string | null;
  shortName?: string | null;
  educationalOfferCode?: string | null;
  isActive: boolean;
  code?: string | null;
}

export interface ApiInt32IdDto {
  /** @format int32 */
  id: number;
}

export interface ApiInt32ListIdDto {
  id?: number[] | null;
}

export interface ApiInt32ValidationEntry {
  /**
   * The item that was validated
   * @format int32
   */
  validatedItem: number;

  /** The validation errors for the SYSCO.Universum.Api.Dtos.Validation.ValidationEntry`1.ValidatedItem */
  validationErrors?: string[] | null;
}

export interface ApiInt32ValidationResponseDto {
  /**
   * The general validation errors for the entire list of validated entries.
   * E.g. if the sum of entries makes it invalid, but the items themselves are valid,
   * the errors are more general.
   */
  generalValidationErrors?: string[] | null;
  validationList?: ApiInt32ValidationEntry[] | null;
}

export interface ApiInt64IdDto {
  /** @format int64 */
  id: number;
}

export interface ApiLocation {
  /** @format int32 */
  id: number;

  /** @format int32 */
  areaId: number;
  street?: string | null;
  postalCode?: string | null;
  postalArea?: string | null;
  details?: string | null;
  isActive: boolean;

  /** @format date-time */
  inserted: string;

  /** @format int32 */
  insertedBy: number;

  /** @format date-time */
  updated: string;

  /** @format int32 */
  updatedBy: number;
  area?: ApiArea;
  course?: ApiCourse[] | null;
  room?: ApiRoom[] | null;
}

export interface ApiMarkCourseApplicationsAsReadDto {
  /** @format int32 */
  courseId: number;

  /** @format int32 */
  userId: number;
}

export interface ApiMemberOrganization {
  /** @format int32 */
  organizationId: number;

  /** @format int32 */
  parentId?: number | null;
  name?: string | null;
  orgNo?: string | null;
  ssbId?: string | null;
  phone?: string | null;
  email?: string | null;
  url?: string | null;
  isWebVisible: boolean;

  /** @format int32 */
  id: number;

  /** @format int32 */
  clientAccountNumber?: number | null;
  organization?: ApiOrganization;
  courseParticipant?: ApiCourseParticipant[] | null;
  memberOrganizationUsers?: ApiMemberOrganizationUser[] | null;
  guestEmployee?: ApiGuestEmployee[] | null;
  appFormResponse?: ApiAppFormResponse[] | null;
}

export interface ApiMemberOrganizationUser {
  /** @format int32 */
  id: number;
  email?: string | null;

  /** @format int32 */
  organizationId: number;

  /** @format int32 */
  insertedBy: number;

  /** @format date-time */
  inserted: string;
  memberOrganization?: ApiMemberOrganization;
}

export interface ApiMetadataDtoInfoType {
  /** @format int32 */
  id: number;
  description?: string | null;
}

export interface ApiNewDocumentsDto {
  documentName?: string | null;

  /** @format int64 */
  fileId: number;
  documentCategory?: string | null;

  /** @format date-time */
  inserted: string;
  isCourseAdminRead: boolean;
}

export interface ApiNusCode {
  code?: string | null;
  name?: string | null;
  shortName?: string | null;
  educationalOfferCode?: string | null;
  isActive: boolean;

  /** @format int32 */
  ownerOrganizationId: number;
  department?: ApiDepartment;
  curriculum?: ApiCurriculum[] | null;
}

export interface ApiOrder {
  /** @format int32 */
  id: number;

  /** @format int32 */
  courseId?: number | null;

  /** @format int32 */
  courseParticipantExpenseId?: number | null;

  /** @format int32 */
  orderStatusId: number;

  /** @format date-time */
  courseStartDate?: string | null;

  /** @format date-time */
  courseEndDate?: string | null;
  isInternal: boolean;
  customerOrderNo?: string | null;
  expenseRef?: string | null;
  theirRef?: string | null;

  /** @format int32 */
  contraEntryOrderId?: number | null;

  /**
   * @format int32
   * @min 0
   * @max 2
   */
  paymentType: number;

  /** @format int32 */
  noOfPayments?: number | null;

  /** @format int32 */
  paymentInverval?: number | null;

  /** @format int32 */
  cardTransactionId?: number | null;
  cardOrderRef?: string | null;

  /** @format date-time */
  inserted: string;

  /** @format int32 */
  insertedBy: number;

  /** @format date-time */
  updated: string;

  /** @format int32 */
  updatedBy: number;

  /** @format int32 */
  retryNumber: number;

  /** @format int32 */
  erpClientId: number;

  /** @format int32 */
  erpOrderNo?: number | null;
  erpVgOrderNo?: string | null;

  /** @format date-time */
  erpLastUpdated?: string | null;
  erpReceipt?: string | null;

  /** @format int32 */
  erpResponseCode?: number | null;
  erpStatus?: string | null;

  /** @format int32 */
  erpInvoiceNo?: number | null;

  /** @format int32 */
  numberOfReminders?: number | null;

  /** @format date-time */
  lastReminderDate?: string | null;
  course?: ApiCourse;
  contraEntryOrder?: ApiOrder;
  participant?: ApiOrderParticipant;
  invoiceRecipient?: ApiOrderInvoiceRecipient;
  orderQueued?: ApiOrderQueued;
  orderSendt?: ApiOrderSendt;
  orderLines?: ApiOrderLine[] | null;
  orderPaymentLines?: ApiOrderPaymentLine[] | null;
  contraEntryOrders?: ApiOrder[] | null;
  courseParticipantExpense?: ApiCourseParticipantExpense;
}

export interface ApiOrderInvoiceRecipient {
  /** @format int32 */
  orderId: number;

  /** @format int32 */
  userId?: number | null;

  /** @format int32 */
  customerNo: number;
  name: string;
  address?: string | null;
  postNo: string;
  postArea: string;
  email: string;
  telephone?: string | null;
  orgNo?: string | null;
  bankAccountNo?: string | null;

  /** @format date-time */
  inserted: string;

  /** @format int32 */
  insertedBy: number;

  /** @format date-time */
  updated: string;

  /** @format int32 */
  updatedBy: number;
  user?: ApiUser;
  order?: ApiOrder;
}

export interface ApiOrderInvoiceRecipientDto {
  /**
   * ID
   * @format int32
   */
  customerNo: number;

  /** Navn */
  name: string;

  /** Gateadresse */
  address?: string | null;

  /** Postnummer */
  postNo: string;

  /** Poststed */
  postArea: string;

  /** E-post-adresse */
  email: string;

  /** Telefonnummer */
  telephone?: string | null;

  /** @format int32 */
  userId?: number | null;

  /** Orgnummer */
  orgNo?: string | null;

  /** Kontonummer */
  bankAccountNo?: string | null;
}

export interface ApiOrderInvoiceRecipientTemplateDto {
  /**
   * ID
   * @format int32
   */
  customerNo: number;

  /** Navn */
  name: string;

  /** Gateadresse */
  address?: string | null;

  /** Postnummer */
  postNo: string;

  /** Poststed */
  postArea: string;

  /** E-post-adresse */
  email: string;

  /** Telefonnummer */
  telephone?: string | null;

  /** @format int32 */
  userId: number;

  /** Orgnummer */
  orgNo?: string | null;
  accountNumber?: string | null;
}

export interface ApiOrderLine {
  /** @format int32 */
  id: number;

  /** @format int32 */
  orderId: number;
  description?: string | null;
  articleNo: string;

  /** @format int32 */
  courseId?: number | null;

  /** @format int32 */
  userId?: number | null;
  userName?: string | null;

  /** @format int32 */
  projectNo: number;

  /** @format int32 */
  productNo: number;

  /** @format int32 */
  courseAreaId?: number | null;

  /** @format int32 */
  accountingDepartment: number;

  /** @format int32 */
  teachingMethod: number;

  /** @format int32 */
  employeeNo: number;

  /** @format int32 */
  employeeId?: number | null;

  /** @format double */
  amount: number;

  /** @format double */
  discount: number;

  /** @format double */
  quantity: number;

  /** @format date-time */
  inserted: string;

  /** @format int32 */
  insertedBy: number;

  /** @format date-time */
  updated: string;

  /** @format int32 */
  updatedBy: number;
  order?: ApiOrder;
  courseArea?: ApiCourseArea;
  employee?: ApiEmployee;
  user?: ApiUser;
}

export interface ApiOrderLineDto {
  /** Beskrivelse. Hvis oppgitt, overstyrer artikkelnavn */
  description?: string | null;

  /** Kan foreslås hentes fra CourseArticle */
  articleNo?: string | null;

  /**
   * @format double
   * @min 0
   * @max 10000000000000000
   */
  amount: number;

  /**
   * Rabatt
   * @format double
   * @min 0
   * @max 100
   */
  discount: number;

  /**
   * Antall – (positivt ved debitering, negativt ved kreditering)
   * @format double
   */
  quantity: number;
  user?: ApiOrderLineUserDto;

  /** @format int32 */
  courseAreaId?: number | null;

  /** @format int32 */
  employeeId?: number | null;

  /**
   * Foreslås hentes fra kurs
   * @format int32
   */
  projectNo: number;

  /**
   * Foreslås hentes fra CourseArea
   * @format int32
   */
  productNo: number;

  /** @format int32 */
  accountingDepartment: number;

  /** @format int32 */
  teachingMethod: number;

  /** @format int32 */
  employeeNo: number;

  /** @format double */
  previouslyInvoicedAmount?: number | null;

  /** @format double */
  previouslyCreditedAmount?: number | null;
}

export interface ApiOrderLineUserDto {
  /** @format int32 */
  id?: number | null;
  name?: string | null;
}

export interface ApiOrderParticipant {
  /** @format int32 */
  orderId: number;

  /** @format int32 */
  courseId?: number | null;

  /** @format int32 */
  userId?: number | null;

  /** @format int32 */
  customerNo: number;
  name: string;
  address?: string | null;
  postNo: string;
  postArea: string;
  email: string;
  telephone?: string | null;
  orgNo?: string | null;

  /** @format date-time */
  inserted: string;

  /** @format int32 */
  insertedBy: number;

  /** @format date-time */
  updated: string;

  /** @format int32 */
  updatedBy: number;
  order?: ApiOrder;
  user?: ApiUser;
}

export interface ApiOrderParticipantDto {
  /**
   * ID
   * @format int32
   */
  customerNo: number;

  /** Navn */
  name: string;

  /** Gateadresse */
  address?: string | null;

  /** Postnummer */
  postNo: string;

  /** Poststed */
  postArea: string;

  /** E-post-adresse */
  email: string;

  /** Telefonnummer */
  telephone?: string | null;

  /** @format int32 */
  userId?: number | null;

  /** Orgnummer */
  orgNo?: string | null;
}

export interface ApiOrderParticipantTemplateDto {
  /**
   * ID
   * @format int32
   */
  customerNo: number;

  /** Navn */
  name: string;

  /** Gateadresse */
  address?: string | null;

  /** Postnummer */
  postNo: string;

  /** Poststed */
  postArea: string;

  /** E-post-adresse */
  email: string;

  /** Telefonnummer */
  telephone?: string | null;

  /** @format int32 */
  userId: number;
  orgNo?: string | null;
}

export interface ApiOrderPaymentLine {
  /** @format int32 */
  id: number;

  /** @format int32 */
  orderId: number;

  /** @format date-time */
  invoiceDate: string;

  /**
   * @format double
   * @min 0
   * @max 100
   */
  percentage: number;

  /** @format date-time */
  inserted: string;

  /** @format int32 */
  insertedBy: number;

  /** @format date-time */
  updated: string;

  /** @format int32 */
  updatedBy: number;
  order?: ApiOrder;
}

export interface ApiOrderPaymentLineDto {
  /**
   * Fakturadato
   * @format date-time
   */
  invoiceDate: string;

  /**
   * Prosent
   * @format double
   * @min 0
   * @max 100
   */
  percentage: number;
}

export interface ApiOrderQueued {
  /** @format int32 */
  orderId: number;

  /** @format date-time */
  inserted: string;

  /** @format int32 */
  insertedBy: number;
  order?: ApiOrder;
}

export interface ApiOrderSendt {
  /** @format int32 */
  orderId: number;

  /** @format date-time */
  inserted: string;

  /** @format int32 */
  insertedBy: number;
  order?: ApiOrder;
}

export interface ApiOrderTemplateDto {
  /** Internfakturering */
  isInternal: boolean;

  /** Kundens bestillingsnummer – (manuelt felt, PH nummer, kan være tom) */
  customerOrderNo?: string | null;

  /** Reise/utleggsreferanse – (Refusjon kurs ____)(max 30 tegn) */
  expenseRef?: string | null;

  /** Deres referanse – (manuelt) */
  theirRef?: string | null;

  /**
   * Motbilag – (ved kreditering sende PK fra originale ordrenummer)
   * @format int32
   */
  contraEntryOrderId?: number | null;

  /**
   * Avdragstype – (type 0, 1 eller 2)
   * Type 0: Ingen avdrag
   * Type 1: Fast intervall
   *     Antall avdrag
   *     Intervall(eks.antall mnd mellom hvert avdrag)
   * Type 2: Liste med avdrag(må gå opp i 100%)
   *     Dato
   *     Prosent
   * @format int32
   * @min 0
   * @max 2
   */
  paymentType: number;

  /**
   * Antall avdrag – (bare fylt ut dersom type 1)
   * @format int32
   */
  noOfPayments?: number | null;

  /**
   * Avdragsintervall – (bare fylt ut dersom type 1)
   * @format int32
   */
  paymentInverval?: number | null;

  /** @format int32 */
  cardTransactionId?: number | null;
  cardOrderRef?: string | null;
  contraEntryOrdersId?: number[] | null;

  /** @format int32 */
  courseId: number;
  participant?: ApiOrderParticipantTemplateDto;
  invoiceRecipient?: ApiOrderInvoiceRecipientTemplateDto;

  /** Avdragslinjer (en liste, bare ved type 2) */
  orderPaymentLines?: ApiOrderPaymentLineDto[] | null;

  /**
   * Kursdato start
   * @format date-time
   */
  courseStartDate?: string | null;

  /**
   * Kursdato slutt
   * @format date-time
   */
  courseEndDate?: string | null;
  orderLines?: ApiOrderLineDto[] | null;
}

export interface ApiOrganization {
  /** @format int32 */
  id: number;
  isActive: boolean;
  isEconomyActive: boolean;

  /** @format date-time */
  inserted: string;

  /** @format int32 */
  insertedBy: number;

  /** @format date-time */
  updated: string;

  /** @format int32 */
  updatedBy: number;
  department?: ApiDepartment;
  memberOrganization?: ApiMemberOrganization;
  accountingDepartment?: ApiAccountingDepartment;
  cost?: ApiCost[] | null;
  communicationTemplateGroup?: ApiCommunicationTemplateGroup[] | null;
}

export interface ApiOrganizationDtoType {
  /** @format int32 */
  id: number;
  name?: string | null;
  type?: string | null;
  isActive: boolean;
}

export interface ApiPaginationInput {
  /** @format int32 */
  pageNumber: number;

  /** @format int32 */
  pageSize: number;
}

export interface ApiParticipantNewDocumentDto {
  /** @format int32 */
  userId: number;
  courseParticipantName?: string | null;
  courseParticipantStatus?: string | null;
  newDocuments?: ApiNewDocumentsDto[] | null;
}

export interface ApiPostNewDocumentsReadStatusDto {
  /** @format int32 */
  courseId: number;

  /** @format int32 */
  userId: number;

  /** @format int64 */
  fileId: number;
}

export interface ApiProductContributionDto {
  /** @format double */
  commonActions?: number | null;

  /** @format double */
  organizationCourses?: number | null;
}

export interface ApiPurchaserOrganizationDto {
  organizationNumber?: string | null;
  name?: string | null;
  homePage?: string | null;
  address?: string | null;
  city?: string | null;
  zip?: string | null;
  country?: string | null;
  mobileNumber?: string | null;
  email?: string | null;
  isBrregSubCompany?: boolean | null;
}

export interface ApiPutAppFormTemplateDto {
  name?: string | null;
  isActive: boolean;
}

export interface ApiRecipientDto {
  mobileNumber?: string | null;
  name?: string | null;
}

export interface ApiRegulationFile {
  /** @format int64 */
  fileId: number;

  /** @format int32 */
  organizationId: number;

  /** @format date-time */
  inserted: string;

  /** @format int32 */
  insertedBy: number;
  file?: ApiFile;
  department?: ApiDepartment;
  appFormTemplate?: ApiAppFormTemplate[] | null;
  appFormResponse?: ApiAppFormResponse[] | null;
}

export interface ApiRejectFacilitationGrantApplicationDto {
  commentToGrant?: string | null;
  commentToActualCost?: string | null;
}

export interface ApiReopenCourseStatusDto {
  status?: string | null;
}

export interface ApiRole {
  name?: string | null;
  description?: string | null;
  isActive: boolean;
  isResource: boolean;
  isForRegularCourse: boolean;
  isForVocationalSchool: boolean;
  cannotBeCombined: boolean;
  requiresCertification: boolean;

  /** @format date-time */
  inserted: string;

  /** @format int32 */
  insertedBy: number;

  /** @format date-time */
  updated: string;

  /** @format int32 */
  updatedBy: number;
  courseParticipantRole?: ApiCourseParticipantRole[] | null;
  courseContract?: ApiCourseContract[] | null;
}

export interface ApiRoom {
  /** @format int32 */
  id: number;

  /** @format int32 */
  locationId: number;
  name?: string | null;

  /** @format int32 */
  capacity: number;

  /** @format int32 */
  recommendedCapacity: number;

  /** @format int32 */
  floorNumber?: number | null;
  description?: string | null;
  isActive: boolean;

  /** @format date-time */
  inserted: string;

  /** @format int32 */
  insertedBy: number;

  /** @format date-time */
  updated: string;

  /** @format int32 */
  updatedBy: number;
  location?: ApiLocation;
  course?: ApiCourse[] | null;
}

export interface ApiSchedule {
  /** @format int32 */
  courseId: number;

  /** @format date-time */
  start: string;

  /** @format date-time */
  end: string;
  title?: string | null;

  /** @format date-time */
  inserted: string;

  /** @format int32 */
  insertedBy: number;

  /** @format date-time */
  updated: string;

  /** @format int32 */
  updatedBy: number;
  course?: ApiCourse;
  courseParticipantAttendance?: ApiCourseParticipantAttendance[] | null;
}

export interface ApiScheduleBaseDto {
  title?: string | null;

  /** @format date-time */
  start?: string | null;

  /** @format date-time */
  end?: string | null;
}

export interface ApiScheduleBaseDtoValidationEntry {
  validatedItem?: ApiScheduleBaseDto;

  /** The validation errors for the SYSCO.Universum.Api.Dtos.Validation.ValidationEntry`1.ValidatedItem */
  validationErrors?: string[] | null;
}

export interface ApiScheduleBaseDtoValidationResponseDto {
  /**
   * The general validation errors for the entire list of validated entries.
   * E.g. if the sum of entries makes it invalid, but the items themselves are valid,
   * the errors are more general.
   */
  generalValidationErrors?: string[] | null;
  validationList?: ApiScheduleBaseDtoValidationEntry[] | null;
}

export interface ApiScheduleBatchDto {
  /** The schedules to change/validate */
  schedules?: ApiScheduleBaseDto[] | null;
}

export interface ApiSendSmsMessageDto {
  /** @format int32 */
  courseId: number;
  recipientIds?: number[] | null;
  messageText?: string | null;
}

export interface ApiSetMessageReadStatusDto {
  isRead: boolean;
}

export type ApiSignatureStatusDto = object;

export interface ApiSsbCountryDto {
  name?: string | null;
  ssbCode?: string | null;
}

export interface ApiSsbSubjectDto {
  ssbCode?: string | null;
  description?: string | null;
  parentSsbCode?: string | null;
}

export interface ApiStringStringKeyValuePair {
  key?: string | null;
  value?: string | null;
}

export interface ApiStudyLocation {
  /** @format int32 */
  id: number;

  /** @format int32 */
  organizationId: number;
  code?: string | null;
  name?: string | null;
  isActive: boolean;

  /** @format date-time */
  inserted: string;

  /** @format int32 */
  insertedBy: number;

  /** @format date-time */
  updated: string;

  /** @format int32 */
  updatedBy: number;
  department?: ApiDepartment;
  course?: ApiCourse[] | null;
}

export interface ApiTeachingGrantAmount {
  /** @format int32 */
  courseId: number;

  /** @format double */
  amount: number;

  /** @format date-time */
  inserted: string;

  /** @format date-time */
  updated: string;
  course?: ApiCourse;
}

export interface ApiTeachingMaterial {
  /** @format int32 */
  id: number;
  name?: string | null;
  description?: string | null;
  attribute1?: string | null;
  attribute2?: string | null;
  attribute3?: string | null;
  attribute4?: string | null;
  attribute5?: string | null;
  categoryName?: string | null;
  isActive: boolean;

  /** @format date-time */
  inserted: string;

  /** @format int32 */
  insertedBy: number;

  /** @format date-time */
  updated: string;

  /** @format int32 */
  updatedBy: number;
  categoryNameNavigation?: ApiTeachingMaterialCategory;
  courseTeachingMaterial?: ApiCourseTeachingMaterial[] | null;
  curriculumTeachingMaterial?: ApiCurriculumTeachingMaterial[] | null;
}

export interface ApiTeachingMaterialCategory {
  name?: string | null;
  description?: string | null;
  attribute1?: string | null;
  attribute2?: string | null;
  attribute3?: string | null;
  attribute4?: string | null;
  attribute5?: string | null;

  /** @format date-time */
  inserted: string;

  /** @format int32 */
  insertedBy: number;

  /** @format date-time */
  updated: string;

  /** @format int32 */
  updatedBy: number;
  teachingMaterial?: ApiTeachingMaterial[] | null;
}

export interface ApiTestType {
  /** @format int32 */
  id: number;
  name?: string | null;
  description?: string | null;

  /** @format date-time */
  inserted: string;

  /** @format int32 */
  insertedBy: number;

  /** @format date-time */
  updated: string;

  /** @format int32 */
  updatedBy: number;
  courseTest?: ApiCourseTest[] | null;
}

export interface ApiUpdateClientAccountRateDto {
  centralCourseRates?: ApiAccountRatesDto;
  localCourseRates?: ApiAccountRatesDto;
  productContributions?: ApiProductContributionDto;

  /** @format double */
  governmentContribution?: number | null;

  /** @format double */
  grantedAmount?: number | null;

  /** @format double */
  balance?: number | null;
  isActive: boolean;
}

export interface ApiUpdateClientAccountRateDtoValidationEntry {
  validatedItem?: ApiUpdateClientAccountRateDto;

  /** The validation errors for the SYSCO.Universum.Api.Dtos.Validation.ValidationEntry`1.ValidatedItem */
  validationErrors?: string[] | null;
}

export interface ApiUpdateClientAccountRateDtoValidationResponseDto {
  /**
   * The general validation errors for the entire list of validated entries.
   * E.g. if the sum of entries makes it invalid, but the items themselves are valid,
   * the errors are more general.
   */
  generalValidationErrors?: string[] | null;
  validationList?: ApiUpdateClientAccountRateDtoValidationEntry[] | null;
}

export interface ApiUpdateCommunicationTemplateDto {
  /**
   * Id'en malgruppen denne kommunikasjonsmalen gjelder for
   * @format int32
   */
  communicationTemplateGroupId: number;

  /** Navnet til kommunikasjonsmalen */
  name?: string | null;

  /** Emnet til kommunikasjonsmalen */
  subject?: string | null;

  /** Innholdet til kommunikasjonsmalen */
  content?: string | null;

  /** Om kommunikasjonsmalen er aktiv eller ikke */
  isActive: boolean;
}

export interface ApiUpdateContractDto {
  accountNo?: string | null;
  isSelfEmployed?: boolean | null;

  /** @format int32 */
  customerOrganizationId?: number | null;
  note?: string | null;
  isPrimaryEmployer?: boolean | null;
  taxMunicipalityNo?: string | null;
  taxMunicipalityName?: string | null;

  /**
   * The role per contract can be different than the course participant role.
   * E.g. a resource can have a contract for a role that is different than his usual role.
   */
  roleName?: string | null;
  isActive?: boolean | null;
  contractLines?: ApiUpdateContractLineDto[] | null;
}

export interface ApiUpdateContractLineDto {
  /** @format int32 */
  costId: number;
  unit?: string | null;

  /** @format double */
  amount: number;

  /**
   * If the SYSCO.Universum.Api.Dtos.CreateContractLineDto.CostId is a cost where the price is not null,
   * the cost price will be saved for the contract line instead of the value of this input field.
   * @format double
   */
  price?: number | null;
  description?: string | null;

  /** @format int32 */
  id: number;
}

export interface ApiUpdateCostDto {
  /** Kostnadstype / Navn */
  name?: string | null;

  /** Beskrivelse (kun brukt for utgifter) */
  description?: string | null;

  /**
   * Sats
   * @format double
   */
  price?: number | null;

  /**
   * Lønnsart (kun brukt for lønnskostnader)
   * @format int32
   */
  payType?: number | null;

  /** Arikkelnummer (kun brukt for utgifter) */
  articleNo?: string | null;

  /**
   * Trekkode (kun brukt for lønnskostnader)
   * @format int32
   */
  deductionCode?: number | null;

  /** Undervisningsform */
  teachingMethod?: string | null;

  /** Status */
  isActive?: boolean | null;
}

export interface ApiUpdateCourseAreaDto {
  name?: string | null;
  ssbCode?: string | null;
  code?: string | null;
  isActive?: boolean | null;
}

export interface ApiUpdateCourseDto {
  courseName?: string | null;
  externalTitle?: string | null;
  useLearningPlatform: boolean;
  courseLocation?: ApiCourseLocationDtoType;

  /** @format int32 */
  studyLocationId?: number | null;

  /** @format date-time */
  cancellationDeadline?: string | null;

  /** @format date-time */
  startDate?: string | null;

  /** @format date-time */
  endDate?: string | null;
  isTimeWebVisible: boolean;

  /** @format int32 */
  hoursWithInstructor: number;

  /** @format int32 */
  hoursWithoutInstructor?: number | null;

  /** @format int32 */
  hoursOnline?: number | null;

  /** @format int32 */
  hoursSelfStudy?: number | null;

  /**
   * Kursansvarlig - kursadministratorbruker, ansatt.
   * @format int32
   */
  courseSupervisorId: number;

  /** @format int32 */
  organizerOrganizationId: number;

  /**
   * Fagansvarlig - kursadministratorbruker, ansatt.
   * @format int32
   */
  lecturerId: number;

  /**
   * Kontaktperson - kursadministratorbruker, ansatt.
   * @format int32
   */
  contactId: number;

  /**
   * KasId er id fra gamle kursadmin system (KAS) som skal avvikles
   * @format int32
   */
  kasId?: number | null;

  /** @format date-time */
  enrollmentDeadline?: string | null;

  /** @format date-time */
  unenrollmentDeadline?: string | null;

  /** @format int32 */
  minimumParticipants: number;

  /** @format int32 */
  maxParticipants: number;

  /** @format int32 */
  participantCapacity: number;

  /** Bedriftsinternt kurs (het fra gammelt av "Bestillingskurs"/"Kurset er bestilt") */
  isOrderable: boolean;
  plan?: ApiUpsertPlanDtoType;
  additionalTeachingMaterialIds?: number[] | null;

  /** Id'ene til organisasjoner som finansierer kurset */
  financedByOrganizationIds?: number[] | null;
  tests?: ApiUpsertTestDtoType[] | null;
  comments?: string | null;
  webDescription?: ApiUpsertCourseDescriptionDto;
  economy?: ApiUpsertCourseEconomyDtoType;

  /** Gyldige statuser: planlagt, åpnet, utsatt, innstilt, avsluttet, slettet */
  status?: string | null;
  reasonForStatusChange?: string | null;
}

export interface ApiUpdateCourseExtendedDto {
  courseName?: string | null;
  externalTitle?: string | null;
  useLearningPlatform: boolean;
  courseLocation?: ApiCourseLocationDtoType;

  /** @format int32 */
  studyLocationId?: number | null;

  /** @format date-time */
  cancellationDeadline?: string | null;

  /** @format date-time */
  startDate?: string | null;

  /** @format date-time */
  endDate?: string | null;
  isTimeWebVisible: boolean;

  /** @format int32 */
  hoursWithInstructor: number;

  /** @format int32 */
  hoursWithoutInstructor?: number | null;

  /** @format int32 */
  hoursOnline?: number | null;

  /** @format int32 */
  hoursSelfStudy?: number | null;

  /**
   * Kursansvarlig - kursadministratorbruker, ansatt.
   * @format int32
   */
  courseSupervisorId: number;

  /** @format int32 */
  organizerOrganizationId: number;

  /**
   * Fagansvarlig - kursadministratorbruker, ansatt.
   * @format int32
   */
  lecturerId: number;

  /**
   * Kontaktperson - kursadministratorbruker, ansatt.
   * @format int32
   */
  contactId: number;

  /**
   * KasId er id fra gamle kursadmin system (KAS) som skal avvikles
   * @format int32
   */
  kasId?: number | null;

  /** @format date-time */
  enrollmentDeadline?: string | null;

  /** @format date-time */
  unenrollmentDeadline?: string | null;

  /** @format int32 */
  minimumParticipants: number;

  /** @format int32 */
  maxParticipants: number;

  /** @format int32 */
  participantCapacity: number;

  /** Bedriftsinternt kurs (het fra gammelt av "Bestillingskurs"/"Kurset er bestilt") */
  isOrderable: boolean;
  plan?: ApiUpsertPlanDtoType;
  additionalTeachingMaterialIds?: number[] | null;

  /** Id'ene til organisasjoner som finansierer kurset */
  financedByOrganizationIds?: number[] | null;
  tests?: ApiUpsertTestDtoType[] | null;
  comments?: string | null;
  webDescription?: ApiUpsertCourseDescriptionDto;
  economy?: ApiUpsertCourseEconomyDtoType;

  /** Gyldige statuser: planlagt, åpnet, utsatt, innstilt, avsluttet, slettet */
  status?: string | null;
  reasonForStatusChange?: string | null;

  /**
   * Id til regnskapsklient
   * @format int32
   */
  ownerOrganizationId: number;
}

export interface ApiUpdateCourseParticipanOrganizationResultDto {
  success: boolean;

  /** @format int32 */
  numberToCreate: number;

  /** @format int32 */
  numberToUpdate: number;

  /** @format int32 */
  numberCreated: number;

  /** @format int32 */
  numberUpdated: number;
  messages?: string[] | null;
}

export interface ApiUpdateCustomerPersonDto {
  firstName?: string | null;
  lastName?: string | null;

  /** @format date-time */
  birthDate?: string | null;
  phoneNumber?: string | null;
  mobileNumber?: string | null;
  postAddress1?: string | null;
  postZip?: string | null;
  postCity?: string | null;
  postCountry?: string | null;
  gender?: string | null;
  bankAccountNo?: string | null;

  /** The social security number (personnummer) for the user */
  ssno?: string | null;
  lastApprovedTermsOfUsage?: string | null;
  receiveNewsletter: boolean;

  /** @format int32 */
  birthYear?: number | null;
}

export interface ApiUpdateCustomerPersonEmailDto {
  /** The new email address for the user */
  emailAddress?: string | null;

  /** The verification code sent to the new email address */
  emailVerificationCode?: string | null;
}

export interface ApiUpdatedCustomerDto {
  name?: string | null;
  address?: string | null;
  postNo?: string | null;
  postArea?: string | null;
  email?: string | null;
  telephone?: string | null;
}

export interface ApiUpdateDraftDto {
  /** Internfakturering */
  isInternal: boolean;

  /** Kundens bestillingsnummer – (manuelt felt, PH nummer, kan være tom) */
  customerOrderNo?: string | null;

  /** Reise/utleggsreferanse – (Refusjon kurs ____)(max 30 tegn) */
  expenseRef?: string | null;

  /** Deres referanse – (manuelt) */
  theirRef?: string | null;

  /**
   * Motbilag – (ved kreditering sende PK fra originale ordrenummer)
   * @format int32
   */
  contraEntryOrderId?: number | null;

  /**
   * Avdragstype – (type 0, 1 eller 2)
   * Type 0: Ingen avdrag
   * Type 1: Fast intervall
   *     Antall avdrag
   *     Intervall(eks.antall mnd mellom hvert avdrag)
   * Type 2: Liste med avdrag(må gå opp i 100%)
   *     Dato
   *     Prosent
   * @format int32
   * @min 0
   * @max 2
   */
  paymentType: number;

  /**
   * Antall avdrag – (bare fylt ut dersom type 1)
   * @format int32
   */
  noOfPayments?: number | null;

  /**
   * Avdragsintervall – (bare fylt ut dersom type 1)
   * @format int32
   */
  paymentInverval?: number | null;

  /** @format int32 */
  cardTransactionId?: number | null;
  cardOrderRef?: string | null;
  contraEntryOrdersId?: number[] | null;

  /** @format int32 */
  courseId: number;
  participant?: ApiOrderParticipantTemplateDto;
  invoiceRecipient?: ApiOrderInvoiceRecipientTemplateDto;

  /** Avdragslinjer (en liste, bare ved type 2) */
  orderPaymentLines?: ApiOrderPaymentLineDto[] | null;
  orderLines?: ApiCreateOrderLineDto[] | null;
}

export interface ApiUpdateExpenseKursadminDto {
  /**
   * Kurs – ID
   * @format int32
   */
  courseId?: number | null;

  /**
   * Refusjonstype – ID
   * @format int32
   */
  costId?: number | null;

  /** Beskrivelse */
  description?: string | null;

  /**
   * Beløp
   * @format double
   */
  amount?: number | null;

  /**
   * Antall
   * @format double
   */
  quantity?: number | null;

  /** Status - utkast, validert, osv. */
  status?: string | null;

  /** Melding til kursdeltaker fra kursadministrator */
  messageToParticipant?: string | null;
}

export interface ApiUpdateExpenseMinsideDto {
  /**
   * Kurs – ID
   * @format int32
   */
  courseId?: number | null;

  /**
   * Refusjonstype – ID
   * @format int32
   */
  costId?: number | null;

  /** Beskrivelse */
  description?: string | null;

  /**
   * Beløp
   * @format double
   */
  amount?: number | null;

  /**
   * Antall
   * @format double
   */
  quantity?: number | null;

  /** Bankkontonummer */
  accountNumber?: string | null;
}

export type ApiUpdateFileCategoryDto = object;

export interface ApiUpdateHolidayDetailDto {
  /** @format int32 */
  id: number;
  periodName?: string | null;

  /** @format date-time */
  fromDate: string;

  /** @format date-time */
  toDate: string;
}

export interface ApiUpdateHolidayDto {
  name?: string | null;
  isActive: boolean;
  details?: ApiUpdateHolidayDetailDto[] | null;
}

export interface ApiUpdateMemberOrganizationDto {
  name?: string | null;
  orgNo?: string | null;
  phone?: string | null;
  email?: string | null;
  url?: string | null;
  isWebVisible?: boolean | null;

  /** @format int32 */
  clientAccountNumber?: number | null;
  inheritOrgNoFromParent?: boolean | null;
}

export interface ApiUpdateNusCodeDto {
  name?: string | null;
  shortName?: string | null;
  educationalOfferCode?: string | null;
  isActive: boolean;
}

export interface ApiUpdateRoleDto {
  description?: string | null;
  isActive?: boolean | null;
  isResource?: boolean | null;
  isForRegularCourse?: boolean | null;
  isForVocationalSchool?: boolean | null;
  cannotBeCombined?: boolean | null;
  requiresCertification?: boolean | null;
}

export interface ApiUpdateTeachingMaterialCategoryDto {
  description?: string | null;
  attribute1?: string | null;
  attribute2?: string | null;
  attribute3?: string | null;
  attribute4?: string | null;
  attribute5?: string | null;
}

export interface ApiUpdateWorkingHourDto {
  /**
   * The id'en til kurskontraktlinjen som det skal skrives timer for
   * @format int32
   */
  courseContractLineId: number;

  /**
   * Antall timer som skal registreres. Kan bli satt ned til nærmeste halvtime (e.g. 2.5)
   * @format double
   */
  amount: number;

  /**
   * <br>
   *             Hvis kostnadstypen for kontraktlinjen er 'Undervisningstimer' så må denne datoen/tidspunktet tilsvare en dato/tidspunkt i timeplanen.
   *
   *             Hvis kostnadstypen for kontraktlinjen ikke er 'Undervisningstimer' så må denne datoen tilsvare en dag hvor
   *             UTC tidspunktet er 00:00:00 på den angitte dagen og være for i dag eller en dag i fortiden.
   *
   * @format date-time
   */
  date: string;

  /** Kommentaren til de registrerte timene. Kan være opptil 500 karakterer lang. */
  comment?: string | null;
}

export interface ApiUpsertAppFormResponseAdditionalQuestionDto {
  /** Can be boolean or text */
  response?: string | null;

  /** @format uuid */
  questionId: string;
}

export interface ApiUpsertApplicationFormResponseDto {
  /** @format uuid */
  templateId: string;
  employer?: ApiEmployerDto;
  isCostBearerPrivate?: boolean | null;
  costBearerOrganization?: ApiCostBearerOrganizationDto;
  purchaser?: ApiPurchaserOrganizationDto;
  isFormalCompetence?: boolean | null;
  isNorwegianCitizen?: boolean | null;
  citizenship?: string | null;
  isNorwegianMotherTongue?: boolean | null;
  norwegianSkillsLevel?: string | null;
  norwegianSkillsCerfLevel?: string | null;
  personalNumber?: string | null;
  isNewsletter?: boolean | null;
  isUnionized?: boolean | null;

  /** @format int32 */
  memberOrganizationId?: number | null;
  courseRegulationsAccepted?: boolean | null;

  /** @format int32 */
  regulationsFileId?: number | null;
  additionalQuestions?: ApiUpsertAppFormResponseAdditionalQuestionDto[] | null;
  costBearerPrivate?: ApiUpsertCostBearerPrivateDto;
}

export interface ApiUpsertAreaDto {
  country?: string | null;
  countryCode?: string | null;
  county?: string | null;
  municipality?: string | null;
  place?: string | null;
  studyLocationCode?: string | null;
}

export interface ApiUpsertCostBearerPrivateDto {
  /**
   * Ønsket avdrag; mndlig(1), kvartalsvis(3), halvårlig(6)
   * @format int32
   */
  installmentInterval?: number | null;

  /** @format int32 */
  installmentType?: number | null;
  hasVerifiedContactInfo?: boolean | null;
}

export interface ApiUpsertCourseDescriptionDto {
  /** @format uuid */
  templateId: string;
  title?: string | null;
  webCategory?: string | null;
  intro?: string | null;
  targetGroup?: string | null;
  foreknowledgeRequirements?: string | null;
  documentationRequired?: boolean | null;
  learningObjectives?: string | null;
  courseContent?: string | null;
  method?: string | null;
  financing?: string | null;
  exam?: string | null;

  /** @format date-time */
  publishDate: string;

  /** @format date-time */
  unpublishDate?: string | null;
  enrollmentStatus?: string | null;
  defaultEnrollmentStudentStatus?: string | null;
}

export interface ApiUpsertCourseDtoType {
  content?: string | null;
  method?: string | null;
  teachingMaterials?: number[] | null;
}

export interface ApiUpsertCourseEconomyDtoType {
  /** @format int32 */
  accountingDepartment?: number | null;

  /** @format double */
  cancellationFee?: number | null;

  /** @format double */
  price?: number | null;

  /**
   * Id til opplæringstilskudd
   * @format int32
   */
  grantId?: number | null;

  /** @format int32 */
  grantRateId?: number | null;
  allowExpenses?: boolean | null;
  articleNos?: string[] | null;
}

export interface ApiUpsertCourseNoteDto {
  title?: string | null;
  text?: string | null;
}

export interface ApiUpsertCourseParticipantDto {
  /** @format int32 */
  userId: number;
  roleName?: string | null;
  roleNames?: string[] | null;
  status?: string | null;
  statusComment?: string | null;
  requiresElectronicInvoice: boolean;

  /** @format int32 */
  memberOrganizationId?: number | null;

  /** @format int32 */
  purchaserId?: number | null;

  /** @format int32 */
  invoiceRecipientId?: number | null;
}

export interface ApiUpsertCurriculumDto {
  /** @format uuid */
  draftId: string;

  /** Point 1 */
  name?: string | null;

  /** @format int32 */
  memberOrgId?: number | null;

  /** @format int32 */
  subCourseId: number;
  cefrLevel?: string | null;

  /** @format double */
  hoursMin: number;

  /** @format double */
  hoursMax: number;

  /** @format double */
  hoursOnline?: number | null;

  /** @format double */
  hoursStudyLoad?: number | null;

  /** @format int32 */
  levelId: number;

  /** @format int32 */
  points?: number | null;

  /** @format int32 */
  evaluationId: number;
  nusCode?: string | null;

  /** Point 2 */
  teacherRequired: boolean;
  teacherRequirements?: string | null;

  /** Point 3 */
  foreknowledgeRequired: boolean;
  foreknowledgeRequirements?: string | null;
  targetGroup?: string | null;

  /** Point 4 */
  externalCurriculumUrl?: string | null;
  learningObjectives?: string | null;
  course?: ApiUpsertCourseDtoType;

  /** Point 6 */
  courseCertificateRequired: boolean;

  /** Point 7 */
  webContent?: string | null;
  teachingMethod?: string | null;
  vocationalSchool?: ApiVocationalSchoolDtoType;
  partners?: number[] | null;
}

export interface ApiUpsertCurriculumStatusDto {
  /** @format int32 */
  statusId: number;
}

export interface ApiUpsertCustomerOrgDto {
  /** @format int32 */
  id: number;
  postAddress1?: string | null;
  postAddress2?: string | null;
  postAddress3?: string | null;
  postCity?: string | null;
  postZip?: string | null;
  postCountry?: string | null;
  invoiceAddress1?: string | null;
  invoiceAddress2?: string | null;
  invoiceAddress3?: string | null;
  invoiceCity?: string | null;
  invoiceZip?: string | null;
  invoiceCountry?: string | null;
  phoneNumber?: string | null;
  mobileNumber?: string | null;
  email?: string | null;
  isActive: boolean;
  customer?: ApiCustomerOrgDto;
}

export interface ApiUpsertEmployeeContractLineDto {
  /** @format double */
  amountOfHours: number;
  description?: string | null;
}

export interface ApiUpsertGrantRateDto {
  /** @format double */
  teachingGrantAmount: number;

  /** @format double */
  extraProfessionalCourseAmount: number;
}

export interface ApiUpsertLocationDto {
  street?: string | null;
  postalCode?: string | null;
  postalArea?: string | null;
  isActive?: boolean | null;
  details?: string | null;
}

export interface ApiUpsertMemberOrganizationUserDto {
  email?: string | null;
}

export interface ApiUpsertPlanDtoType {
  /** @format int32 */
  schoolRouteId: number;
  registerHours: boolean;
  schedules?: ApiScheduleBaseDto[] | null;
}

export interface ApiUpsertRoomDto {
  name?: string | null;

  /** @format int32 */
  capacity?: number | null;

  /** @format int32 */
  recommendedCapacity?: number | null;

  /** @format int32 */
  floorNumber?: number | null;
  isActive?: boolean | null;
  description?: string | null;
}

export interface ApiUpsertStudyLocationDto {
  code?: string | null;
  name?: string | null;
  isActive?: boolean | null;
}

export interface ApiUpsertTeachingMaterialDto {
  name?: string | null;
  category?: string | null;
  description?: string | null;
  isActive?: boolean | null;
  attribute1?: string | null;
  attribute2?: string | null;
  attribute3?: string | null;
  attribute4?: string | null;
  attribute5?: string | null;
}

export interface ApiUpsertTestDtoType {
  /** @format int32 */
  testTypeId: number;
  name?: string | null;
  description?: string | null;
}

export interface ApiUpsertTestTypeDto {
  name?: string | null;
  description?: string | null;
}

export interface ApiUser {
  /** @format int32 */
  id: number;
  type?: string | null;
  firstName?: string | null;
  middleName?: string | null;
  lastName?: string | null;
  isActive: boolean;

  /** @format date-time */
  inserted: string;

  /** @format int32 */
  insertedBy: number;

  /** @format date-time */
  updated: string;

  /** @format int32 */
  updatedBy: number;

  /** @format int32 */
  msgUserId?: number | null;
  customerOrganization?: ApiCustomerOrganization;
  customerPerson?: ApiCustomerPerson;
  employee?: ApiEmployee;
  guestEmployee?: ApiGuestEmployee;
  courseParticipant?: ApiCourseParticipant[] | null;
  orderInvoiceRecipient?: ApiOrderInvoiceRecipient[] | null;
  orderParticipant?: ApiOrderParticipant[] | null;
  orderLine?: ApiOrderLine[] | null;
  userPayrollEmployee?: ApiUserPayrollEmployee[] | null;
  userEmailVerificationCode?: ApiUserEmailVerificationCode[] | null;
}

export interface ApiUserEmailVerificationCode {
  /** @format int32 */
  userEmailVerificationCodeId: number;

  /** @format int32 */
  userId: number;
  email?: string | null;
  verificationCode?: string | null;

  /** @format date-time */
  expirationDate: string;
  user?: ApiUser;
}

export interface ApiUserPayrollEmployee {
  /** @format int32 */
  userId: number;

  /** @format int32 */
  erpClientId: number;

  /** @format int32 */
  erpPayrollEmployeeId: number;
  user?: ApiUser;
}

export interface ApiUsersIdNameDto {
  /** @format int32 */
  id: number;
  name?: string | null;
}

/**
* The following usage of the dto is suggested for each use.
Creating a course:
<code>
POST schedule/validate
{
    schoolRouteId: {value}, (because the value can be changed while creating)
    curriculumId: {value}, (because the value can be changed while creating)
    hoursWithInstructor: {value}, (because the value can be changed while creating)
    schedules: {values} (because the value can be changed while creating)
}
</code>

Updating a course:
<code>
POST course/{courseId}/schedule/validate
{
    schoolRouteId: {value}, (because the value can be changed while editing)
    curriculumId: {value}, (because the value can be changed while editing)
    hoursWithInstructor: {value}, (because the value can be changed while editing)
    schedules: {values} (because the value can be changed while editing)
}
</code>

Changing a schedule in the calendar:
<code>
POST course/{courseId}/schedule/validate
{
    schoolRouteId: null, (because the value can't change while only changing schedules for a course)
    curriculumId: null, (because the value can't change while only changing schedules for a course)
    hoursWithInstructor: null, (because the value can't change while only changing schedules for a course)
    schedules: {values} (because the value can be changed while changing schedules)
}
</code>
*/
export interface ApiValidateSchedulesDto {
  /** The schedules to change/validate */
  schedules?: ApiScheduleBaseDto[] | null;

  /**
   * The id of the school route (holiday-entry) to check for intersections.
   * Should be set if the CourseId is not used in the url or if the SYSCO.Universum.Api.Dtos.Schedule.ValidateSchedulesDto.SchoolRouteId for the given CourseId can be different (e.g. when editing the course).
   * @format int32
   */
  schoolRouteId?: number | null;

  /**
   * The id of the curriculum to check for min and max hours allowed.
   * Should be set if the CourseId is not used in the url or if the SYSCO.Universum.Api.Dtos.Schedule.ValidateSchedulesDto.CurriculumId for the given CourseId can be different (e.g. when editing the course).
   * @format int32
   */
  curriculumId?: number | null;

  /**
   * The number of hours with instructor, will be used to validate against the schedules.
   * Should be set if the CourseId is not used in the url or if the SYSCO.Universum.Api.Dtos.Schedule.ValidateSchedulesDto.HoursWithInstructor for the given CourseId can be different (e.g. when editing the course).
   * @format double
   */
  hoursWithInstructor?: number | null;
}

export interface ApiVocationalSchoolDtoType {
  /** @format int32 */
  subjectCount: number;
  practice: boolean;
}

export interface ApiWorkingHour {
  /** @format int64 */
  id: number;

  /** @format int32 */
  courseContractLineId: number;

  /** @format date-time */
  date: string;

  /** @format double */
  amount: number;
  comment?: string | null;
  status?: string | null;

  /** @format int32 */
  approvedBy?: number | null;

  /** @format int32 */
  declinedBy?: number | null;

  /** @format int64 */
  workingHourFileId?: number | null;

  /** @format date-time */
  lastApprovedDate?: string | null;

  /** @format date-time */
  declinedDate?: string | null;

  /** @format int32 */
  insertedBy: number;

  /** @format date-time */
  inserted: string;

  /** @format int32 */
  updatedBy: number;

  /** @format date-time */
  updated: string;
  approvedByEmployee?: ApiEmployee;
  declinedByEmployee?: ApiEmployee;
  courseContractLine?: ApiCourseContractLine;
  workingHourFile?: ApiWorkingHourFile;
}

export interface ApiWorkingHourFile {
  /** @format int64 */
  id: number;

  /** @format int64 */
  fileId?: number | null;
  serviceBusMessageId?: string | null;
  status?: string | null;

  /** @format date-time */
  inserted: string;

  /** @format int32 */
  insertedBy: number;
  workingHour?: ApiWorkingHour[] | null;
  file?: ApiFile;
}

/**
 * @format int32
 */
export type ApiWorkingHourUserIdFilter = 0 | 1 | 2 | 3;

import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse, ResponseType } from "axios";

export type QueryParamsType = Record<string | number, any>;

export interface FullRequestParams extends Omit<AxiosRequestConfig, "data" | "params" | "url" | "responseType"> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseType;
  /** request body */
  body?: unknown;
}

export type RequestParams = Omit<FullRequestParams, "body" | "method" | "query" | "path">;

export interface ApiConfig<SecurityDataType = unknown> extends Omit<AxiosRequestConfig, "data" | "cancelToken"> {
  securityWorker?: (
    securityData: SecurityDataType | null
  ) => Promise<AxiosRequestConfig | void> | AxiosRequestConfig | void;
  secure?: boolean;
  format?: ResponseType;
}

export enum ContentType {
  Json = "application/json",
  FormData = "multipart/form-data",
  UrlEncoded = "application/x-www-form-urlencoded",
}

export class HttpClient<SecurityDataType = unknown> {
  public instance: AxiosInstance;
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>["securityWorker"];
  private secure?: boolean;
  private format?: ResponseType;

  constructor({ securityWorker, secure, format, ...axiosConfig }: ApiConfig<SecurityDataType> = {}) {
    this.instance = axios.create({
      ...axiosConfig,
      baseURL: axiosConfig.baseURL || "http://sco-aof-common-universum-weu-apim.azure-api.net/dev/v2/kursadmin",
    });
    this.secure = secure;
    this.format = format;
    this.securityWorker = securityWorker;
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  private mergeRequestParams(params1: AxiosRequestConfig, params2?: AxiosRequestConfig): AxiosRequestConfig {
    return {
      ...this.instance.defaults,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...(this.instance.defaults.headers || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  private createFormData(input: Record<string, unknown>): FormData {
    return Object.keys(input || {}).reduce((formData, key) => {
      const property = input[key];
      this.formatFormData(formData, key, property);
      return formData;
    }, new FormData());
  }

  public formatFormData = (formData: FormData, key: string, property: unknown) => {
    if (Array.isArray(property)) {
      property.forEach((element: unknown) => {
        this.formatFormData(formData, key, element);
      });
      return;
    }
    if (property instanceof Blob) {
      formData.append(key, property);
      return;
    }
    if (typeof property === "object" && property !== null) {
      formData.append(key, JSON.stringify(property));
      return;
    }
    formData.append(key, `${property}`);
  };

  public request = async <T = any, _E = any>({
    secure,
    path,
    type,
    query,
    format,
    body,
    ...params
  }: FullRequestParams): Promise<AxiosResponse<T>> => {
    const secureParams =
      ((typeof secure === "boolean" ? secure : this.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const responseFormat = (format && this.format) || void 0;

    if (type === ContentType.FormData && body && body !== null && typeof body === "object") {
      requestParams.headers.common = { Accept: "*/*" };
      requestParams.headers.post = {};
      requestParams.headers.put = {};

      body = this.createFormData(body as Record<string, unknown>);
    }

    return this.instance.request({
      ...requestParams,
      headers: {
        ...(type && type !== ContentType.FormData ? { "Content-Type": type } : {}),
        ...(requestParams.headers || {}),
      },
      params: query,
      responseType: responseFormat,
      data: body,
      url: path,
    });
  };
}

/**
 * @title Dev KursAdmin API v2
 * @version 1.0
 * @baseUrl http://sco-aof-common-universum-weu-apim.azure-api.net/dev/v2/kursadmin
 *
 * Secure KursAdmin backend API. You need JWT(AD) to access all the operations.
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  course = {
    /**
     * No description
     *
     * @tags AppFormResponse, kursadmin
     * @name GetAppFormResponseAsync
     * @request GET:/course/{courseid}/participant/{userId}/appformresponse
     */
    getAppFormResponseAsync: (courseid: number, userId: number, params: RequestParams = {}) =>
      this.request<ApiGetApplicationFormResponseDto, any>({
        path: `/course/${courseid}/participant/${userId}/appformresponse`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Course, kursadmin, minside
     * @name GetCourseDefaultOptions
     * @request GET:/course/defaultoptions
     */
    getCourseDefaultOptions: (params: RequestParams = {}) =>
      this.request<ApiGetCourseDefaultOptionDto, any>({
        path: `/course/defaultoptions`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Course, kursadmin
     * @name GetCoursesFilteredByDepartmentAsync
     * @request GET:/course
     */
    getCoursesFilteredByDepartmentAsync: (
      query?: {
        CourseResponsibleFilter?: ApiCourseResponsibleFilter;
        Includes?: string[];
        Statuses?: string[];
        UserId?: number;
        PageNumber?: number;
        PageSize?: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<ApiGetShortCourseDto[], any>({
        path: `/course`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Course, kursadmin
     * @name CreateCourseAsync
     * @request POST:/course
     */
    createCourseAsync: (data: ApiCreateCourseDto, params: RequestParams = {}) =>
      this.request<ApiInt32IdDto, any>({
        path: `/course`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Course, kursadmin
     * @name GetCourseByIdFilteredByDepartmentAsync
     * @request GET:/course/{id}
     */
    getCourseByIdFilteredByDepartmentAsync: (id: number, params: RequestParams = {}) =>
      this.request<ApiGetKursAdminCourseDto, any>({
        path: `/course/${id}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Course, kursadmin
     * @name UpdateCourseAsync
     * @request PUT:/course/{id}
     */
    updateCourseAsync: (id: number, data: ApiUpdateCourseDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/course/${id}`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Course, kursadmin, minside
     * @name GetCourseDescriptionByIdAsync
     * @request GET:/course/{id}/webdescription
     */
    getCourseDescriptionByIdAsync: (id: number, params: RequestParams = {}) =>
      this.request<ApiGetCourseDescriptionDto, any>({
        path: `/course/${id}/webdescription`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Course, kursadmin
     * @name GetCourseParticipantsByCourseIdAsync
     * @request GET:/course/{id}/participant
     */
    getCourseParticipantsByCourseIdAsync: (
      id: number,
      query?: { RoleNames?: string[]; Statuses?: string[]; IsActive?: boolean; isResource?: boolean },
      params: RequestParams = {}
    ) =>
      this.request<ApiGetCourseParticipantKursAdminDto[], any>({
        path: `/course/${id}/participant`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Course, kursadmin
     * @name UpsertCourseParticipantAsync
     * @request POST:/course/{id}/participant
     */
    upsertCourseParticipantAsync: (id: number, data: ApiUpsertCourseParticipantDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/course/${id}/participant`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Course, kursadmin
     * @name GetCourseResourcesByCourseIdAsync
     * @request GET:/course/{id}/resource
     */
    getCourseResourcesByCourseIdAsync: (id: number, params: RequestParams = {}) =>
      this.request<ApiGetCourseResourceDto[], any>({
        path: `/course/${id}/resource`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Course, kursadmin
     * @name InsertEmployeeContractLine
     * @request POST:/course/{courseId}/user/{userId}/employeecontractline
     */
    insertEmployeeContractLine: (
      courseId: number,
      userId: number,
      data: ApiUpsertEmployeeContractLineDto,
      params: RequestParams = {}
    ) =>
      this.request<ApiInt32IdDto, string[] | void>({
        path: `/course/${courseId}/user/${userId}/employeecontractline`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Course, kursadmin
     * @name DeleteEmployeeContractLine
     * @request DELETE:/course/employeecontractline/{id}
     */
    deleteEmployeeContractLine: (id: number, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/course/employeecontractline/${id}`,
        method: "DELETE",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Course, kursadmin
     * @name UpdateEmployeeContractLine
     * @request PUT:/course/employeecontractline/{id}
     */
    updateEmployeeContractLine: (id: number, data: ApiUpsertEmployeeContractLineDto, params: RequestParams = {}) =>
      this.request<void, string[] | void>({
        path: `/course/employeecontractline/${id}`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Course, kursadmin
     * @name GetEmployeeContractLines
     * @request GET:/course/{id}/user/{userId}/employeecontractline
     */
    getEmployeeContractLines: (id: number, userId: number, params: RequestParams = {}) =>
      this.request<ApiGetEmployeeContractLineDto[], void>({
        path: `/course/${id}/user/${userId}/employeecontractline`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Course, kursadmin
     * @name DeleteCourseResourcesByCourseIdAsync
     * @request DELETE:/course/{id}/resource/{userId}
     */
    deleteCourseResourcesByCourseIdAsync: (id: number, userId: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/course/${id}/resource/${userId}`,
        method: "DELETE",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Course, kursadmin
     * @name CreateFacilitationCourseAsync
     * @request POST:/course/{mainCourseId}/facilitationcourse
     */
    createFacilitationCourseAsync: (mainCourseId: number, data: ApiCreateCourseDto, params: RequestParams = {}) =>
      this.request<ApiInt32IdDto, any>({
        path: `/course/${mainCourseId}/facilitationcourse`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Reopen a closed course, can only be done by 'Kursadminstrator utvidet'
     *
     * @tags Course, kursadmin
     * @name ReopenCourseAsync
     * @request POST:/course/{id}/reopen
     */
    reopenCourseAsync: (id: number, data: ApiReopenCourseStatusDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/course/${id}/reopen`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Course, kursadmin
     * @name CloseCourseParticipantsAsync
     * @request POST:/course/{id}/close/participants
     */
    closeCourseParticipantsAsync: (id: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/course/${id}/close/participants`,
        method: "POST",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Course, kursadmin
     * @name ValidateCloseCourseAsync
     * @request POST:/course/{id}/close/validate
     */
    validateCloseCourseAsync: (
      id: number,
      query?: { skipWorkingHours?: boolean; skipTeacherHours?: boolean; skipAmountOfParticipantsOlderThan14?: boolean },
      params: RequestParams = {}
    ) =>
      this.request<ApiCloseCourseValidationDto, any>({
        path: `/course/${id}/close/validate`,
        method: "POST",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Course, kursadmin
     * @name CloseCourseAsync
     * @request POST:/course/{id}/close
     */
    closeCourseAsync: (
      id: number,
      query?: { skipWorkingHours?: boolean; skipTeacherHours?: boolean; skipAmountOfParticipantsOlderThan14?: boolean },
      params: RequestParams = {}
    ) =>
      this.request<ApiCloseCourseValidationDto, any>({
        path: `/course/${id}/close`,
        method: "POST",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Course, kursadmin
     * @name CalulateTeachingGrantAmountAsync
     * @request POST:/course/{id}/close/teachinggrant
     */
    calulateTeachingGrantAmountAsync: (id: number, params: RequestParams = {}) =>
      this.request<ApiInt32IdDto, void>({
        path: `/course/${id}/close/teachinggrant`,
        method: "POST",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Course, kursadmin
     * @name GetTeachingGrantAmountAsync
     * @request GET:/course/{id}/teachinggrant
     */
    getTeachingGrantAmountAsync: (id: number, params: RequestParams = {}) =>
      this.request<ApiGetTeachingGrantAmountDtoIdDto, void>({
        path: `/course/${id}/teachinggrant`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Course, kursadmin
     * @name UpdateCourseParticipantRanksAsync
     * @request PUT:/course/{id}/participant/rank
     */
    updateCourseParticipantRanksAsync: (id: number, data: ApiIdRankDto[], params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/course/${id}/participant/rank`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Course, kursadmin
     * @name GetCourseNotesAsync
     * @request GET:/course/{id}/note
     */
    getCourseNotesAsync: (id: number, params: RequestParams = {}) =>
      this.request<ApiGetCourseNoteDto[], any>({
        path: `/course/${id}/note`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Course, kursadmin
     * @name CreateCourseNoteAsync
     * @request POST:/course/{id}/note
     */
    createCourseNoteAsync: (id: number, data: ApiUpsertCourseNoteDto, params: RequestParams = {}) =>
      this.request<ApiInt32IdDto, any>({
        path: `/course/${id}/note`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Course, kursadmin
     * @name GetCourseNoteAsync
     * @request GET:/course/{id}/note/{courseNoteId}
     */
    getCourseNoteAsync: (id: number, courseNoteId: number, params: RequestParams = {}) =>
      this.request<ApiGetCourseNoteDto, any>({
        path: `/course/${id}/note/${courseNoteId}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Course, kursadmin
     * @name DeleteCourseNoteAsync
     * @request DELETE:/course/{id}/note/{courseNoteId}
     */
    deleteCourseNoteAsync: (id: number, courseNoteId: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/course/${id}/note/${courseNoteId}`,
        method: "DELETE",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Course, kursadmin
     * @name GetOrdersAsync
     * @request GET:/course/{id}/order
     */
    getOrdersAsync: (id: number, query?: { PageNumber?: number; PageSize?: number }, params: RequestParams = {}) =>
      this.request<ApiGetOrderDto[], any>({
        path: `/course/${id}/order`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags CourseInvitation, kursadmin
     * @name GetCourseInvitations
     * @request GET:/course/{id}/invitation
     */
    getCourseInvitations: (
      id: number,
      query?: { CourseId?: number; Statuses?: string[]; PageNumber?: number; PageSize?: number },
      params: RequestParams = {}
    ) =>
      this.request<ApiGetCourseInvitationDto[], void>({
        path: `/course/${id}/invitation`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags CourseInvitation, kursadmin
     * @name CreateInvitationsAsync
     * @request POST:/course/{id}/invitation
     */
    createInvitationsAsync: (id: number, data: ApiCreateCourseInvitationDto[], params: RequestParams = {}) =>
      this.request<ApiInt32ListIdDto, string[] | void>({
        path: `/course/${id}/invitation`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags CourseInvitation, kursadmin
     * @name DeleteInvitationAsync
     * @request DELETE:/course/{courseId}/invitation/{invitationId}
     */
    deleteInvitationAsync: (courseId: number, invitationId: number, params: RequestParams = {}) =>
      this.request<void, string[] | void>({
        path: `/course/${courseId}/invitation/${invitationId}`,
        method: "DELETE",
        ...params,
      }),

    /**
     * No description
     *
     * @tags CourseParticipantAttendance, kursadmin, minside
     * @name GetCourseParticipantAttendancesByCourseIdAsync
     * @request GET:/course/{courseId}/attendance
     */
    getCourseParticipantAttendancesByCourseIdAsync: (
      courseId: number,
      query?: {
        ScheduleStart?: string;
        UserId?: number;
        UserFullName?: string;
        IsRegistered?: boolean;
        IsSetByKursAdmin?: boolean;
      },
      params: RequestParams = {}
    ) =>
      this.request<ApiGetCourseParticipantAttendanceDto[], string[] | void>({
        path: `/course/${courseId}/attendance`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * @description Merges (creates/updates) the list of attendances for the given course
     *
     * @tags CourseParticipantAttendance, kursadmin, minside
     * @name BatchMergeCourseParticipantAttendancesByCourseIdAsync
     * @request POST:/course/{courseId}/attendance/batchmerge
     */
    batchMergeCourseParticipantAttendancesByCourseIdAsync: (
      courseId: number,
      data: ApiBatchMergeCourseParticipantAttendancesDto,
      params: RequestParams = {}
    ) =>
      this.request<void, ApiCourseParticipantAttendanceBaseDtoValidationResponseDto | void>({
        path: `/course/${courseId}/attendance/batchmerge`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags CourseParticipantAttendance, kursadmin
     * @name DeleteCourseParticipantAttendanceAsync
     * @request DELETE:/course/{courseId}/attendance/{userId}
     */
    deleteCourseParticipantAttendanceAsync: (
      courseId: number,
      userId: number,
      query?: { ScheduleStart?: string },
      params: RequestParams = {}
    ) =>
      this.request<void, void>({
        path: `/course/${courseId}/attendance/${userId}`,
        method: "DELETE",
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags FacilitationGrantApplication, kursadmin
     * @name GetGrantApplicationAsync
     * @request GET:/course/{courseId}/facilitationgrantapplication
     */
    getGrantApplicationAsync: (courseId: number, params: RequestParams = {}) =>
      this.request<ApiGetFacilitationGrantApplicationDto, any>({
        path: `/course/${courseId}/facilitationgrantapplication`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * @description Creates new facilitation grant application
     *
     * @tags FacilitationGrantApplication, kursadmin
     * @name CreateGrantApplication
     * @request POST:/course/{courseId}/facilitationgrantapplication
     */
    createGrantApplication: (
      courseId: number,
      data: ApiCreateFacilitationGrantApplicationDto,
      params: RequestParams = {}
    ) =>
      this.request<void, ApiCreateFacilitationGrantApplicationDtoValidationResponseDto>({
        path: `/course/${courseId}/facilitationgrantapplication`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags FacilitationGrantApplication, kursadmin
     * @name GetFilteredGrantApplicationsAsync
     * @request GET:/course/facilitationgrantapplication
     */
    getFilteredGrantApplicationsAsync: (params: RequestParams = {}) =>
      this.request<ApiGetFacilitationGrantApplicationDto[], any>({
        path: `/course/facilitationgrantapplication`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * @description Rejects a facilitation grant application
     *
     * @tags FacilitationGrantApplication, kursadmin
     * @name RejectFacilitationGrantApplication
     * @request POST:/course/{courseId}/facilitationgrantapplication/reject
     */
    rejectFacilitationGrantApplication: (
      courseId: number,
      data: ApiRejectFacilitationGrantApplicationDto,
      params: RequestParams = {}
    ) =>
      this.request<void, ApiInt32ValidationResponseDto>({
        path: `/course/${courseId}/facilitationgrantapplication/reject`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Approves facilitation grant application
     *
     * @tags FacilitationGrantApplication, kursadmin
     * @name ApproveFacilitationGrantApplication
     * @request POST:/course/{courseId}/facilitationgrantapplication/approve
     */
    approveFacilitationGrantApplication: (
      courseId: number,
      data: ApiApproveFacilitationGrantApplicationDto,
      params: RequestParams = {}
    ) =>
      this.request<void, ApiApproveFacilitationGrantApplicationDtoValidationResponseDto>({
        path: `/course/${courseId}/facilitationgrantapplication/approve`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Approves facilitation grant application costs
     *
     * @tags FacilitationGrantApplication, kursadmin
     * @name ApproveActualCostsForFacilitationGrantApplication
     * @request POST:/course/{courseId}/facilitationgrantapplication/approvecosts
     */
    approveActualCostsForFacilitationGrantApplication: (
      courseId: number,
      data: ApiApproveFacilitationGrantApplicationCostsDto,
      params: RequestParams = {}
    ) =>
      this.request<void, ApiApproveFacilitationGrantApplicationCostsDtoValidationResponseDto>({
        path: `/course/${courseId}/facilitationgrantapplication/approvecosts`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Add actual costs a facilitation grant application
     *
     * @tags FacilitationGrantApplication, kursadmin
     * @name AddActualCostsToGrantApplication
     * @request POST:/course/{courseId}/facilitationgrantapplication/addcosts
     */
    addActualCostsToGrantApplication: (
      courseId: number,
      data: ApiAddActualCostsToFacilitationGrantApplicationDto,
      params: RequestParams = {}
    ) =>
      this.request<void, ApiAddActualCostsToFacilitationGrantApplicationDtoValidationResponseDto>({
        path: `/course/${courseId}/facilitationgrantapplication/addcosts`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Validates the schedules based on the input data
     *
     * @tags Schedule, kursadmin
     * @name ValidateSchedulesForCourseAsync
     * @request POST:/course/{courseId}/schedule/validate
     */
    validateSchedulesForCourseAsync: (courseId: number, data: ApiValidateSchedulesDto, params: RequestParams = {}) =>
      this.request<void, ApiScheduleBaseDtoValidationResponseDto | void>({
        path: `/course/${courseId}/schedule/validate`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Replaces the schedules for the course with the given list
     *
     * @tags Schedule, kursadmin
     * @name BatchUpdateSchedule
     * @request PUT:/course/{courseId}/schedule/batch
     */
    batchUpdateSchedule: (courseId: number, data: ApiScheduleBatchDto, params: RequestParams = {}) =>
      this.request<void, ApiScheduleBaseDtoValidationResponseDto | void>({
        path: `/course/${courseId}/schedule/batch`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        ...params,
      }),
  };
  appformtemplate = {
    /**
     * No description
     *
     * @tags AppFormTemplate, kursadmin, minside
     * @name GetAppFormTemplatesAsync
     * @request GET:/appformtemplate
     */
    getAppFormTemplatesAsync: (params: RequestParams = {}) =>
      this.request<ApiGetApplicationFormTemplateDto[], any>({
        path: `/appformtemplate`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags AppFormTemplate, kursadmin
     * @name CreateAppFormTemplateAsync
     * @request POST:/appformtemplate
     */
    createAppFormTemplateAsync: (data: ApiCreateApplicationFormTemplateDto, params: RequestParams = {}) =>
      this.request<ApiGuidIdDto, any>({
        path: `/appformtemplate`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags AppFormTemplate, kursadmin, minside
     * @name GetAppFormTemplateAsync
     * @request GET:/appformtemplate/{id}
     */
    getAppFormTemplateAsync: (id: string, params: RequestParams = {}) =>
      this.request<ApiGetApplicationFormTemplateDto, any>({
        path: `/appformtemplate/${id}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags AppFormTemplate, kursadmin
     * @name UpdateAppFormTemplate
     * @request PUT:/appformtemplate/{id}
     */
    updateAppFormTemplate: (id: string, data: ApiPutAppFormTemplateDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/appformtemplate/${id}`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags AppFormTemplate, kursadmin
     * @name DeleteAppFormTemplateAsync
     * @request DELETE:/appformtemplate/{id}
     */
    deleteAppFormTemplateAsync: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/appformtemplate/${id}`,
        method: "DELETE",
        ...params,
      }),
  };
  area = {
    /**
     * No description
     *
     * @tags Area, kursadmin, minside
     * @name GetAreasAsync
     * @request GET:/area
     */
    getAreasAsync: (params: RequestParams = {}) =>
      this.request<ApiGetAreaDto[], any>({
        path: `/area`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Area, kursadmin
     * @name CreateAreaAsync
     * @request POST:/area
     */
    createAreaAsync: (data: ApiUpsertAreaDto, params: RequestParams = {}) =>
      this.request<ApiInt32IdDto, any>({
        path: `/area`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Area, kursadmin, minside
     * @name GetAreaByIdAsync
     * @request GET:/area/{id}
     */
    getAreaByIdAsync: (id: number, params: RequestParams = {}) =>
      this.request<ApiGetAreaDto, any>({
        path: `/area/${id}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Area, kursadmin
     * @name UpdateAreaAsync
     * @request PUT:/area/{id}
     */
    updateAreaAsync: (id: number, data: ApiUpsertAreaDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/area/${id}`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Area, kursadmin, minside
     * @name GetLocationsAsync
     * @request GET:/area/{id}/location
     */
    getLocationsAsync: (id: number, params: RequestParams = {}) =>
      this.request<ApiGetLocationDto[], any>({
        path: `/area/${id}/location`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Area, kursadmin
     * @name CreateLocationAsync
     * @request POST:/area/{id}/location
     */
    createLocationAsync: (id: number, data: ApiUpsertLocationDto, params: RequestParams = {}) =>
      this.request<ApiInt32IdDto, any>({
        path: `/area/${id}/location`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Area, kursadmin, minside
     * @name GetLocationAsync
     * @request GET:/area/{id}/location/{locationId}
     */
    getLocationAsync: (id: number, locationId: number, params: RequestParams = {}) =>
      this.request<ApiGetLocationDto, any>({
        path: `/area/${id}/location/${locationId}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Area, kursadmin
     * @name UpdateLocationAsync
     * @request PUT:/area/{id}/location/{locationId}
     */
    updateLocationAsync: (id: number, locationId: number, data: ApiUpsertLocationDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/area/${id}/location/${locationId}`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Area, kursadmin, minside
     * @name GetRoomAsync
     * @request GET:/area/{id}/location/{locationId}/room/{roomId}
     */
    getRoomAsync: (id: number, locationId: number, roomId: number, params: RequestParams = {}) =>
      this.request<ApiGetRoomDto, any>({
        path: `/area/${id}/location/${locationId}/room/${roomId}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Area, kursadmin
     * @name UpdateRoomAsync
     * @request PUT:/area/{id}/location/{locationId}/room/{roomId}
     */
    updateRoomAsync: (
      id: number,
      locationId: number,
      roomId: number,
      data: ApiUpsertRoomDto,
      params: RequestParams = {}
    ) =>
      this.request<void, any>({
        path: `/area/${id}/location/${locationId}/room/${roomId}`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Area, kursadmin, minside
     * @name CreateRoomAsync
     * @request POST:/area/{id}/location/{locationId}/room
     */
    createRoomAsync: (id: number, locationId: number, data: ApiUpsertRoomDto, params: RequestParams = {}) =>
      this.request<ApiInt32IdDto, any>({
        path: `/area/${id}/location/${locationId}/room`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  clientaccountrate = {
    /**
     * No description
     *
     * @tags ClientAccountRate, kursadmin
     * @name GetClientAccountRateOverviewAsync
     * @summary Get list of years with client account rates and whether the year is active or not
     * @request GET:/clientaccountrate
     */
    getClientAccountRateOverviewAsync: (params: RequestParams = {}) =>
      this.request<ApiGetClientAccountRateOverviewDto[], any>({
        path: `/clientaccountrate`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * @description Creates client account rates for a new year as a copy of a previous year
     *
     * @tags ClientAccountRate, kursadmin
     * @name CreateClientAccountRatesAsync
     * @summary Creates client account rates for a new year as a copy of a previous year
     * @request POST:/clientaccountrate
     */
    createClientAccountRatesAsync: (query?: { year?: number; copyFromYear?: number }, params: RequestParams = {}) =>
      this.request<void, string[]>({
        path: `/clientaccountrate`,
        method: "POST",
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ClientAccountRate, kursadmin
     * @name GetClientAccountRatesAsync
     * @summary Get client account rates for a specific year
     * @request GET:/clientaccountrate/{year}
     */
    getClientAccountRatesAsync: (year: number, params: RequestParams = {}) =>
      this.request<ApiGetClientAccountRateDto[], void>({
        path: `/clientaccountrate/${year}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * @description Update client account rates
     *
     * @tags ClientAccountRate, kursadmin
     * @name UpdateClientAccountRatesAsync
     * @summary Update client account rates for a specific year and member organization
     * @request PUT:/clientaccountrate/{year}/{memberOrganizationId}
     */
    updateClientAccountRatesAsync: (
      year: number,
      memberOrganizationId: number,
      data: ApiUpdateClientAccountRateDto,
      params: RequestParams = {}
    ) =>
      this.request<void, ApiUpdateClientAccountRateDtoValidationResponseDto | void>({
        path: `/clientaccountrate/${year}/${memberOrganizationId}`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        ...params,
      }),
  };
  communicationtemplate = {
    /**
     * No description
     *
     * @tags CommunicationTemplate, kursadmin
     * @name GetCommunicationTemplatesAsync
     * @request GET:/communicationtemplate
     */
    getCommunicationTemplatesAsync: (
      query?: { Search?: string; CommunicationTemplateGroupIds?: number[]; PageNumber?: number; PageSize?: number },
      params: RequestParams = {}
    ) =>
      this.request<ApiGetCommunicationTemplateDto[], any>({
        path: `/communicationtemplate`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags CommunicationTemplate, kursadmin
     * @name CreateCommunicationTemplateAsync
     * @request POST:/communicationtemplate
     */
    createCommunicationTemplateAsync: (data: ApiCreateCommunicationTemplateDto, params: RequestParams = {}) =>
      this.request<ApiInt32IdDto, string[]>({
        path: `/communicationtemplate`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags CommunicationTemplate, kursadmin
     * @name GetCommunicationTemplateByIdAsync
     * @request GET:/communicationtemplate/{id}
     */
    getCommunicationTemplateByIdAsync: (id: number, params: RequestParams = {}) =>
      this.request<ApiGetCommunicationTemplateDto, void>({
        path: `/communicationtemplate/${id}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags CommunicationTemplate, kursadmin
     * @name UpdateCommunicationTemplateAsync
     * @request PUT:/communicationtemplate/{id}
     */
    updateCommunicationTemplateAsync: (
      id: number,
      data: ApiUpdateCommunicationTemplateDto,
      params: RequestParams = {}
    ) =>
      this.request<void, string[] | void>({
        path: `/communicationtemplate/${id}`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags CommunicationTemplate, kursadmin
     * @name DeleteCommunicationTemplateAsync
     * @request DELETE:/communicationtemplate/{id}
     */
    deleteCommunicationTemplateAsync: (id: number, params: RequestParams = {}) =>
      this.request<void, string[] | void>({
        path: `/communicationtemplate/${id}`,
        method: "DELETE",
        ...params,
      }),
  };
  communicationtemplategroup = {
    /**
     * No description
     *
     * @tags CommunicationTemplateGroup, kursadmin
     * @name GetCommunicationTemplateGroupsAsync
     * @request GET:/communicationtemplategroup
     */
    getCommunicationTemplateGroupsAsync: (
      query?: { Name?: string; Types?: string[]; PageNumber?: number; PageSize?: number },
      params: RequestParams = {}
    ) =>
      this.request<ApiGetCommunicationTemplateGroupDto[], any>({
        path: `/communicationtemplategroup`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags CommunicationTemplateGroup, kursadmin
     * @name GetCommunicationTemplateGroupByIdAsync
     * @request GET:/communicationtemplategroup/{id}
     */
    getCommunicationTemplateGroupByIdAsync: (id: number, params: RequestParams = {}) =>
      this.request<ApiGetCommunicationTemplateGroupDto, void>({
        path: `/communicationtemplategroup/${id}`,
        method: "GET",
        format: "json",
        ...params,
      }),
  };
  economy = {
    /**
     * No description
     *
     * @tags Cost, kursadmin
     * @name GetCourseEconomyBaseAsync
     * @request GET:/economy/defaultoption
     */
    getCourseEconomyBaseAsync: (params: RequestParams = {}) =>
      this.request<ApiCourseEconomyBaseDataDto, any>({
        path: `/economy/defaultoption`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Cost, kursadmin
     * @name GetCostsPerOrganizationAsync
     * @request GET:/economy/cost
     */
    getCostsPerOrganizationAsync: (
      query?: { CostTypeCostGroup?: string; PageNumber?: number; PageSize?: number },
      params: RequestParams = {}
    ) =>
      this.request<ApiGetCostDto[], any>({
        path: `/economy/cost`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Cost, kursadmin
     * @name CreateCostAsync
     * @request POST:/economy/cost
     */
    createCostAsync: (data: ApiCreateCostDto, params: RequestParams = {}) =>
      this.request<ApiInt32IdDto, any>({
        path: `/economy/cost`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Cost, kursadmin
     * @name GetCostAsync
     * @request GET:/economy/cost/{id}
     */
    getCostAsync: (id: number, params: RequestParams = {}) =>
      this.request<ApiGetCostDto, any>({
        path: `/economy/cost/${id}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Cost, kursadmin
     * @name UpdateCostAsync
     * @request PUT:/economy/cost/{id}
     */
    updateCostAsync: (id: number, data: ApiUpdateCostDto, params: RequestParams = {}) =>
      this.request<ApiInt32IdDto, any>({
        path: `/economy/cost/${id}`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Cost, kursadmin, minside
     * @name GetCostTypesAsync
     * @request GET:/economy/costtype
     */
    getCostTypesAsync: (params: RequestParams = {}) =>
      this.request<ApiGetCostTypeDto[], any>({
        path: `/economy/costtype`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Cost, kursadmin
     * @name GetContractsAsync
     * @request GET:/economy/course/{courseId}/contract
     */
    getContractsAsync: (
      courseId: number,
      query?: { Statuses?: string[]; PageNumber?: number; PageSize?: number },
      params: RequestParams = {}
    ) =>
      this.request<ApiGetContractSimpleDto[], any>({
        path: `/economy/course/${courseId}/contract`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Cost, kursadmin
     * @name GetContractsForCourseAndUserAsync
     * @request GET:/economy/course/{courseId}/user/{userId}/contract
     */
    getContractsForCourseAndUserAsync: (
      courseId: number,
      userId: number,
      query?: { Statuses?: string[]; PageNumber?: number; PageSize?: number },
      params: RequestParams = {}
    ) =>
      this.request<ApiGetContractWithFileDto[], any>({
        path: `/economy/course/${courseId}/user/${userId}/contract`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Cost, kursadmin
     * @name CreateContractAsync
     * @request POST:/economy/course/{courseId}/user/{userId}/contract
     */
    createContractAsync: (courseId: number, userId: number, data: ApiCreateContractDto, params: RequestParams = {}) =>
      this.request<ApiInt32IdDto, any>({
        path: `/economy/course/${courseId}/user/${userId}/contract`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Cost, kursadmin, minside
     * @name GetContractAsync
     * @request GET:/economy/contract/{id}
     */
    getContractAsync: (id: number, params: RequestParams = {}) =>
      this.request<ApiGetContractWithFileDto, any>({
        path: `/economy/contract/${id}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Cost, kursadmin
     * @name UpdateContractAsync
     * @request PUT:/economy/contract/{id}
     */
    updateContractAsync: (id: number, data: ApiUpdateContractDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/economy/contract/${id}`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Cost, kursadmin
     * @name SendContractForSigningAsync
     * @request POST:/economy/contract/{id}/send-for-signing
     */
    sendContractForSigningAsync: (id: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/economy/contract/${id}/send-for-signing`,
        method: "POST",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Cost, kursadmin
     * @name GetWorkingHourAsync
     * @request GET:/economy/workinghour/{id}
     */
    getWorkingHourAsync: (id: number, params: RequestParams = {}) =>
      this.request<ApiGetWorkingHourDto, void>({
        path: `/economy/workinghour/${id}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Cost, kursadmin
     * @name GetWorkingHoursAsync
     * @request GET:/economy/workinghour
     */
    getWorkingHoursAsync: (
      query?: {
        OnlySupervisedCourses?: boolean;
        UserId?: number;
        WorkingHourUserIdFilter?: ApiWorkingHourUserIdFilter;
        CourseId?: number;
        Statuses?: string[];
        FromDate?: string;
        ToDate?: string;
        IsSelfEmployed?: boolean;
        PageNumber?: number;
        PageSize?: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<ApiGetWorkingHourDto[], any>({
        path: `/economy/workinghour`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Cost, kursadmin
     * @name ApproveWorkingHourAsync
     * @request POST:/economy/workinghour/approve
     */
    approveWorkingHourAsync: (data: number[], params: RequestParams = {}) =>
      this.request<void, string[]>({
        path: `/economy/workinghour/approve`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Cost, kursadmin
     * @name UnapproveWorkingHourAsync
     * @request POST:/economy/workinghour/unapprove
     */
    unapproveWorkingHourAsync: (data: number[], params: RequestParams = {}) =>
      this.request<void, string[]>({
        path: `/economy/workinghour/unapprove`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Cost, kursadmin
     * @name DeclineWorkingHourAsync
     * @request POST:/economy/workinghour/decline
     */
    declineWorkingHourAsync: (data: number[], params: RequestParams = {}) =>
      this.request<void, string[]>({
        path: `/economy/workinghour/decline`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Cost, kursadmin
     * @name SetPaymentPendingWorkingHourAsync
     * @request POST:/economy/workinghour/setpaymentpending
     */
    setPaymentPendingWorkingHourAsync: (data: number[], params: RequestParams = {}) =>
      this.request<void, string[]>({
        path: `/economy/workinghour/setpaymentpending`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Grant, kursadmin
     * @name GetGrantRates
     * @request GET:/economy/grant/grantrate
     */
    getGrantRates: (params: RequestParams = {}) =>
      this.request<ApiGetGrantRateDto[], any>({
        path: `/economy/grant/grantrate`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Grant, kursadmin
     * @name CreateGrantRate
     * @request POST:/economy/grant/grantrate/{year}
     */
    createGrantRate: (year: number, data: ApiUpsertGrantRateDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/economy/grant/grantrate/${year}`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Grant, kursadmin
     * @name UpdateGrantRate
     * @request PUT:/economy/grant/grantrate/{year}
     */
    updateGrantRate: (year: number, data: ApiUpsertGrantRateDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/economy/grant/grantrate/${year}`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        ...params,
      }),
  };
  extended = {
    /**
     * No description
     *
     * @tags Course, kursadmin
     * @name UpdateCourseExtendedAsync
     * @request PUT:/extended/course/{id}
     */
    updateCourseExtendedAsync: (id: number, data: ApiUpdateCourseExtendedDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/extended/course/${id}`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        ...params,
      }),
  };
  coursearea = {
    /**
     * No description
     *
     * @tags CourseArea, kursadmin, minside
     * @name GetCourseAreasAsync
     * @request GET:/coursearea
     */
    getCourseAreasAsync: (params: RequestParams = {}) =>
      this.request<ApiHierarchicalAreaDtoType[], any>({
        path: `/coursearea`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags CourseArea, kursadmin
     * @name CreateCourseAreaAsync
     * @request POST:/coursearea
     */
    createCourseAreaAsync: (data: ApiCreateCourseAreaDto, params: RequestParams = {}) =>
      this.request<ApiInt32IdDto, any>({
        path: `/coursearea`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags CourseArea, kursadmin, minside
     * @name GetCourseAreaAsync
     * @request GET:/coursearea/{id}
     */
    getCourseAreaAsync: (id: number, params: RequestParams = {}) =>
      this.request<ApiGetCourseAreaDto, any>({
        path: `/coursearea/${id}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags CourseArea, kursadmin
     * @name UpdateCourseAreaAsync
     * @request PUT:/coursearea/{id}
     */
    updateCourseAreaAsync: (id: number, data: ApiUpdateCourseAreaDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/coursearea/${id}`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        ...params,
      }),
  };
  courseparticipantexpense = {
    /**
     * @description Retrieve a list of stored CourseParticipantExpenses that matches the given parameters.
     *
     * @tags CourseParticipantExpense, kursadmin
     * @name GetCourseParticipantExpenseAsync
     * @request GET:/courseparticipantexpense
     */
    getCourseParticipantExpenseAsync: (
      query?: {
        CourseId?: number;
        UserId?: number;
        RoleNames?: string[];
        Statuses?: string[];
        IsUserActive?: boolean;
        OnlySupervisedCourses?: boolean;
        FromDate?: string;
        ToDate?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<ApiGetExpenseDto[], any>({
        path: `/courseparticipantexpense`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * @description Get one CourseParticipantExpense.
     *
     * @tags CourseParticipantExpense, kursadmin
     * @name GetKursadminCourseParticipantExpense
     * @request GET:/courseparticipantexpense/{id}
     */
    getKursadminCourseParticipantExpense: (id: number, params: RequestParams = {}) =>
      this.request<ApiGetExpenseDto, any>({
        path: `/courseparticipantexpense/${id}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * @description Update one CourseParticipantExpense, the expense must not have status ORDERCREATED.
     *
     * @tags CourseParticipantExpense, kursadmin
     * @name UpdateCourseParticipantExpenseDraft
     * @request PUT:/courseparticipantexpense/{id}
     */
    updateCourseParticipantExpenseDraft: (id: number, data: ApiUpdateExpenseKursadminDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/courseparticipantexpense/${id}`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Delete one CourseParticipantExpense, the expense must have status DRAFT.
     *
     * @tags CourseParticipantExpense, kursadmin
     * @name DeleteCourseParticipantExpenseDraft
     * @request DELETE:/courseparticipantexpense/{id}
     */
    deleteCourseParticipantExpenseDraft: (id: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/courseparticipantexpense/${id}`,
        method: "DELETE",
        ...params,
      }),

    /**
     * @description Creates an order from a CourseParticipantExpense, the expense must have status VALIDATED.
     *
     * @tags CourseParticipantExpense, kursadmin
     * @name CreateOrderFromCourseParticipantExpense
     * @request POST:/courseparticipantexpense/{id}/createorder
     */
    createOrderFromCourseParticipantExpense: (id: number, params: RequestParams = {}) =>
      this.request<ApiInt32IdDto, any>({
        path: `/courseparticipantexpense/${id}/createorder`,
        method: "POST",
        format: "json",
        ...params,
      }),
  };
  courseparticipantstatus = {
    /**
     * @description Retrieve a list of user's CourseParticipantStatus.
     *
     * @tags CourseParticipantStatus, kursadmin
     * @name GetCourseParticipantStatusesByUserIdAsync
     * @request GET:/courseparticipantstatus/{userId}
     */
    getCourseParticipantStatusesByUserIdAsync: (userId: number, params: RequestParams = {}) =>
      this.request<ApiGetCourseParticipantStatusDto[], void>({
        path: `/courseparticipantstatus/${userId}`,
        method: "GET",
        format: "json",
        ...params,
      }),
  };
  curriculum = {
    /**
     * No description
     *
     * @tags Curriculum, minside, kursadmin
     * @name GetCurriculumDefaultoptionsAsync
     * @request GET:/curriculum/defaultoptions
     */
    getCurriculumDefaultoptionsAsync: (params: RequestParams = {}) =>
      this.request<ApiGetCurriculumDefaultOptionDto, any>({
        path: `/curriculum/defaultoptions`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Curriculum, kursadmin
     * @name GetCurriculumAsync
     * @request GET:/curriculum/{id}
     */
    getCurriculumAsync: (id: number, params: RequestParams = {}) =>
      this.request<ApiGetCurriculumDto, any>({
        path: `/curriculum/${id}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Curriculum, kursadmin
     * @name UpdateCurriculumAsync
     * @request PUT:/curriculum/{id}
     */
    updateCurriculumAsync: (id: number, data: ApiUpsertCurriculumDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/curriculum/${id}`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Curriculum, kursadmin
     * @name DeleteCurriculumAsync
     * @request DELETE:/curriculum/{id}
     */
    deleteCurriculumAsync: (id: number, query?: { statusId?: number }, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/curriculum/${id}`,
        method: "DELETE",
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Curriculum, kursadmin
     * @name GetCoursesByCurriculumIdAsync
     * @request GET:/curriculum/{id}/course
     */
    getCoursesByCurriculumIdAsync: (
      id: number,
      query?: { PageNumber?: number; PageSize?: number },
      params: RequestParams = {}
    ) =>
      this.request<ApiGetShortCourseDto[], any>({
        path: `/curriculum/${id}/course`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Curriculum, kursadmin
     * @name GetCurriculaAsync
     * @request GET:/curriculum
     */
    getCurriculaAsync: (
      query?: {
        MainCourseId?: number;
        SubCourseId?: number;
        InsertedBy?: number;
        OnlyActiveMainAndSubCourses?: boolean;
        TeachingMethod?: string;
        StatusIds?: number[];
        PageNumber?: number;
        PageSize?: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<ApiGetShortCurriculumDto[], any>({
        path: `/curriculum`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Curriculum, kursadmin
     * @name CreateCurriculumAsync
     * @request POST:/curriculum
     */
    createCurriculumAsync: (data: ApiUpsertCurriculumDto, params: RequestParams = {}) =>
      this.request<ApiInt32IdDto, any>({
        path: `/curriculum`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Curriculum, kursadmin
     * @name ApproveCurriculumAsync
     * @request PUT:/curriculum/{id}/status
     */
    approveCurriculumAsync: (id: number, data: ApiUpsertCurriculumStatusDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/curriculum/${id}/status`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Curriculum, kursadmin
     * @name GetExecutionPlanAsync
     * @request GET:/curriculum/{id}/executionplan
     */
    getExecutionPlanAsync: (id: number, params: RequestParams = {}) =>
      this.request<ApiGetExecutionPlanDto, any>({
        path: `/curriculum/${id}/executionplan`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Curriculum, kursadmin
     * @name UpsertExecutionPlanAsync
     * @request POST:/curriculum/{id}/executionplan
     */
    upsertExecutionPlanAsync: (id: number, data: ApiExecutionPlanDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/curriculum/${id}/executionplan`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Curriculum, kursadmin
     * @name DeleteExecutionPlanAsync
     * @request DELETE:/curriculum/{id}/executionplan
     */
    deleteExecutionPlanAsync: (id: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/curriculum/${id}/executionplan`,
        method: "DELETE",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Curriculum, kursadmin
     * @name GetDraftsPerUserAsync
     * @request GET:/curriculum/draft
     */
    getDraftsPerUserAsync: (params: RequestParams = {}) =>
      this.request<ApiUpsertCurriculumDto[], any>({
        path: `/curriculum/draft`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Curriculum, kursadmin
     * @name CreateDraftAsync
     * @request POST:/curriculum/draft
     */
    createDraftAsync: (data: ApiUpsertCurriculumDto, params: RequestParams = {}) =>
      this.request<ApiGuidIdDto, any>({
        path: `/curriculum/draft`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Curriculum, kursadmin
     * @name GetDraftPerIdAndUserId
     * @request GET:/curriculum/draft/{id}
     */
    getDraftPerIdAndUserId: (id: string, params: RequestParams = {}) =>
      this.request<ApiUpsertCurriculumDto, any>({
        path: `/curriculum/draft/${id}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Curriculum, kursadmin
     * @name UpdateDraftAsync
     * @request PUT:/curriculum/draft/{id}
     */
    updateDraftAsync: (id: string, data: ApiUpsertCurriculumDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/curriculum/draft/${id}`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Curriculum, kursadmin
     * @name DeleteDraftAsync
     * @request DELETE:/curriculum/draft/{id}
     */
    deleteDraftAsync: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/curriculum/draft/${id}`,
        method: "DELETE",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Curriculum, kursadmin
     * @name CreateNusCodeAsync
     * @request POST:/curriculum/nuscode
     */
    createNusCodeAsync: (data: ApiInsertNusCodeDto, params: RequestParams = {}) =>
      this.request<ApiGuidIdDto, string[]>({
        path: `/curriculum/nuscode`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Curriculum, kursadmin
     * @name GetNusCodes
     * @request GET:/curriculum/nuscode
     */
    getNusCodes: (query?: { orgId?: number; PageNumber?: number; PageSize?: number }, params: RequestParams = {}) =>
      this.request<ApiGetNusCodeDto[], any>({
        path: `/curriculum/nuscode`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Curriculum, kursadmin
     * @name UpdateNusCodeAsync
     * @request PUT:/curriculum/nuscode/{code}
     */
    updateNusCodeAsync: (code: string, data: ApiUpdateNusCodeDto, params: RequestParams = {}) =>
      this.request<ApiGuidIdDto, string[] | void>({
        path: `/curriculum/nuscode/${code}`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  customer = {
    /**
     * No description
     *
     * @tags Customer, kursadmin
     * @name GetCustomerPersonsAsync
     * @request GET:/customer/person
     */
    getCustomerPersonsAsync: (
      query?: {
        FirstName?: string;
        LastName?: string;
        Email?: string;
        MobileNumber?: string;
        Types?: string[];
        IsCourseParticipant?: boolean;
        PageNumber?: number;
        PageSize?: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<ApiGetCustomerDto[], any>({
        path: `/customer/person`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Customer, kursadmin
     * @name GetCustomerOrganizationsAsync
     * @request GET:/customer/organization
     */
    getCustomerOrganizationsAsync: (
      query?: { Name?: string; Orgno?: string; Email?: string; PageNumber?: number; PageSize?: number },
      params: RequestParams = {}
    ) =>
      this.request<ApiGetCustomerDto[], any>({
        path: `/customer/organization`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Customer, kursadmin
     * @name CreateCustomerOrganizationAsync
     * @request POST:/customer/organization
     */
    createCustomerOrganizationAsync: (data: ApiUpsertCustomerOrgDto, params: RequestParams = {}) =>
      this.request<ApiInt32IdDto, any>({
        path: `/customer/organization`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Customer, kursadmin
     * @name GetCustomerOrgByIdAsync
     * @request GET:/customer/organization/{id}
     */
    getCustomerOrgByIdAsync: (id: number, params: RequestParams = {}) =>
      this.request<ApiGetCustomerDto, any>({
        path: `/customer/organization/${id}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Customer, kursadmin
     * @name UpdateOrganizationAsync
     * @request PUT:/customer/organization/{id}
     */
    updateOrganizationAsync: (id: number, data: ApiUpsertCustomerOrgDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/customer/organization/${id}`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Customer, kursadmin
     * @name GetCustomerPersonByIdAsync
     * @request GET:/customer/person/{id}
     */
    getCustomerPersonByIdAsync: (id: number, params: RequestParams = {}) =>
      this.request<ApiGetCustomerDto, any>({
        path: `/customer/person/${id}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Customer, kursadmin
     * @name UpdateCustomerPersonEmailAsync
     * @request POST:/customer/customer/person/{id}/email
     */
    updateCustomerPersonEmailAsync: (id: number, query?: { emailAddress?: string }, params: RequestParams = {}) =>
      this.request<void, string[]>({
        path: `/customer/customer/person/${id}/email`,
        method: "POST",
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Customer, kursadmin, minside
     * @name VerifyContactInfoForPersonAsync
     * @request POST:/customer/person/{id}/verify-contact-info
     */
    verifyContactInfoForPersonAsync: (id: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/customer/person/${id}/verify-contact-info`,
        method: "POST",
        ...params,
      }),
  };
  dashboard = {
    /**
     * No description
     *
     * @tags Dashboard, kursadmin
     * @name GetDashboardSelfAsync
     * @request GET:/dashboard/self
     */
    getDashboardSelfAsync: (query?: { getCurriculumForApprovalCount?: boolean }, params: RequestParams = {}) =>
      this.request<ApiGetDashboardDto, any>({
        path: `/dashboard/self`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Dashboard, kursadmin
     * @name GetDashboardAsync
     * @summary Get dashboard for a user without department access control
     * @request GET:/dashboard
     */
    getDashboardAsync: (
      query?: {
        userId?: number;
        courseResponsibleFilter?: ApiCourseResponsibleFilter;
        getCurriculumForApprovalCount?: boolean;
      },
      params: RequestParams = {}
    ) =>
      this.request<ApiGetDashboardDto, any>({
        path: `/dashboard`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Dashboard, kursadmin
     * @name GetUnreadCourseApplications
     * @request GET:/dashboard/participant/unread
     */
    getUnreadCourseApplications: (
      query?: { userId?: number; courseId?: number; courseName?: string; areaId?: number; areaName?: string },
      params: RequestParams = {}
    ) =>
      this.request<ApiGetUnreadCourseApplicationsDto[], any>({
        path: `/dashboard/participant/unread`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Dashboard, kursadmin
     * @name MarkCourseApplicationsAsReadAsync
     * @request POST:/dashboard/participant/markasread
     */
    markCourseApplicationsAsReadAsync: (data: ApiMarkCourseApplicationsAsReadDto[], params: RequestParams = {}) =>
      this.request<ApiInt64IdDto, string[]>({
        path: `/dashboard/participant/markasread`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Dashboard, kursadmin
     * @name GetCoursesWithNewDocuments
     * @request GET:/dashboard/newdocument
     */
    getCoursesWithNewDocuments: (
      query?: {
        CourseIds?: number[];
        CourseNames?: string[];
        AreaLocations?: string[];
        PageNumber?: number;
        PageSize?: number;
        userId?: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<ApiGetCoursesAndNewDocumentsDto[], any>({
        path: `/dashboard/newdocument`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Dashboard, kursadmin
     * @name MarkNewDocumentsAsRead
     * @request PUT:/dashboard/newdocument/markasread
     */
    markNewDocumentsAsRead: (data: ApiPostNewDocumentsReadStatusDto[], params: RequestParams = {}) =>
      this.request<ApiInt64IdDto, string[]>({
        path: `/dashboard/newdocument/markasread`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  erp = {
    /**
     * No description
     *
     * @tags Erp, kursadmin
     * @name GetErpEmployees
     * @request GET:/erp/employee
     */
    getErpEmployees: (params: RequestParams = {}) =>
      this.request<ApiErpEmployeeDtoType[], any>({
        path: `/erp/employee`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Erp, kursadmin
     * @name GetPayrollEmployees
     * @request GET:/erp/employee/payroll
     */
    getPayrollEmployees: (params: RequestParams = {}) =>
      this.request<ApiErpEmployeeDtoType[], any>({
        path: `/erp/employee/payroll`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Erp, kursadmin
     * @name ImportErpEmployee
     * @request POST:/erp/employee/{employeeNo}
     */
    importErpEmployee: (employeeNo: number, params: RequestParams = {}) =>
      this.request<ApiInt32IdDto, any>({
        path: `/erp/employee/${employeeNo}`,
        method: "POST",
        format: "json",
        ...params,
      }),
  };
  user = {
    /**
     * No description
     *
     * @tags Erp, kursadmin
     * @name SyncErpPayrollEmployee
     * @request POST:/user/{userId}/erp/employee/payroll/sync
     */
    syncErpPayrollEmployee: (userId: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/user/${userId}/erp/employee/payroll/sync`,
        method: "POST",
        ...params,
      }),

    /**
     * No description
     *
     * @tags User, kursadmin
     * @name GetUserAsync
     * @request GET:/user/{id}
     */
    getUserAsync: (id: number, params: RequestParams = {}) =>
      this.request<ApiGetUserDto, any>({
        path: `/user/${id}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags User, kursadmin
     * @name GetEmployeeAsync
     * @request GET:/user/employee/{id}
     */
    getEmployeeAsync: (id: number, params: RequestParams = {}) =>
      this.request<ApiGetCurrentEmployeeDto, any>({
        path: `/user/employee/${id}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags User, kursadmin
     * @name GetEmployeesAsync
     * @request GET:/user/employee
     */
    getEmployeesAsync: (
      query?: { FirstName?: string; LastName?: string; Email?: string; PageNumber?: number; PageSize?: number },
      params: RequestParams = {}
    ) =>
      this.request<ApiGetEmployeeDto[], any>({
        path: `/user/employee`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags User, kursadmin
     * @name GetGuestEmployeesAsync
     * @request GET:/user/employee/guest
     */
    getGuestEmployeesAsync: (params: RequestParams = {}) =>
      this.request<ApiGetGuestEmployeeDto[], any>({
        path: `/user/employee/guest`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags User, kursadmin
     * @name GetCurrentEmployeeAsync
     * @request GET:/user/employee/self
     */
    getCurrentEmployeeAsync: (params: RequestParams = {}) =>
      this.request<ApiGetCurrentEmployeeDto, any>({
        path: `/user/employee/self`,
        method: "GET",
        format: "json",
        ...params,
      }),
  };
  file = {
    /**
     * No description
     *
     * @tags File, kursadmin
     * @name GetFileDefaultOption
     * @request GET:/file/defaultoptions
     */
    getFileDefaultOption: (params: RequestParams = {}) =>
      this.request<ApiGetFileDefaultOptionDto, any>({
        path: `/file/defaultoptions`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags File, minside, kursadmin
     * @name GetMinSideFileDefaultOption
     * @request GET:/file/minside/defaultoptions
     */
    getMinSideFileDefaultOption: (params: RequestParams = {}) =>
      this.request<ApiGetFileDefaultOptionDto, any>({
        path: `/file/minside/defaultoptions`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags File, minside, kursadmin
     * @name DownloadFileAsync
     * @request GET:/file/{id}
     */
    downloadFileAsync: (id: number, params: RequestParams = {}) =>
      this.request<File, any>({
        path: `/file/${id}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags File, kursadmin
     * @name DeleteFileAsync
     * @request DELETE:/file/{id}
     */
    deleteFileAsync: (id: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/file/${id}`,
        method: "DELETE",
        ...params,
      }),

    /**
     * No description
     *
     * @tags File, kursadmin
     * @name UpdateFileCategoryAsync
     * @request PUT:/file/{id}/category
     */
    updateFileCategoryAsync: (id: number, data: ApiUpdateFileCategoryDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/file/${id}/category`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags File, minside, kursadmin
     * @name GetCurriculumFilesAsync
     * @request GET:/file/curriculum/{curriculumId}
     */
    getCurriculumFilesAsync: (curriculumId: number, params: RequestParams = {}) =>
      this.request<ApiGetCurriculumFileDto[], any>({
        path: `/file/curriculum/${curriculumId}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags File, kursadmin
     * @name CreateCurriculumFileAsync
     * @request POST:/file/curriculum/{curriculumId}
     */
    createCurriculumFileAsync: (
      curriculumId: number,
      data: { Category?: string; File?: File; Description?: string },
      params: RequestParams = {}
    ) =>
      this.request<ApiInt64IdDto, any>({
        path: `/file/curriculum/${curriculumId}`,
        method: "POST",
        body: data,
        type: ContentType.FormData,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags File, kursadmin
     * @name GetCourseFilesAsync
     * @request GET:/file/course/{courseId}
     */
    getCourseFilesAsync: (courseId: number, params: RequestParams = {}) =>
      this.request<ApiGetCourseRelatedFileDto[], any>({
        path: `/file/course/${courseId}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags File, kursadmin
     * @name CreateCourseFileAsync
     * @request POST:/file/course/{courseId}
     */
    createCourseFileAsync: (
      courseId: number,
      data: { Category?: string; File?: File; Description?: string },
      params: RequestParams = {}
    ) =>
      this.request<ApiInt64IdDto, any>({
        path: `/file/course/${courseId}`,
        method: "POST",
        body: data,
        type: ContentType.FormData,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags File, kursadmin
     * @name CreateFacilitationGrantApplicationFileAsync
     * @request POST:/file/course/{courseId}/facilitationgrantapplication
     */
    createFacilitationGrantApplicationFileAsync: (
      courseId: number,
      data: { Category?: string; File?: File; Description?: string },
      params: RequestParams = {}
    ) =>
      this.request<ApiInt64IdDto, any>({
        path: `/file/course/${courseId}/facilitationgrantapplication`,
        method: "POST",
        body: data,
        type: ContentType.FormData,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags File, kursadmin
     * @name GetFacilitationGrantApplicationFilesAsync
     * @request GET:/file/course/{courseId}/facilitationgrantapplication
     */
    getFacilitationGrantApplicationFilesAsync: (courseId: number, params: RequestParams = {}) =>
      this.request<ApiGetCourseRelatedFileDto[], any>({
        path: `/file/course/${courseId}/facilitationgrantapplication`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags File, kursadmin
     * @name CreateCourseParticipantFileByIdAsync
     * @request POST:/file/course/{courseId}/participant/{userId}
     */
    createCourseParticipantFileByIdAsync: (
      courseId: number,
      userId: number,
      data: { Category?: string; File?: File; Description?: string },
      params: RequestParams = {}
    ) =>
      this.request<ApiInt64IdDto, any>({
        path: `/file/course/${courseId}/participant/${userId}`,
        method: "POST",
        body: data,
        type: ContentType.FormData,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags File, kursadmin
     * @name GetCourseRegulationsFilesAsync
     * @request GET:/file/courseregulation
     */
    getCourseRegulationsFilesAsync: (params: RequestParams = {}) =>
      this.request<ApiGetCourseRegulationFileDto[], any>({
        path: `/file/courseregulation`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags File, kursadmin
     * @name CreateCourseRegulationsFileAsync
     * @request POST:/file/courseregulation
     */
    createCourseRegulationsFileAsync: (data: { File?: File; Description?: string }, params: RequestParams = {}) =>
      this.request<ApiInt64IdDto, any>({
        path: `/file/courseregulation`,
        method: "POST",
        body: data,
        type: ContentType.FormData,
        format: "json",
        ...params,
      }),
  };
  holiday = {
    /**
     * No description
     *
     * @tags Holiday, kursadmin
     * @name GetHolidayAsync
     * @request GET:/holiday/{id}
     */
    getHolidayAsync: (id: number, params: RequestParams = {}) =>
      this.request<ApiGetHolidayDto, any>({
        path: `/holiday/${id}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Holiday, kursadmin
     * @name UpdateHolidayAsync
     * @request PUT:/holiday/{id}
     */
    updateHolidayAsync: (id: number, data: ApiUpdateHolidayDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/holiday/${id}`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Holiday, kursadmin
     * @name DeleteHolidayAsync
     * @request DELETE:/holiday/{id}
     */
    deleteHolidayAsync: (id: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/holiday/${id}`,
        method: "DELETE",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Holiday, kursadmin
     * @name GetHolidaysAsync
     * @request GET:/holiday
     */
    getHolidaysAsync: (query?: { PageNumber?: number; PageSize?: number }, params: RequestParams = {}) =>
      this.request<ApiGetHolidayDto[], any>({
        path: `/holiday`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Holiday, kursadmin
     * @name CreateHolidayAsync
     * @request POST:/holiday
     */
    createHolidayAsync: (data: ApiCreateHolidayDto, params: RequestParams = {}) =>
      this.request<ApiInt32IdDto, any>({
        path: `/holiday`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  health = {
    /**
     * No description
     *
     * @tags Info, kursadmin, minside
     * @name Info
     * @request GET:/health/info
     */
    info: (params: RequestParams = {}) =>
      this.request<ApiBuildinfo, any>({
        path: `/health/info`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Ping, kursadmin, minside
     * @name Ping
     * @request GET:/health/ping
     */
    ping: (params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/health/ping`,
        method: "GET",
        format: "json",
        ...params,
      }),
  };
  messaging = {
    /**
     * No description
     *
     * @tags Messaging, kursadmin
     * @name GetMessagingDefaultOptions
     * @request GET:/messaging/defaultoptions
     */
    getMessagingDefaultOptions: (params: RequestParams = {}) =>
      this.request<ApiGetMessagingDefaultOptionDto, any>({
        path: `/messaging/defaultoptions`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Messaging, kursadmin
     * @name SendMessageFromKursAdminAsync
     * @request POST:/messaging/kursadmin
     */
    sendMessageFromKursAdminAsync: (
      data: { CourseId?: number; RecipientIds?: number[]; Subject?: string; Text?: string; Attachments?: File[] },
      params: RequestParams = {}
    ) =>
      this.request<ApiInt64IdDto, any>({
        path: `/messaging/kursadmin`,
        method: "POST",
        body: data,
        type: ContentType.FormData,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Messaging, minside, kursadmin
     * @name ReplyTo
     * @request POST:/messaging/reply
     */
    replyTo: (
      data: { ReplyTo?: number; Subject?: string; Text?: string; Attachments?: File[] },
      params: RequestParams = {}
    ) =>
      this.request<ApiInt64IdDto, any>({
        path: `/messaging/reply`,
        method: "POST",
        body: data,
        type: ContentType.FormData,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Messaging, kursadmin
     * @name SearchMessagesKursadmin
     * @request GET:/messaging/kursadmin/search
     */
    searchMessagesKursadmin: (
      query?: {
        OnlySupervisedCourses?: boolean;
        CourseResponsibleFilter?: ApiCourseResponsibleFilter;
        UserId?: number;
        Sender?: boolean;
        IsRead?: boolean;
        SentFromDate?: string;
        SentToDate?: string;
        CourseId?: number;
        PageNumber?: number;
        PageSize?: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<ApiGetMessageResultDtoPagedResult, any>({
        path: `/messaging/kursadmin/search`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Messaging, kursadmin
     * @name GetNumberOfUnreadMessagesCourseAdmin
     * @request GET:/messaging/kursadmin/unreadcount
     */
    getNumberOfUnreadMessagesCourseAdmin: (params: RequestParams = {}) =>
      this.request<number, any>({
        path: `/messaging/kursadmin/unreadcount`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Messaging, kursadmin
     * @name SetReadKursadmin
     * @request PUT:/messaging/kursadmin/{id}/read
     */
    setReadKursadmin: (id: number, data: ApiSetMessageReadStatusDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/messaging/kursadmin/${id}/read`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Messaging, kursadmin
     * @name GetMessageByIdKursAdmin
     * @request GET:/messaging/kursadmin/{id}
     */
    getMessageByIdKursAdmin: (id: number, params: RequestParams = {}) =>
      this.request<ApiGetMessageResultDto, any>({
        path: `/messaging/kursadmin/${id}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Messaging, kursadmin
     * @name SendSmsMessageFromKursAdminAsync
     * @request POST:/messaging/kursadmin/sms
     */
    sendSmsMessageFromKursAdminAsync: (data: ApiSendSmsMessageDto, params: RequestParams = {}) =>
      this.request<ApiInt32IdDto, any>({
        path: `/messaging/kursadmin/sms`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Messaging, kursadmin
     * @name GetSmsMessagesKursadmin
     * @request GET:/messaging/kursadmin/sms/list
     */
    getSmsMessagesKursadmin: (query?: { CourseId?: number }, params: RequestParams = {}) =>
      this.request<ApiGetSmsResultDto[], any>({
        path: `/messaging/kursadmin/sms/list`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),
  };
  order = {
    /**
     * @description For participants. Generates one or more order templates that can later be inserted via POST. Does not insert anything to the database.
     *
     * @tags Order, kursadmin
     * @name GetTemplates
     * @request POST:/order/generate-templates
     */
    getTemplates: (data: ApiGenerateTemplateDto[], params: RequestParams = {}) =>
      this.request<ApiOrderTemplateDto[], any>({
        path: `/order/generate-templates`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description For participants. Generates one or more order templates that can later be inserted via POST. Does not insert anything to the database.
     *
     * @tags Order, kursadmin
     * @name GetTemplatesParticipant
     * @request POST:/order/generate-templates/participant
     */
    getTemplatesParticipant: (data: ApiGenerateParticipantOrderTemplateDto[], params: RequestParams = {}) =>
      this.request<ApiOrderTemplateDto[], any>({
        path: `/order/generate-templates/participant`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description For organizations. Generates an order template that can later be inserted via POST. Does not insert anything to the database.
     *
     * @tags Order, kursadmin
     * @name GetTemplatesOrganization
     * @request POST:/order/generate-templates/organization
     */
    getTemplatesOrganization: (data: ApiGenerateOrganizationOrderTemplateDto, params: RequestParams = {}) =>
      this.request<ApiOrderTemplateDto, any>({
        path: `/order/generate-templates/organization`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Retrieve a single stored order.
     *
     * @tags Order, kursadmin
     * @name GetOrderAsync
     * @request GET:/order/{id}
     */
    getOrderAsync: (id: number, params: RequestParams = {}) =>
      this.request<ApiGetOrderDto, any>({
        path: `/order/${id}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * @description Update one order, the order must have status DRAFT.
     *
     * @tags Order, kursadmin
     * @name UpdateDraft
     * @request PUT:/order/{id}
     */
    updateDraft: (id: number, data: ApiUpdateDraftDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/order/${id}`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Deletes one order, the order must have status DRAFT.
     *
     * @tags Order, kursadmin
     * @name Delete
     * @request DELETE:/order/{id}
     */
    delete: (id: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/order/${id}`,
        method: "DELETE",
        ...params,
      }),

    /**
     * @description Insert one order into the database, with status DRAFT.
     *
     * @tags Order, kursadmin
     * @name CreateDraft
     * @request POST:/order
     */
    createDraft: (data: ApiCreateDraftDto, params: RequestParams = {}) =>
      this.request<ApiInt32IdDto, any>({
        path: `/order`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Queues one order for sending, the order must have status DRAFT or FAILEDQUEUEING. If queued successfully, the status of the order is then set to QUEUED. Normally, the order is then sent by the function app, and the order status is SENT.
     *
     * @tags Order, kursadmin
     * @name Queue
     * @request POST:/order/{id}/queue
     */
    queue: (id: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/order/${id}/queue`,
        method: "POST",
        ...params,
      }),

    /**
     * @description Cancels one order, the order must not have status SENT. The status of the order is then set to DRAFT.
     *
     * @tags Order, kursadmin
     * @name Cancel
     * @request POST:/order/{id}/cancel
     */
    cancel: (id: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/order/${id}/cancel`,
        method: "POST",
        ...params,
      }),

    /**
     * @description Creates a contra entry order of the given order. A contra entry order is an order negating the order given. It is equal to the given order, except with opposite quantity. The given order must have status SENT or FAILEDORDER (otherwise, one could simply cancel it).
     *
     * @tags Order, kursadmin
     * @name CreateContraEntry
     * @request POST:/order/{id}/contra
     */
    createContraEntry: (id: number, params: RequestParams = {}) =>
      this.request<ApiInt32IdDto, any>({
        path: `/order/${id}/contra`,
        method: "POST",
        format: "json",
        ...params,
      }),
  };
  organization = {
    /**
     * No description
     *
     * @tags Organization, kursadmin, minside
     * @name GetDepartmentsAsync
     * @request GET:/organization/department
     */
    getDepartmentsAsync: (params: RequestParams = {}) =>
      this.request<ApiGetDepartmentDto[], any>({
        path: `/organization/department`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Organization, kursadmin, minside
     * @name GetMemberOrganizationsAsync
     * @request GET:/organization/member
     */
    getMemberOrganizationsAsync: (params: RequestParams = {}) =>
      this.request<ApiGetMemberOrganizationDto[], any>({
        path: `/organization/member`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Organization, kursadmin
     * @name PostMemberOrganizationsAsync
     * @request POST:/organization/member
     */
    postMemberOrganizationsAsync: (data: ApiCreateMemberOrganizationDto, params: RequestParams = {}) =>
      this.request<ApiInt32IdDto, any>({
        path: `/organization/member`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Organization, kursadmin, minside
     * @name GetMainOfficesMemberOrganizationAsync
     * @request GET:/organization/member/mainoffice
     */
    getMainOfficesMemberOrganizationAsync: (params: RequestParams = {}) =>
      this.request<ApiGetMemberOrganizationDto[], any>({
        path: `/organization/member/mainoffice`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Organization, kursadmin
     * @name GetMemberOrganizationAsync
     * @request GET:/organization/{id}/member
     */
    getMemberOrganizationAsync: (id: number, params: RequestParams = {}) =>
      this.request<ApiGetMemberOrganizationDto, void>({
        path: `/organization/${id}/member`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Organization, kursadmin
     * @name UpdateMemberOrganizationsAsync
     * @request PUT:/organization/{id}/member
     */
    updateMemberOrganizationsAsync: (id: number, data: ApiUpdateMemberOrganizationDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/organization/${id}/member`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Organization, kursadmin, minside
     * @name GetMemberOrganizationChilOfficesAsync
     * @request GET:/organization/{id}/member/regionaloffice
     */
    getMemberOrganizationChilOfficesAsync: (id: number, params: RequestParams = {}) =>
      this.request<ApiGetMemberOrganizationDto[], void>({
        path: `/organization/${id}/member/regionaloffice`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Organization, kursadmin
     * @name DeactivateMemberOrganizationsAsync
     * @request POST:/organization/{id}/member/deactivate
     */
    deactivateMemberOrganizationsAsync: (id: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/organization/${id}/member/deactivate`,
        method: "POST",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Organization, kursadmin
     * @name ActivateMemberOrganizationsAsync
     * @request POST:/organization/{id}/member/activate
     */
    activateMemberOrganizationsAsync: (id: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/organization/${id}/member/activate`,
        method: "POST",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Organization, kursadmin
     * @name PostMemberOrganizationUserAsync
     * @request POST:/organization/{id}/member/user
     */
    postMemberOrganizationUserAsync: (
      id: number,
      data: ApiUpsertMemberOrganizationUserDto,
      params: RequestParams = {}
    ) =>
      this.request<ApiInt32IdDto, any>({
        path: `/organization/${id}/member/user`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Organization, kursadmin
     * @name DeleteMemberOrganizationUser
     * @request DELETE:/organization/{orgId}/member/user/{userId}
     */
    deleteMemberOrganizationUser: (orgId: number, userId: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/organization/${orgId}/member/user/${userId}`,
        method: "DELETE",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Organization, kursadmin
     * @name GetMemberOrganizationUsers
     * @request GET:/organization/{orgId}/member/user
     */
    getMemberOrganizationUsers: (orgId: number, params: RequestParams = {}) =>
      this.request<ApiGetMemberOrganizationUserDto[], any>({
        path: `/organization/${orgId}/member/user`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Organization, kursadmin
     * @name GetPotentialOrganizerOrganizations
     * @request GET:/organization
     */
    getPotentialOrganizerOrganizations: (query?: { isOrganizer?: boolean }, params: RequestParams = {}) =>
      this.request<ApiOrganizationDtoType[], any>({
        path: `/organization`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),
  };
  role = {
    /**
     * No description
     *
     * @tags Role, kursadmin, minside
     * @name GetRolesAsync
     * @request GET:/role
     */
    getRolesAsync: (params: RequestParams = {}) =>
      this.request<ApiRole[], any>({
        path: `/role`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Role, kursadmin
     * @name CreateRoleAsync
     * @request POST:/role
     */
    createRoleAsync: (data: ApiCreateRoleDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/role`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Role, kursadmin, minside
     * @name GetRoleByNameAsync
     * @request GET:/role/{name}
     */
    getRoleByNameAsync: (name: string, params: RequestParams = {}) =>
      this.request<ApiGetRoleDto, any>({
        path: `/role/${name}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Role, kursadmin
     * @name UpdateRoleAsync
     * @request PUT:/role/{name}
     */
    updateRoleAsync: (name: string, data: ApiUpdateRoleDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/role/${name}`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        ...params,
      }),
  };
  schedule = {
    /**
     * @description Validates the schedules based on the input data
     *
     * @tags Schedule, kursadmin
     * @name ValidateSchedulesAsync
     * @request POST:/schedule/validate
     */
    validateSchedulesAsync: (data: ApiValidateSchedulesDto, params: RequestParams = {}) =>
      this.request<void, ApiScheduleBaseDtoValidationResponseDto>({
        path: `/schedule/validate`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),
  };
  ssb = {
    /**
     * No description
     *
     * @tags Ssb, kursadmin, minside
     * @name GetCountriesAsync
     * @request GET:/ssb/country
     */
    getCountriesAsync: (query?: { PageNumber?: number; PageSize?: number }, params: RequestParams = {}) =>
      this.request<ApiSsbCountryDto[], any>({
        path: `/ssb/country`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Ssb, kursadmin, minside
     * @name GetSubjectsAsync
     * @request GET:/ssb/subject
     */
    getSubjectsAsync: (query?: { PageNumber?: number; PageSize?: number }, params: RequestParams = {}) =>
      this.request<ApiSsbSubjectDto[], any>({
        path: `/ssb/subject`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),
  };
  studylocation = {
    /**
     * No description
     *
     * @tags StudyLocation, kursadmin
     * @name GetStudyLocationsPerOrganizationAsync
     * @request GET:/studylocation
     */
    getStudyLocationsPerOrganizationAsync: (params: RequestParams = {}) =>
      this.request<ApiGetStudyLocationDto[], any>({
        path: `/studylocation`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags StudyLocation, kursadmin
     * @name CreateStudyLocationAsync
     * @request POST:/studylocation
     */
    createStudyLocationAsync: (data: ApiUpsertStudyLocationDto, params: RequestParams = {}) =>
      this.request<ApiInt32IdDto, any>({
        path: `/studylocation`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags StudyLocation, kursadmin
     * @name UpdateStudyLocationAsync
     * @request PUT:/studylocation/{id}
     */
    updateStudyLocationAsync: (id: number, data: ApiUpsertStudyLocationDto, params: RequestParams = {}) =>
      this.request<ApiInt32IdDto, any>({
        path: `/studylocation/${id}`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  teachingmaterial = {
    /**
     * No description
     *
     * @tags TeachingMaterial, kursadmin, minside
     * @name GetTeachingMaterialsAsync
     * @request GET:/teachingmaterial
     */
    getTeachingMaterialsAsync: (params: RequestParams = {}) =>
      this.request<ApiGetTeachingMaterialDto[], any>({
        path: `/teachingmaterial`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags TeachingMaterial, kursadmin
     * @name CreateTeachingMaterialAsync
     * @request POST:/teachingmaterial
     */
    createTeachingMaterialAsync: (data: ApiUpsertTeachingMaterialDto, params: RequestParams = {}) =>
      this.request<ApiInt32IdDto, any>({
        path: `/teachingmaterial`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags TeachingMaterial, kursadmin, minside
     * @name GetTeachingMaterialAsync
     * @request GET:/teachingmaterial/{id}
     */
    getTeachingMaterialAsync: (id: number, params: RequestParams = {}) =>
      this.request<ApiGetTeachingMaterialDto, any>({
        path: `/teachingmaterial/${id}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags TeachingMaterial, kursadmin
     * @name UpdateTeachingMaterialAsync
     * @request PUT:/teachingmaterial/{id}
     */
    updateTeachingMaterialAsync: (id: number, data: ApiUpsertTeachingMaterialDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/teachingmaterial/${id}`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        ...params,
      }),
  };
  teachingmaterialcategory = {
    /**
     * No description
     *
     * @tags TeachingMaterialCategory, kursadmin, minside
     * @name GetTeachingMaterialCategoryAsync
     * @request GET:/teachingmaterialcategory
     */
    getTeachingMaterialCategoryAsync: (params: RequestParams = {}) =>
      this.request<ApiGetTeachingMaterialCategoryDto[], any>({
        path: `/teachingmaterialcategory`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags TeachingMaterialCategory, kursadmin
     * @name CreateTeachingMaterialCategoryAsync
     * @request POST:/teachingmaterialcategory
     */
    createTeachingMaterialCategoryAsync: (data: ApiCreateTeachingMaterialCategoryDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/teachingmaterialcategory`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags TeachingMaterialCategory, kursadmin
     * @name UpdateTeachingMaterialCategoryAsync
     * @request PUT:/teachingmaterialcategory/{name}
     */
    updateTeachingMaterialCategoryAsync: (
      name: string,
      data: ApiUpdateTeachingMaterialCategoryDto,
      params: RequestParams = {}
    ) =>
      this.request<void, any>({
        path: `/teachingmaterialcategory/${name}`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        ...params,
      }),
  };
  testtype = {
    /**
     * No description
     *
     * @tags TestType, kursadmin, minside
     * @name GetTestTypesAsync
     * @request GET:/testtype
     */
    getTestTypesAsync: (params: RequestParams = {}) =>
      this.request<ApiGetTestTypeDto[], any>({
        path: `/testtype`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags TestType, kursadmin
     * @name CreateTestTypeAsync
     * @request POST:/testtype
     */
    createTestTypeAsync: (data: ApiUpsertTestTypeDto, params: RequestParams = {}) =>
      this.request<ApiInt32IdDto, any>({
        path: `/testtype`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags TestType, kursadmin, minside
     * @name GetTestTypeIdAsync
     * @request GET:/testtype/{id}
     */
    getTestTypeIdAsync: (id: number, params: RequestParams = {}) =>
      this.request<ApiGetTestTypeDto, any>({
        path: `/testtype/${id}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags TestType, kursadmin
     * @name UpdateTestType
     * @request PUT:/testtype/{id}
     */
    updateTestType: (id: number, data: ApiUpsertTestTypeDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/testtype/${id}`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        ...params,
      }),
  };
  guestside = {
    /**
     * No description
     *
     * @tags GuestUserCourse, guestuser
     * @name GetGuestUserCourseDefaultOptions
     * @request GET:/guestside/defaultoptions
     */
    getGuestUserCourseDefaultOptions: (params: RequestParams = {}) =>
      this.request<ApiGetCourseDefaultOptionDto, any>({
        path: `/guestside/defaultoptions`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags GuestUserCourse, guestuser
     * @name GetGuestUserCourses
     * @request GET:/guestside/course
     */
    getGuestUserCourses: (params: RequestParams = {}) =>
      this.request<ApiGetShortCourseWithParticipantsDto[], any>({
        path: `/guestside/course`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags GuestUserCourse, guestuser
     * @name GetGuestUserCourseByIdFilteredByOrganizationAsync
     * @request GET:/guestside/course/{id}
     */
    getGuestUserCourseByIdFilteredByOrganizationAsync: (id: number, params: RequestParams = {}) =>
      this.request<ApiGetKursAdminCourseDto, any>({
        path: `/guestside/course/${id}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags GuestUserCourse, guestuser
     * @name GetGuestUserCourseParticipantsByCourseIdAsync
     * @request GET:/guestside/course/{id}/participant
     */
    getGuestUserCourseParticipantsByCourseIdAsync: (
      id: number,
      query?: { RoleNames?: string[]; Statuses?: string[]; IsActive?: boolean },
      params: RequestParams = {}
    ) =>
      this.request<ApiGetCourseParticipantKursAdminDto[], any>({
        path: `/guestside/course/${id}/participant`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags GuestUserCourse, guestuser
     * @name GetGuestUserCourseParticipantHistory
     * @request GET:/guestside/participant/{userId}/history
     */
    getGuestUserCourseParticipantHistory: (userId: number, params: RequestParams = {}) =>
      this.request<ApiGetCourseParticipantDtoType[], any>({
        path: `/guestside/participant/${userId}/history`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags GuestUserCourse, guestuser
     * @name GetGuestCourseParticipantAppFromResponse
     * @request GET:/guestside/course/{courseId}/participant/{userId}/application
     */
    getGuestCourseParticipantAppFromResponse: (courseId: number, userId: number, params: RequestParams = {}) =>
      this.request<ApiGetApplicationFormResponseDto, any>({
        path: `/guestside/course/${courseId}/participant/${userId}/application`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags GuestUserCourse, guestuser
     * @name PutGuestUserCourseParticipantApplicationStatus
     * @request POST:/guestside/course/{courseId}/participant/{userId}/status
     */
    putGuestUserCourseParticipantApplicationStatus: (
      courseId: number,
      userId: number,
      data: ApiGuestEmployeeCourseParticipantStatusDto,
      params: RequestParams = {}
    ) =>
      this.request<void, any>({
        path: `/guestside/course/${courseId}/participant/${userId}/status`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags GuestUserCourse, guestuser
     * @name GetCourseParticipantAttendances
     * @request GET:/guestside/course/{courseId}/attendance
     */
    getCourseParticipantAttendances: (
      courseId: number,
      query?: {
        ScheduleStart?: string;
        UserId?: number;
        UserFullName?: string;
        IsRegistered?: boolean;
        IsSetByKursAdmin?: boolean;
      },
      params: RequestParams = {}
    ) =>
      this.request<ApiGetCourseParticipantAttendanceDto[], string[] | void>({
        path: `/guestside/course/${courseId}/attendance`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags GuestUserCourse, guestuser
     * @name GetGuestUserCustomerPersonsAsync
     * @request GET:/guestside/customer/person
     */
    getGuestUserCustomerPersonsAsync: (
      query?: {
        FirstName?: string;
        LastName?: string;
        Email?: string;
        MobileNumber?: string;
        Types?: string[];
        IsCourseParticipant?: boolean;
        PageNumber?: number;
        PageSize?: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<ApiGetCustomerPersonDto[], any>({
        path: `/guestside/customer/person`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags GuestUserCourse, guestuser
     * @name GetGuestUserCustomerPersonByIdAsync
     * @request GET:/guestside/customer/person/{id}
     */
    getGuestUserCustomerPersonByIdAsync: (id: number, params: RequestParams = {}) =>
      this.request<ApiGetCustomerDto, any>({
        path: `/guestside/customer/person/${id}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags GuestUserCourse, guestuser
     * @name GetGuestUserDepartmentsAsync
     * @request GET:/guestside/organization/department
     */
    getGuestUserDepartmentsAsync: (params: RequestParams = {}) =>
      this.request<ApiGetDepartmentDto[], any>({
        path: `/guestside/organization/department`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags GuestUserCourse, guestuser
     * @name GetGuestUserMemberOrganizations
     * @request GET:/guestside/organization/member
     */
    getGuestUserMemberOrganizations: (params: RequestParams = {}) =>
      this.request<ApiGetMemberOrganizationDto[], any>({
        path: `/guestside/organization/member`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags GuestUserCourse, guestuser
     * @name GetGuestUserAreaByIdAsync
     * @request GET:/guestside/area/{id}
     */
    getGuestUserAreaByIdAsync: (id: number, params: RequestParams = {}) =>
      this.request<ApiGetAreaDto, any>({
        path: `/guestside/area/${id}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags GuestUserCourse, guestuser
     * @name GetGuestUserCurriculumAsync
     * @request GET:/guestside/curriculum/{id}
     */
    getGuestUserCurriculumAsync: (id: number, params: RequestParams = {}) =>
      this.request<ApiGetCurriculumDto, any>({
        path: `/guestside/curriculum/${id}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags GuestUserCourse, guestuser
     * @name GetGuestUserExecutionPlanAsync
     * @request GET:/guestside/curriculum/{id}/executionplan
     */
    getGuestUserExecutionPlanAsync: (id: number, params: RequestParams = {}) =>
      this.request<ApiGetExecutionPlanDto, any>({
        path: `/guestside/curriculum/${id}/executionplan`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags GuestUserCourse, guestuser
     * @name GetGuestUserAppFormTemplateAsync
     * @request GET:/guestside/appformtemplate/{id}
     */
    getGuestUserAppFormTemplateAsync: (id: string, params: RequestParams = {}) =>
      this.request<ApiGetApplicationFormTemplateDto, any>({
        path: `/guestside/appformtemplate/${id}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags GuestUserCourse, guestuser
     * @name GetGuestUserCurrentGuestEmployeeAsync
     * @request GET:/guestside/employee/guest/self
     */
    getGuestUserCurrentGuestEmployeeAsync: (params: RequestParams = {}) =>
      this.request<ApiGetGuestEmployeewithMemberOrganizationDto, any>({
        path: `/guestside/employee/guest/self`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags GuestUserCourse, guestuser
     * @name GetDashboardSelfAsync1
     * @request GET:/guestside/dashboard
     */
    getDashboardSelfAsync1: (params: RequestParams = {}) =>
      this.request<ApiGetGuestUserDashboardDto, any>({
        path: `/guestside/dashboard`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags GuestUserCourse, guestuser
     * @name GetCourseApplications
     * @request GET:/guestside/dashboard/participant/applications
     */
    getCourseApplications: (params: RequestParams = {}) =>
      this.request<ApiGetUnreadCourseApplicationsDto[], any>({
        path: `/guestside/dashboard/participant/applications`,
        method: "GET",
        format: "json",
        ...params,
      }),
  };
}
