import { AdminRouteNames } from "@/shared/enums/RouteNames/adminRouteNamesEnum";
export const AdminRouteList = [
  {
    path: "admin/teachingmaterial",
    name: AdminRouteNames.TeachingMaterial,
    component: () => import(/* webpackChunkName: "TeachingMaterialPage" */ "@/pages/admin/TeachingMaterialPage.vue"),
  },
  {
    path: "admin/teachingmaterial/categories",
    name: AdminRouteNames.TeachingAidCategories,
    component: () =>
      import(
        /* webpackChunkName: "TeachingMaterialCatogoriesPage" */ "@/pages/admin/TeachingMaterialCatogoriesPage.vue"
      ),
  },
  {
    path: "admin/resourceRoles",
    name: AdminRouteNames.ResourceRoles,
    component: () => import(/* webpackChunkName: "ResourceRolesPage" */ "@/pages/admin/ResourceRolesPage.vue"),
  },
  {
    path: "admin/areas",
    name: AdminRouteNames.Areas,
    component: () => import(/* webpackChunkName: "AreaPage" */ "@/pages/admin/AreaPage.vue"),
  },
  {
    path: "admin/memberOrganizations",
    name: AdminRouteNames.MemberOrganizations,
    component: () =>
      import(/* webpackChunkName: "MemberOrganizationPage" */ "@/pages/admin/MemberOrganizationPage.vue"),
  },
  {
    path: "admin/mainOffice/:mainOfficeId",
    name: AdminRouteNames.MainOffice,
    component: () => import(/* webpackChunkName: "MainOfficePage" */ "@/pages/admin/MainOfficePage.vue"),
  },
  {
    path: "admin/mainOffice/:mainOfficeId/regionOffice/:regionOfficeId",
    name: AdminRouteNames.RegionOffice,
    component: () => import(/* webpackChunkName: "RegionOfficePage" */ "@/pages/admin/RegionOfficePage.vue"),
  },
  {
    path: "admin/area/:areaId/locations",
    name: AdminRouteNames.Locations,
    component: () => import(/* webpackChunkName: "LocationPage" */ "@/pages/admin/LocationPage.vue"),
  },
  {
    path: "admin/area/:areaId/location/:locationId",
    name: AdminRouteNames.Rooms,
    component: () => import(/* webpackChunkName: "RoomPage" */ "@/pages/admin/RoomPage.vue"),
  },
  {
    path: "admin/applicationform",
    name: AdminRouteNames.ApplicationsList,
    component: () => import(/* webpackChunkName: "ApplicationFormPage" */ "@/pages/admin/ApplicationFormPage.vue"),
  },
  {
    path: "admin/applicationform/create",
    name: AdminRouteNames.ApplicationsCreate,
    component: () =>
      import(
        /* webpackChunkName: "ApplicationFormDisplayCreatePage" */ "@/pages/admin/ApplicationFormDisplayCreatePage.vue"
      ),
  },
  {
    path: "admin/applicationform/view/:applicationId",
    name: AdminRouteNames.ApplicationsView,
    component: () =>
      import(
        /* webpackChunkName: "ApplicationFormDisplayCreatePage" */ "@/pages/admin/ApplicationFormDisplayCreatePage.vue"
      ),
  },
  {
    path: "admin/testtypes",
    name: AdminRouteNames.TestTypes,
    component: () => import(/* webpackChunkName: "TestTypePage" */ "@/pages/admin/TestTypePage.vue"),
  },
  {
    path: "admin/vacationSchedule",
    name: AdminRouteNames.VacationSchedule,
    component: () => import(/* webpackChunkName: "VacationSchedulePage" */ "@/pages/admin/VacationSchedulePage.vue"),
  },
  {
    path: "admin/subjectRegister",
    name: AdminRouteNames.SubjectRegister,
    component: () => import(/* webpackChunkName: "SubjectRegisterPage" */ "@/pages/admin/SubjectRegisterPage.vue"),
  },
  {
    path: "admin/salaryCosts",
    name: AdminRouteNames.SalaryCosts,
    component: () => import(/* webpackChunkName: "SalaryCostPage" */ "@/pages/admin/SalaryCostPage.vue"),
  },
  {
    path: "admin/expenses",
    name: AdminRouteNames.Expenses,
    component: () => import(/* webpackChunkName: "ExpensesPage" */ "@/pages/admin/ExpensesPage.vue"),
  },
  {
    path: "admin/trainingGrants",
    name: AdminRouteNames.TrainingGrants,
    component: () => import(/* webpackChunkName: "TrainingGrantsPage" */ "@/pages/admin/TrainingGrantsPage.vue"),
  },
  {
    path: "admin/approvedWorkingHours",
    name: AdminRouteNames.ApprovedWorkingHours,
    component: () =>
      import(/* webpackChunkName: "ApprovedWorkingHoursPage" */ "@/pages/admin/ApprovedWorkingHoursPage.vue"),
  },
  {
    path: "admin/communicationTemplates",
    name: AdminRouteNames.CommunicationTemplateList,
    component: () =>
      import(/* webpackChunkName: "CommunicationTemplateListPage" */ "@/pages/admin/CommunicationTemplateListPage.vue"),
  },
  {
    path: "admin/communicationTemplate/:communicationTemplateId",
    name: AdminRouteNames.CommunicationTemplate,
    component: () =>
      import(/* webpackChunkName: "CommunicationTemplatePage" */ "@/pages/admin/CommunicationTemplatePage.vue"),
  },
  {
    path: "admin/studyLocationRegister",
    name: AdminRouteNames.StudyLocationRegister,
    component: () =>
      import(/* webpackChunkName: "StudyLocationRegisterPage" */ "@/pages/admin/StudyLocationRegisterPage.vue"),
  },
  {
    path: "admin/nusCodeRegister",
    name: AdminRouteNames.NusCodeRegister,
    component: () => import(/* webpackChunkName: "NusCodesPage" */ "@/pages/admin/NusCodesPage.vue"),
  },
  {
    path: "admin/rateRegisterList",
    name: AdminRouteNames.RateRegisterList,
    component: () => import(/* webpackChunkName: "RateRegisterPage" */ "@/pages/admin/RateRegisterListPage.vue"),
  },
  {
    path: "admin/rateRegister/:rateRegisterYear",
    name: AdminRouteNames.RateRegister,
    component: () => import(/* webpackChunkName: "RateRegisterPage" */ "@/pages/admin/RateRegisterPage.vue"),
  },
];
