import { authService } from "@/auth/authService";
import { EnvironmentVariables } from "@/environment";
import router from "@/router/router";
import { HttpErrorMessages } from "@/shared/constants/httpErrorMessages";
import { NotificationItemType } from "@/shared/enums/notificationItemEnum";
import { openNotification } from "@/shared/helpers/store.helpers";
import rootStore from "@/store/root.store";
import { StoreState } from "@/store/store.state.interface";
import axios, { AxiosError, AxiosRequestConfig } from "axios";
import { Store } from "vuex";
import { UserConstants } from "./../shared/constants/userConstants";

export interface HttpClientProps {
  config?: AxiosRequestConfig;
  addDepartmentInterceptor?: boolean;
}

export const getHttpClient = ({ config = {}, addDepartmentInterceptor = true }: HttpClientProps = {}) => {
  const httpClient = axios.create({
    baseURL: EnvironmentVariables.VUE_APP_API_URL,
    timeout: EnvironmentVariables.HTTP_TIMEOUT, // ms
    headers: {
      "Content-Type": "application/json",
      accept: "application/json",
    },
    ...config,
    paramsSerializer: (params: Record<string, string | string[]>) =>
      // forcing proper format for arrays like: IsActive=true&RoleNames=Student&RoleNames=greger
      formatRequestParams(params),
  });

  httpClient.interceptors.request.use(authInterceptor);
  httpClient.interceptors.request.use(baseUrlInterceptor);
  // success response just passed directly
  httpClient.interceptors.response.use((response) => response, errorInterceptor);

  if (addDepartmentInterceptor) {
    httpClient.interceptors.request.use(departmentInterceptor);
  }

  return httpClient;
};

const formatRequestParams = (params: Record<string, string | string[]>) => {
  const urlParam = new URLSearchParams();
  for (const property in params) {
    const value = params[property];
    if (value === undefined) {
      // for undefined just skip
      continue;
    }
    if (Array.isArray(value)) {
      value.forEach((element: string) => {
        urlParam.append(property, element);
      });
    } else {
      urlParam.append(property, value);
    }
  }
  return urlParam.toString();
};

const departmentInterceptor = async (config: AxiosRequestConfig) => {
  if (authService.canImpersonateDepartment()) {
    config.headers["Org-ID"] = window.sessionStorage.getItem(UserConstants.USER_DEPARTMENT_ID);
  }
  return config;
};

const isGuestUser = (url?: string) => url?.includes("guestside");
const authInterceptor = async (config: AxiosRequestConfig) => {
  const isUsingCypress = sessionStorage.getItem("cypress-token");

  let token: string | null | undefined = "";
  if (isUsingCypress) {
    token = sessionStorage.getItem("cypress-token");
  } else {
    token = await authService.acquireTokenSilent();
  }

  config.headers["Authorization"] = `Bearer ${token}`;
  config.headers["Ocp-Apim-Subscription-Key"] = isGuestUser(config.url)
    ? EnvironmentVariables.VUE_APP_API_OCP_KEY_GUEST
    : EnvironmentVariables.VUE_APP_API_OCP_KEY;
  return config;
};

const baseUrlInterceptor = async (config: AxiosRequestConfig) => {
  isGuestUser(config.url)
    ? (config.baseURL = `${EnvironmentVariables.VUE_APP_API_URL}/guestuser`)
    : (config.baseURL = `${EnvironmentVariables.VUE_APP_API_URL}/kursadmin`);
  return config;
};

const displaySimpleValidationErrors = (store: Store<StoreState>, responseData: any) => {
  if (Array.isArray(responseData)) {
    responseData.forEach((errorItem: any) => {
      if (typeof errorItem === "string") {
        openNotification(store, NotificationItemType.Warning, errorItem);
      }
    });
  } else if (typeof responseData === "string") {
    openNotification(store, NotificationItemType.Warning, responseData);
  }
};

// interceptor to catch errors
const errorInterceptor = (error: AxiosError) => {
  const store = rootStore as unknown as Store<StoreState>;
  if (!error.response) {
    return;
  }
  let errorMessage: string = HttpErrorMessages.errorGeneralMessage;
  if (error.response.status >= 500) {
    errorMessage = HttpErrorMessages.errorGeneralMessage;
  } else {
    switch (error.response.status) {
      case 302: {
        errorMessage = HttpErrorMessages.error302Message;
        break;
      }
      case 400:
        errorMessage = HttpErrorMessages.error400Message;
        if (error.response.data) {
          displaySimpleValidationErrors(store, error.response.data);
        }
        break;
      case 401: // authentication error, logout the user
        errorMessage = HttpErrorMessages.error401Message;
        router.push("/login");
        break;
      case 404: {
        errorMessage = HttpErrorMessages.error404Message;
        break;
      }
      default:
        errorMessage = `${HttpErrorMessages.errorGeneralMessage} (${error.response.status})`;
    }
  }

  openNotification(store, NotificationItemType.Error, errorMessage);
  return Promise.reject(error);
};

const defaultHttpClient = getHttpClient();
export const httpClient = defaultHttpClient;
