export enum AdminRouteNames {
  TeachingMaterial = "teachingMaterial",
  TeachingAidCategories = "teachingAidCategories",
  ResourceRoles = "resourceRoles",
  Areas = "areas",
  MemberOrganizations = "memberOrganizations",
  MainOffice = "mainOffice",
  RegionOffice = "regionOffice",
  Locations = "locations",
  Rooms = "rooms",
  ApplicationsList = "applicationsList",
  ApplicationsCreate = "applicationsCreate",
  ApplicationsView = "applicationsView",
  TestTypes = "testTypes",
  VacationSchedule = "vacationSchedule",
  SubjectRegister = "subjectRegister",
  Expenses = "expenses",
  SalaryCosts = "salaryCosts",
  TrainingGrants = "trainingGrants",
  ApprovedWorkingHours = "approvedWorkingHours",
  CommunicationTemplateList = "communicationTemplateList",
  CommunicationTemplate = "communicationTemplate",
  StudyLocationRegister = "studyLocationRegister",
  NusCodeRegister = "nusCodeRegister",
  RateRegisterList = "rateRegisterList",
  RateRegister = "rateRegister",
}
