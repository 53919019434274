
























































import TableFilter from "@/components/shell/TableFilter.vue";
import { hasMemberOrgAccess } from "@/shared/helpers/accessLevelApiAdapter";
import {
  adminItems,
  contactItems,
  courseItems,
  dashboardItems,
  MenuItem,
  studyplanItems,
  watchRoutePathImmediate,
} from "@/shared/helpers/shellHelpers";
import { useRouter, useStore } from "@/shared/useHelpers";
import { StoreState } from "@/store/store.state.interface";
import { defineComponent, ref } from "@vue/composition-api";

interface DrawerItems {
  title: string;
  icon: string;
  items: MenuItem[];
  active: boolean;
  isEnabledForMemberOrganizations?: boolean;
}

export default defineComponent({
  name: "TheCollapsableDrawer",
  components: { TableFilter },
  setup() {
    const drawerOpen = ref(false);
    const items = ref<DrawerItems[]>([]);
    const router = useRouter();
    const store = useStore<StoreState>();

    const updateMenuItems = async (routePath?: string) => {
      if (!routePath) {
        items.value = [];
      }

      items.value = [
        {
          title: "Dashboard",
          icon: "mdi-home-city",
          items: dashboardItems(router),
          active: routePath?.includes("/home") ? true : false,
          isEnabledForMemberOrganizations: true,
        },
        {
          title: "Studieplaner",
          icon: "mdi-file-document-multiple-outline",
          items: studyplanItems(router),
          active: routePath?.includes("/studieplaner") ? true : false,
        },
        {
          title: "Kurs",
          icon: "mdi-school-outline",
          items: await courseItems(store, routePath || "", +router.currentRoute.params.id, router),
          active: routePath?.includes("/kurs") ? true : false,
          isEnabledForMemberOrganizations: true,
        },
        {
          title: "Adressebok",
          icon: "mdi-book-account-outline",
          items: contactItems(router),
          active: routePath?.includes("/adressebok") ? true : false,
        },
        {
          title: "Administrasjon",
          icon: "mdi-cog",
          items: adminItems(router),
          active: routePath?.includes("/admin") ? true : false,
        },
      ].filter((current) => (hasMemberOrgAccess ? current.isEnabledForMemberOrganizations : true));
    };

    watchRoutePathImmediate(updateMenuItems);

    return { drawerOpen, items };
  },
});
