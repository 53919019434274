var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ShellMenu',{key:"profile-menu",attrs:{"close-on-content-click":false,"max-width":_vm.maxWidth,"bottom":"","content-class":"overflow-hidden","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c('BaseTooltipButton',_vm._g(_vm._b({attrs:{"path":"Profil"},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('v-avatar',{attrs:{"color":"primary","rounded":"","size":"36"}},[_c('span',{staticClass:"white--text"},[_vm._v(_vm._s(_vm.userInitials))])])]},proxy:true}],null,true)},'BaseTooltipButton',attrs,false),on))]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-toolbar',{staticClass:"pr-5",attrs:{"width":_vm.maxWidth,"flat":"","short":""}},[_vm._v(" "+_vm._s(_vm.userEmail)+" ")]),_c('v-divider'),(_vm.showPreFillSwitch)?_c('v-switch',{staticClass:"px-3",attrs:{"value":_vm.usePreFilledValues,"label":"Pre fill values"},on:{"change":_vm.updatePreFilledValue}}):_vm._e(),_c('v-divider'),_vm._l((_vm.menuItems),function(ref){
var action = ref.action;
var title = ref.title;
var icon = ref.icon;
return _c('v-list-item',{key:title,on:{"click":action}},[_c('v-list-item-title',{staticClass:"text-subtitle-1",domProps:{"textContent":_vm._s(title)}}),_c('v-spacer'),_c('v-list-item-icon',[_c('v-icon',{domProps:{"textContent":_vm._s(icon)}})],1)],1)})],2)}
var staticRenderFns = []

export { render, staticRenderFns }