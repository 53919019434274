














import { api } from "@/api/api";
import { ADMIN_DEPARTMENT_ID } from "@/auth/auth.constants";
import { UserConstants } from "@/shared/constants/userConstants";
import { globalLoadingWrapper } from "@/shared/helpers/loadingHelpers";
import { useRouteComputed, useRouter } from "@/shared/useHelpers";
import { defineComponent, onMounted, ref } from "@vue/composition-api";
export default defineComponent({
  name: "TheDepartmentSelector",
  setup() {
    const router = useRouter();
    const route = useRouteComputed();
    const departments = ref<{ text: string; value: string }[]>([]);
    const selectedDepartment = ref(window.sessionStorage.getItem(UserConstants.USER_DEPARTMENT_ID));
    const setDepartment = (department: string) => {
      window.sessionStorage.setItem(UserConstants.USER_DEPARTMENT_ID, department);
      if (!(route.value.path === "/" || route.value.path === "/home")) {
        router.push("/home");
      }
    };

    onMounted(() =>
      globalLoadingWrapper({ blocking: false }, async () => {
        const response = (await api.organization.getDepartmentsAsync()).data.filter(
          (current) => current.id !== ADMIN_DEPARTMENT_ID
        );
        departments.value = response.map((department) => ({
          text: department.name || "(ukjent avdeling)",
          value: department.id.toString(),
        }));
      })
    );

    return { departments, selectedDepartment, setDepartment };
  },
});
