import Sortable, { SortableEvent } from "sortablejs";
import Vue from "vue";
import App from "./App.vue";
import { authService } from "./auth/authService";
import "./plugins/composition-api";
import vuetify from "./plugins/vuetify";
import "./registerServiceWorker";
import router from "./router/router";
import store from "./store/root.store";
// @ts-ignore TODO move to TS
import Dialog from "./plugins/dialog.js";
import "./plugins/tiptap-vuetify";
import { EnvironmentVariables } from "./environment";
import LogRocket from "logrocket";

import { createVPhoneInput } from "v-phone-input";
import "v-phone-input/dist/v-phone-input.css";
import "flag-icons/css/flag-icons.min.css";

if (EnvironmentVariables.IN_PRODUCTION_ENV) {
  LogRocket.init("xacocm/aof-kursadmin", {
    release: `Git: ${EnvironmentVariables.APP_REV}`,
    console: {
      shouldAggregateConsoleErrors: true,
    },
    // TODO network requestSanitizer & responseSanitizer
    shouldDebugLog: EnvironmentVariables.IN_DEV_ENV,
  });
}

// Log app version
// eslint-disable-next-line
console.log(
  `%c${EnvironmentVariables.APP_NAME} ${EnvironmentVariables.APP_VERSION}@${EnvironmentVariables.APP_REV}%c${EnvironmentVariables.NODE_ENV}`,
  "font:bold 1.2rem sans-serif;background:black;color:white;padding: 0 1rem",
  "font:bold 1.2rem sans-serif;background:white;color:black;padding: 0 1rem;"
);

// disable all service workers for now
if (window.navigator && navigator.serviceWorker) {
  navigator.serviceWorker.getRegistrations().then((registrations) => {
    for (const registration of registrations) {
      registration.unregister();
    }
  });
}

// remove when tiptap issue is fixed (or if this breaks anything)
const swallowTipTapWarnings = () => {
  const oldWarn = console.warn;
  console.warn = (...message) => {
    if (message[0].includes('tiptap-vuetify: The current language "no" is not yet available')) {
      return;
    }
    oldWarn(...message);
  };
};
swallowTipTapWarnings();

const vPhoneInput = createVPhoneInput({
  countryIconMode: "svg",
  displayFormat: "international",
  label: "Mobilnummer",
  countryLabel: "Land",
  invalidMessage: ({ label, example }) => `${label} må være et gyldig nummer (eksempel: ${example}).`,
});

Vue.use(vPhoneInput);

Vue.prototype.$auth = authService;
Vue.config.productionTip = false;
Vue.use(Dialog);

Vue.directive("sortableDataTable", {
  bind(el, binding, vnode) {
    const options: Sortable.Options = {
      animation: 150,
      onUpdate(event: SortableEvent) {
        vnode.componentInstance?.$emit("sorted", event);
      },
    };
    Sortable.create(el.getElementsByTagName("tbody")[0], options);
  },
});

new Vue({
  name: "KursAdmin",
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
