import { api } from "@/api/api";
import { ApiUpsertCourseParticipantDto } from "@/api/generated/Api";
import { authService } from "@/auth/authService";

export const hasMemberOrgAccess = authService.fromMemberOrganization();

const memberOrganizationApi = {
  getCourse: api.guestside.getGuestUserCourseByIdFilteredByOrganizationAsync,
  getCourses: api.guestside.getGuestUserCourses,
  getCurriculum: api.guestside.getGuestUserCurriculumAsync,
  getExecutionPlan: api.guestside.getGuestUserExecutionPlanAsync,
  getCourseParticipants: api.guestside.getGuestUserCourseParticipantsByCourseIdAsync,
  getAreaById: api.guestside.getGuestUserAreaByIdAsync,
  getMemberOrganizations: api.guestside.getGuestUserMemberOrganizations,
  getCustomerPerson: api.guestside.getGuestUserCustomerPersonByIdAsync,
  getCustomerPersons: api.guestside.getGuestUserCustomerPersonsAsync,
  getDefaultOptions: api.guestside.getGuestUserCourseDefaultOptions,
  getAppFormTemplate: api.guestside.getGuestUserAppFormTemplateAsync,
  getAppFormResponse: api.guestside.getGuestCourseParticipantAppFromResponse,
  getParticipantAttendance: api.guestside.getCourseParticipantAttendances,
  getCourseApplications: api.guestside.getCourseApplications,
  upsertCourseParticipant: (courseId: number, payload: ApiUpsertCourseParticipantDto, userId: number) => {
    if (!payload.memberOrganizationId) {
      return;
    }
    api.guestside.putGuestUserCourseParticipantApplicationStatus(courseId, userId, {
      participantStatus: payload.status,
      statusComment: payload.statusComment,
      memberOrganizationId: payload.memberOrganizationId,
    });
  },
};
const unrestrictedApi = {
  getCourse: api.course.getCourseByIdFilteredByDepartmentAsync,
  getCourses: api.course.getCoursesFilteredByDepartmentAsync,
  getCurriculum: api.curriculum.getCurriculumAsync,
  getExecutionPlan: api.curriculum.getExecutionPlanAsync,
  getCourseParticipants: api.course.getCourseParticipantsByCourseIdAsync,
  getAreaById: api.area.getAreaByIdAsync,
  getMemberOrganizations: api.organization.getMemberOrganizationsAsync,
  getCustomerPerson: api.customer.getCustomerPersonByIdAsync,
  getCustomerPersons: api.customer.getCustomerPersonsAsync,
  getDefaultOptions: api.course.getCourseDefaultOptions,
  getAppFormTemplate: api.appformtemplate.getAppFormTemplateAsync,
  getAppFormResponse: api.course.getAppFormResponseAsync,
  getParticipantAttendance: api.course.getCourseParticipantAttendancesByCourseIdAsync,
  getCourseApplications: api.dashboard.getUnreadCourseApplications,
  upsertCourseParticipant: (courseId: number, payload: ApiUpsertCourseParticipantDto) =>
    api.course.upsertCourseParticipantAsync(courseId, payload),
};

export const useRestrictedAccessApi = () => {
  if (authService.fromMemberOrganization()) {
    return memberOrganizationApi;
  }
  return unrestrictedApi;
};
