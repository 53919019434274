


























import { mapActions, mapGetters } from "vuex";
// @ts-ignore TODO move to TS
import SearchableSection from "@/mixins/SearchableSection";
import { defineComponent } from "@vue/composition-api";
export default defineComponent({
  name: "TheGlobalSearch",
  mixins: [SearchableSection],
  data: () => ({
    isFocused: false,
  }),
  methods: {
    ...mapActions("search", ["setValue"]),
    onItemSelected({ onSelected }: { onSelected: () => void }) {
      onSelected instanceof Function && onSelected();
    },
    getSearchResults(value: any) {
      const searchValue = value.toLowerCase();
      if (!this.$route.meta) {
        return [];
      }
      const searchResults = this.$route.meta.searchResults;
      const pathSearchResults = searchResults?.results ?? searchResults;
      if (!pathSearchResults) {
        return [];
      }
      if (Array.isArray(pathSearchResults)) {
        return pathSearchResults.filter((value) => String(value).toLowerCase().includes(searchValue));
      }
      if (Object.prototype.toString.call(pathSearchResults) === "[object Function]") {
        return pathSearchResults(value);
      }
      return [];
    },
  },
  computed: {
    ...mapGetters("search", ["results", "value"]),
    isSearching() {
      // return this.model && this.model.length > 0;
      return false; // TODO
    },
    searchSectionLabel(): any {
      if (!this.$route.meta) {
        return "";
      }
      return this.$route.meta.searchResults?.label;
    },
  },
});
