










import { runStoreAction } from "@/shared/helpers/store.helpers";
import { NotificationItemInternal } from "@/shared/interfaces/notificationItemInternal.interface";
import { useStore } from "@/shared/useHelpers";
import { StoreActions } from "@/store/store.actions";
import { StoreModules } from "@/store/store-modules.enum";
import { StoreState } from "@/store/store.state.interface";
import { computed, defineComponent } from "@vue/composition-api";

export default defineComponent({
  name: "TheNotificationDisplayer",
  setup() {
    const store = useStore<StoreState>();
    const notificationList = computed(() => store.state.hoc.notifications);

    const removeNotification = (notificationItem: NotificationItemInternal) => {
      runStoreAction(store, StoreModules.Hoc, StoreActions.HocActions.RemoveNotificationItem, notificationItem);
    };

    return {
      notificationList,
      removeNotification,
    };
  },
});
