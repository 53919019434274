import { CourseRouteNames } from "@/shared/enums/RouteNames/courseRouteNames.enum";
export const KursRouteList = [
  {
    path: "kurs",
    name: CourseRouteNames.CourseList,
    component: () => import(/* webpackChunkName: "CourseListPage" */ "@/pages/kurs/CourseListPage.vue"),
    meta: { isEnabledForMemberOrganizations: true },
  },
  {
    path: "kurs/ny",
    name: CourseRouteNames.NewCoursePrerequisites,
    component: () =>
      import(/* webpackChunkName: "NewCoursePrerequisitesPage" */ "@/pages/kurs/new/NewCoursePrerequisitesPage.vue"),
  },
  {
    path: "kurs/ny/courseTable/:id",
    name: CourseRouteNames.ExisitingCoursesUnderCurriculum,
    component: () =>
      import(
        /* webpackChunkName: "ExistingCoursesUnderCurriculumPage" */ "@/pages/kurs/new/ExistingCoursesUnderCurriculumPage.vue"
      ),
  },
  {
    path: "kurs/ny/:curriculumId",
    name: CourseRouteNames.NewCourse,
    component: () =>
      import(/* webpackChunkName: "CourseUpsertStepperPage" */ "@/pages/kurs/CourseUpsertStepperPage.vue"),
  },
  {
    path: "kurs/rediger/:editCourseId",
    name: CourseRouteNames.EditCourse,
    component: () =>
      import(/* webpackChunkName: "CourseUpsertStepperPage" */ "@/pages/kurs/CourseUpsertStepperPage.vue"),
  },
];
