













import TheAppBar from "@/components/shell/TheAppBar.vue";
import TheCollapsableDrawer from "@/components/shell/TheCollapsableDrawer.vue";
import TheNotificationDisplayer from "@/components/shell/TheNotificationDisplayer.vue";
import { defineComponent } from "@vue/composition-api";
import { EnvironmentVariables } from "./environment";
import { openNotification } from "@/shared/helpers/store.helpers";
import { useStore } from "./shared/useHelpers";
import { StoreState } from "./store/store.state.interface";
import { NotificationItemType } from "./shared/enums/notificationItemEnum";
import "@/global.scss";
export default defineComponent({
  name: "App",
  components: {
    TheAppBar,
    TheCollapsableDrawer,
    TheNotificationDisplayer,
  },
  setup() {
    const store = useStore<StoreState>();

    if (!EnvironmentVariables.IN_PRODUCTION_ENV && window.location.hostname !== "localhost") {
      openNotification(
        store,
        NotificationItemType.Information,
        `Du er i miljøet: ${EnvironmentVariables.VUE_APP_ENV_NAME}`
      );
    }
  },
});
