





















// Components
import BaseListGroup from "@/components/shared/list/BaseListGroup.vue";
import BaseListItem from "@/components/shared/list/BaseListItem.vue";
import { defineComponent } from "@vue/composition-api";
export default defineComponent({
  name: "BaseList",
  components: {
    BaseListGroup,
    BaseListItem,
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },
});
