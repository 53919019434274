import { api } from "@/api/api";
import { getHttpClient } from "@/api/httpClient";
import { DashboardUser } from "@/shared/constants/dashboardUser";
import { authService } from "@/auth/authService";
import { AxiosRequestConfig } from "axios";
import { ADMIN_DEPARTMENT_ID } from "@/auth/auth.constants";
import { UserConstants } from "../constants/userConstants";

export const getCurrentUser = async () => (await api.user.getCurrentEmployeeAsync()).data;

const dashboardInterceptor = (currentUserOrgId?: string) => async (config: AxiosRequestConfig) => {
  const dashboardUserOrgId = window.sessionStorage.getItem(DashboardUser.USER_ORG_ID);
  const loggedInUserOrgId = currentUserOrgId ?? (await getCurrentUser()).organizationId.toString();

  const isSameOrgId = dashboardUserOrgId === loggedInUserOrgId;
  const isAdminDepartmentId = dashboardUserOrgId === ADMIN_DEPARTMENT_ID.toString();

  /**
   * DashboardUser's Org-ID will be added if loggedInUser and dashboardUser belong to different departments
   * Department's Org-ID will be added if loggedInUser and dashboardUser belong to admin department
   */
  if (authService.canImpersonateDepartment() && dashboardUserOrgId !== null) {
    if (isSameOrgId && isAdminDepartmentId) {
      config.headers["Org-ID"] = window.sessionStorage.getItem(UserConstants.USER_DEPARTMENT_ID);
    }
    if (!isSameOrgId && !isAdminDepartmentId) {
      config.headers["Org-ID"] = dashboardUserOrgId;
    }
  }
  return config;
};

export const getDashboardUserId = async () => {
  const dashboardUserId = window.sessionStorage.getItem(DashboardUser.USER_ID);
  if (dashboardUserId !== null) {
    return Number(+dashboardUserId);
  }

  const currentUser = await getCurrentUser();
  window.sessionStorage.setItem(DashboardUser.USER_ID, currentUser.id.toString());
  window.sessionStorage.setItem(DashboardUser.USER_ORG_ID, currentUser.organizationId.toString());
  return currentUser.id;
};

/**
 * getDashboardHttpClient has one optional paramter currentUserOrgId
 * it is used in DashboardPage to prevent duplicate calls by dashboardApi
 */
export const getDashboardHttpClient = (currentUserOrgId?: string) => {
  const httpClient = getHttpClient({ addDepartmentInterceptor: false });
  httpClient.interceptors.request.use(dashboardInterceptor(currentUserOrgId));
  return httpClient;
};
